import { useCallback, useState } from "react";
import { Body, SectionHeader } from "@sprout/typography";
import { Select, type Options, Option, OnSelectParam } from "@sprout/select";
import { PrimaryButton } from "@sprout/button";
import Button from "@sprout/button";
import useCsrfToken from "@hooks/use_csrf";
import CompanyLogo from "@components/company_logo";
import { useQueryClient } from "@tanstack/react-query";
import { router } from "@inertiajs/react";

import "./subscription.scss";
import useTranslation from "@hooks/use_translation";
import Container from "@components/ui/container.tsx";
import BackButton from "@components/back_button.tsx";

type Method = "PUT" | "POST";

type Props = {
  departments: Options;
  selected: Options;
  pageTitle: string;
  subscriptionPath: string;
  subscriptionId: number;
  method: Method;
  logoUrl?: string;
  companyName: string;
};

const Subscription = (props: Props) => {
  const {
    departments,
    method,
    selected,
    pageTitle,
    subscriptionPath,
    subscriptionId,
    logoUrl,
    companyName,
  } = props;

  const { t } = useTranslation("jobAlerts");
  const csrfToken = useCsrfToken();
  const [selectedDepartments, setSelectedDepartments] = useState<Options>(selected);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const queryClient = useQueryClient();

  const submitDepartments = useCallback(async () => {
    setIsSubmitting(true);

    try {
      const response = await fetch(subscriptionPath, {
        method,
        body: JSON.stringify({
          id: subscriptionId,
          departments: selectedDepartments.map((department: Option) => department.value),
          authenticity_token: csrfToken,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        await queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
        router.visit("/subscriptions");
        return;
      }

      const { message } = await response.json();

      if (!message) {
        throw new Error("Not ok");
      }

      setError(message);
    } catch (e) {
      console.error(e);
      setError(t("defaultError"));
    } finally {
      setIsSubmitting(false);
    }
  }, [isSubmitting, selectedDepartments, queryClient, router]);

  const onSelect = useCallback((option: OnSelectParam) => {
    setSelectedDepartments(option as Options);
  }, []);

  const handleDelete = useCallback(async () => {
    try {
      const response = await fetch(subscriptionPath, {
        method: "DELETE",
        body: JSON.stringify({
          authenticity_token: csrfToken,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (response.ok) {
        await queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
        router.visit("/subscriptions");
        return;
      }

      setError(t("defaultError"));
    } catch (e) {
      console.error(e);
      setError(t("defaultError"));
    }
  }, [subscriptionPath, csrfToken, queryClient, t]);

  return (
    <Container>
      <BackButton href="/subscriptions" />
      <CompanyLogo companyName={companyName} logoUrl={logoUrl} />
      <SectionHeader as="h1" large>
        {pageTitle}
      </SectionHeader>
      <Body>{t("subscription.selectJobs")}</Body>
      <div className="subscription">
        <div className="subscription__input">
          <Select
            selected={selectedDepartments}
            short={true}
            id="select-departments"
            label={t("subscription.department")}
            onSelect={onSelect}
            options={departments}
            isMulti={true}
            isClearable={true}
            error={error}
          />
        </div>
        <div className="subscription__buttons">
          <PrimaryButton onClick={submitDepartments} loading={isSubmitting}>
            {method === "POST" ? t("subscription.create") : t("subscription.update")}
          </PrimaryButton>
          {method !== "POST" && (
            <Button display="danger" onClick={handleDelete}>
              {t("subscription.delete")}
            </Button>
          )}
        </div>
      </div>
    </Container>
  );
};

export default Subscription;
