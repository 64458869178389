import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const GreenhouseLogoIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper viewBox="0 0 13 26" {...props}>
      <path
        className={`icon--${props.color}`}
        fill={props.color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3935 7.40741C10.3935 8.61111 9.88426 9.67593 9.05093 10.5093C8.66953 10.8907 8.21743 11.1542 7.8176 11.3873C7.24674 11.7201 6.78241 11.9909 6.78241 12.4537C6.78241 12.9977 7.22945 13.1792 7.88589 13.4457C8.51444 13.7009 9.33497 14.034 10.1389 14.838C11.2269 15.9259 11.8981 17.3611 11.8981 19.0278C11.8981 22.3148 9.25926 24.9306 5.94907 24.9306C2.63889 24.9306 0 22.3148 0 19.0301C0 17.3611 0.671296 15.9259 1.75926 14.838C2.56318 14.034 3.38371 13.7009 4.01226 13.4457C4.6687 13.1792 5.11574 12.9977 5.11574 12.4537C5.11574 11.9909 4.6514 11.7201 4.08055 11.3873C3.68071 11.1542 3.22862 10.8907 2.84722 10.5093C2.01389 9.67593 1.50463 8.61111 1.50463 7.36111C1.50463 4.9537 3.47222 3.00926 5.87963 3.00926C6.12773 3.00926 6.36254 3.0292 6.58048 3.04771C6.76923 3.06375 6.94533 3.0787 7.10648 3.0787C7.73148 3.0787 8.05556 2.80093 8.05556 2.36111C8.05556 2.24365 8.03093 2.11141 8.00439 1.96894L8.00439 1.96893C7.9734 1.80256 7.93981 1.62223 7.93981 1.43519C7.93981 0.648148 8.61111 0 9.4213 0C10.2315 0 10.8796 0.671296 10.8796 1.48148C10.8796 2.33796 10.2083 2.73148 9.69907 2.91667C9.28241 3.05556 8.95833 3.24074 8.95833 3.65741C8.95833 3.95201 9.15942 4.24336 9.41105 4.60795C9.83167 5.2174 10.3935 6.03148 10.3935 7.40741ZM9.93056 19.0301C9.93056 16.7384 8.24074 14.8866 5.94907 14.8866C3.65741 14.8866 1.96759 16.7384 1.96759 19.0301C1.96759 21.2986 3.65741 23.1736 5.94907 23.1736C8.24074 23.1736 9.93056 21.2963 9.93056 19.0301ZM5.94907 4.69907C7.38426 4.69907 8.56481 5.90278 8.56481 7.36111C8.56481 8.81944 7.38426 10.0231 5.94907 10.0231C4.51389 10.0231 3.33333 8.81944 3.33333 7.36111C3.33333 5.90278 4.51389 4.69907 5.94907 4.69907Z"
      />
    </IconSvgWrapper>
  );
};

export default GreenhouseLogoIcon;
