import React, { useCallback, useEffect, useRef } from "react";
import ReactSelect, {
  components,
  type ClearIndicatorProps,
  type GroupBase,
  type MultiValueRemoveProps,
  type OptionProps,
  type OptionsOrGroups,
  type SelectComponentsConfig,
  type Theme,
  type CSSObjectWithLabel,
  type ControlProps,
  type InputProps,
} from "react-select";
import Creatable from "react-select/creatable";
import type {} from "react-select/base";
import CloseIcon from "./icons/close";
import DropdownArrow from "./icons/dropdown_arrow";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { Label } from "./typography";
import { IconButton } from "@sprout/icon_button";

import classNames from "classnames";
import AsyncSelect from "react-select/async";
import useTranslation from "@hooks/use_translation";

export type Option = {
  value: string | number;
  label: string;
  options?: Option[];
  depth?: number;
};

export type Options = Option[];

export type OnSelectParam = Option | Readonly<Options> | null;

interface SelectPropsBase {
  id: string;
  grouped?: boolean;
  label: string;
  required?: boolean;
  isMulti?: boolean;
  outsideLabel?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  isDisabled?: boolean;
  createLabel?: string;
  error?: string | null;
  selected?: OnSelectParam;
  onSelect: (selected: OnSelectParam) => void;
  onMenuScrollToBottom?: () => void;
  onInputChange?: (value: string) => void;
  short?: boolean;
  hideDropdownIndicator?: boolean;
  hideNoOptionsMessage?: boolean;
  defaultOptions?: Option[];
  placeholderText?: string;
  value?: Option | null;
  helperText?: string;
}

interface SelectProps extends SelectPropsBase {
  options: Options;
  isAsync?: false;
  loadOptions?: never;
  isLoading?: never;
}

interface AsyncSelectProps extends SelectPropsBase {
  isAsync: boolean;
  isLoading: boolean;
  loadOptions: (inputValue: string) => Promise<Options>;
  options?: never;
}

declare module "react-select/base" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    onKeyUp: React.KeyboardEventHandler;
    onMouseUp: React.MouseEventHandler;
    onTouchEnd: React.TouchEventHandler;
  }
}

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <IconButton
        label="Remove Selection"
        icon={CloseIcon}
        hoverColor="gray"
        size="xs"
        onClick={(event) => event.preventDefault()}
      ></IconButton>
    </components.MultiValueRemove>
  );
};

// This ensures that Emotion's styles are inserted before our css to ensure our classes have precedence over Emotion
const EmotionCacheProvider = ({ children }: { children: React.ReactNode }) => {
  const nonce = document.querySelector('meta[name="csp-nonce"]')?.getAttribute("content");

  const cache = React.useMemo(
    () =>
      createCache({
        key: "overwritable",
        nonce: nonce || undefined,
        prepend: true,
        container: document.head,
      }),
    [nonce],
  );

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};

const DropdownIndicator = () => (
  <IconButton
    label="Toggle flyout"
    icon={DropdownArrow}
    tabIndex={-1}
    onClick={(event) => event.preventDefault()}
  ></IconButton>
);

const EmptyDropdownIndicator = () => null;

const EmptyNoOptionsMessage = () => null;

const ClearIndicator = (props: ClearIndicatorProps<Option>) => {
  const {
    clearValue,
    innerProps: { ref, ...restInnerProps },
  } = props;

  const onKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === "Enter") {
        clearValue();
      }
    },
    [clearValue],
  );

  return (
    <div {...restInnerProps} ref={ref} onKeyDown={onKeyDown} aria-hidden={false}>
      <IconButton
        label="Clear Selections"
        icon={CloseIcon}
        onClick={(event) => event.preventDefault()}
      ></IconButton>
    </div>
  );
};

const IndicatorSeparator = () => null;

const IndentedOption = (props: OptionProps<Option>) => {
  const depth = props.data.depth;
  const leftPadding = depth && depth > 0 ? depth * 24 : 0;

  return (
    <components.Option {...props}>
      <div className="indentation" style={{ paddingLeft: leftPadding }}>
        {props.label}
      </div>
    </components.Option>
  );
};

const Control = (props: ControlProps<Option>) => (
  <div
    onKeyUp={props.selectProps.onKeyUp}
    onMouseUp={props.selectProps.onMouseUp}
    onTouchEnd={props.selectProps.onTouchEnd}
  >
    <components.Control {...props} />
  </div>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Input = (props: InputProps<Option, boolean, any>) => (
  <components.Input {...props} enterKeyHint="done" />
);

export const Select = (props: SelectProps | AsyncSelectProps) => {
  const {
    id,
    isMulti = false,
    isAsync = false,
    isLoading = false,
    outsideLabel = false,
    options,
    grouped,
    isClearable = false,
    required = false,
    error = null,
    isCreatable = false,
    isDisabled = false,
    createLabel,
    onSelect,
    label,
    selected,
    onMenuScrollToBottom,
    onInputChange,
    loadOptions,
    value,
    short = false,
    hideDropdownIndicator = false,
    hideNoOptionsMessage = false,
    defaultOptions,
    placeholderText,
    helperText,
  } = props;
  const { t } = useTranslation("sprout");

  const selectionColor = "";

  const [selectedOption, setSelectedOption] = React.useState<OnSelectParam | Readonly<Options>>(
    selected || null,
  );
  const [menuIsOpen, setMenuIsOpen] = React.useState<boolean>(false);

  const onChange = useCallback(
    (option: OnSelectParam) => {
      onSelect(option);
      setSelectedOption(option);
      setMenuIsOpen(false);
    },
    [onSelect],
  );

  const selectRef = useRef<HTMLDivElement>(null);

  // VoiceOver (and other screen readers) do not support aria-errormessage (see https://github.com/w3c/aria/issues/2048#issuecomment-1743299817).
  // The current, accepted practice is to add that ID to aria-describedby, which is supported more universally.
  //
  // Unfortunately, react-select does not accept an aria-describedby prop (internally, it sets it to the ID of the live region or placeholder).
  // This effect is a workaround to add the error message ID to aria-describedby on the select input so it will be announced by screen readers.
  useEffect(() => {
    const input = selectRef.current?.querySelector("input[aria-errormessage]");

    if (!input) {
      return;
    }

    const ariaErrorMessageId = input.getAttribute("aria-errormessage") || "";
    const ariaDescribedByIds = input.getAttribute("aria-describedby") || "";

    if (!ariaDescribedByIds.split(" ").includes(ariaErrorMessageId)) {
      input.setAttribute("aria-describedby", `${ariaDescribedByIds} ${ariaErrorMessageId}`.trim());
    }
  });

  const labelId = `${id}-label`;

  const containerClassName = classNames({
    select__container: true,
    "select__container--outside-label": outsideLabel,
    "select__container--disabled": isDisabled,
    "select__container--short": short,
  });

  const labelClassName = classNames({
    select__label: true,
    "select__label--error": !!error,
    "select__label--outside-label": outsideLabel,
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const componentMapping: SelectComponentsConfig<Option, boolean, any> = {
    IndicatorSeparator,
    MultiValueRemove,
    DropdownIndicator,
    ClearIndicator,
    Control,
    Input,
  };

  if (grouped) {
    componentMapping.Option = IndentedOption;
  }

  if (hideDropdownIndicator) {
    componentMapping.DropdownIndicator = EmptyDropdownIndicator;
  }

  if (hideNoOptionsMessage) {
    componentMapping.NoOptionsMessage = EmptyNoOptionsMessage;
  }

  const sharedProps = {
    instanceId: id,
    required,
    "aria-labelledby": labelId,
    "aria-describedby": `${id}-description ${id}-error`,
    "aria-invalid": !!error,
    "aria-errormessage": `${id}-error`,
    inputId: id,
    isMulti,
    className: "select-shell",
    classNamePrefix: "select",
    defaultValue: selectedOption,
    onChange,
    isClearable,
    isDisabled,
    options,
    components: componentMapping,
    classNames: {
      control: () =>
        classNames({
          "select__control--error": !!error,
          "select__control--outside-label": outsideLabel,
        }),
      indicatorsContainer: () =>
        classNames({
          "select__indicators--outside-label": outsideLabel,
          "select__indicators--no-label": !label,
        }),
    },
    onMenuScrollToBottom,
    onInputChange,
    hideDropdownIndicator,
    hideNoOptionsMessage,
    noOptionsMessage: () => t("select.noOptions"),
    defaultOptions,
    placeholder: placeholderText || t("select.placeholder"),
    menuIsOpen,
    onKeyUp: (key: React.KeyboardEvent) => {
      if (key.code === "Escape" || key.code === "Tab") {
        setMenuIsOpen(false);
      } else if (key.code !== "Enter") {
        setMenuIsOpen(true);
      }
    },
    onBlur: () => setMenuIsOpen(false),
    onMouseUp: () => setMenuIsOpen(!menuIsOpen),
    onTouchEnd: () => setMenuIsOpen(!menuIsOpen),
    value: value !== undefined ? value : undefined,
  };

  const formatCreateLabel = createLabel ? (value: string) => `${createLabel} ${value}` : undefined;

  const isValidNewOption = (
    inputValue: string,
    selectedOptions: Readonly<Options>,
    options: OptionsOrGroups<Option, GroupBase<Option>>,
  ) => {
    if (!isCreatable) {
      return false;
    }

    const optionSet = new Set(
      options.flatMap((option) => {
        if (!option) {
          return [];
        }

        if ((option as GroupBase<Option>).options) {
          return (option as GroupBase<Option>).options.map((option) => option.value);
        }

        return (option as Option)?.value;
      }),
    );

    // Only allow 1 new element to be created
    for (const selectedOption of selectedOptions) {
      const value = (selectedOption as Option).value;

      if (!optionSet.has(value)) {
        return false;
      }
    }

    // Show the new option creator if the user has typed something
    return inputValue.length > 0;
  };

  const setTheme = (theme: Theme) => ({
    ...theme,
    colors: {
      ...theme.colors,
      primary: selectionColor || theme.colors.primary,
      primary25: selectionColor ? `${selectionColor}40` : theme.colors.primary25,
      primary50: selectionColor ? `${selectionColor}80` : theme.colors.primary50,
      primary75: selectionColor ? `${selectionColor}c0` : theme.colors.primary75,
      neutral5: "#e1e1e1",
    },
  });

  const styles = {
    menu: (provided: CSSObjectWithLabel) =>
      ({
        ...provided,
        zIndex: 9999,
        margin: 0,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }) as CSSObjectWithLabel,
  };

  let selectComponent;
  if (isCreatable) {
    selectComponent = (
      <Creatable
        {...sharedProps}
        formatCreateLabel={formatCreateLabel}
        isValidNewOption={isValidNewOption}
      />
    );
  } else if (isAsync) {
    selectComponent = (
      <AsyncSelect
        {...sharedProps}
        cacheOptions
        loadOptions={loadOptions}
        value={value}
        isLoading={isLoading}
        loadingMessage={() => t("select.loadingMessage")}
        isClearable={isClearable}
        theme={setTheme}
        styles={styles}
        defaultOptions={defaultOptions}
      />
    );
  } else {
    selectComponent = <ReactSelect {...sharedProps} theme={setTheme} styles={styles} />;
  }

  return (
    <div className="select" ref={selectRef}>
      <div className={containerClassName}>
        <EmotionCacheProvider>
          <Label className={labelClassName} id={labelId} htmlFor={id} required={required}>
            {label}
          </Label>
          {selectComponent}
        </EmotionCacheProvider>
      </div>
      {helperText && (
        <p className="helper-text" aria-live="polite" data-testid={`${id}-help`}>
          {helperText}
        </p>
      )}
      {error && (
        <p id={`${id}-error`} className="helper-text helper-text--error" aria-live="polite">
          {error}
        </p>
      )}
    </div>
  );
};
