import { useEffect } from "react";

// Separating this into its own component is a performance optimization
// for the beforeunload event listener. This lets us exclude the component (and
// the event listener) from the DOM when it isn't needed.
// Separating this into its own component is a performance optimization
// for the beforeunload event listener. This lets us exclude the component (and
// the event listener) from the DOM when it isn't needed.
const UnloadHandler = () => {
  function handleUnload(event: BeforeUnloadEvent) {
    event.preventDefault();
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return null;
};

export default UnloadHandler;
