import useDevice from "@/hooks/use_device";
import { type Subscription } from "@/pages/dashboard";
import { router } from "@inertiajs/react";
import CompanyLogo from "../company_logo";
import { SectionHeader } from "@/sprout/typography";
import { IconButtonLink } from "@/sprout/icon_button";
import PreviewIcon from "@/sprout/icons/preview";
import EditIcon from "@/sprout/icons/edit";
import DepartmentTags from "../department_tags";
import theme from "@/stylesheets/theme.module.scss";
import "./card.scss";

const JobAlertsCard = ({ subscription }: { subscription: Subscription }) => {
  const { type } = useDevice();

  const subscriptionOnClick = () => {
    if (type === "mobile") {
      router.visit(subscription.editPath);
    }
  };

  return (
    <li className="job-alerts-list__card" onClick={subscriptionOnClick}>
      <div className="job-alerts-list__header">
        <CompanyLogo
          logoUrl={subscription.logoUrl}
          companyName={subscription.boardName}
          size="small"
        />
        <SectionHeader as="h3">{subscription.boardName}</SectionHeader>
        <div className="job-alerts-list__header__buttons">
          <IconButtonLink
            external
            href={subscription.path}
            icon={PreviewIcon}
            color={theme.darkGreen}
            data-provides="preview-board"
          />

          <IconButtonLink
            icon={EditIcon}
            color={theme.darkGreen}
            href={subscription.editPath}
            data-provides="edit-subscription"
          />
        </div>
      </div>
      <DepartmentTags departments={subscription.departments} />
    </li>
  );
};

export default JobAlertsCard;
