import { useSuspenseQuery } from "@tanstack/react-query";
import type { Subscription } from "@/pages/dashboard";

const useSubscriptions = () => {
  const fetchSubscriptions = (): Promise<Subscription[]> =>
    fetch("/subscriptions.json").then((res) => res.json());

  return useSuspenseQuery({
    queryKey: ["subscriptions"],
    queryFn: fetchSubscriptions,
    staleTime: 60 * 60 * 1000, // 60 minutes
  });
};

export default useSubscriptions;
