import { Link } from "@sprout/typography";
import useTranslation from "@hooks/use_translation";
import useDevice from "@hooks/use_device.ts";
import "./back_button.scss";
import BackIcon from "@sprout/icons/back.tsx";
import theme from "@/stylesheets/theme.module.scss";

const BackButton = ({ href }: { href?: string }) => {
  const { t } = useTranslation("misc");
  const { type } = useDevice();

  return (
    <div>
      <Link href={href || "/dashboard"} className="flex items-center">
        <div className="flex items-center gap-1 ">
          {type === "web" ? (
            <span className="flex items-center">
              <BackIcon size="sm" color={theme.linkColor} />
              {t("back")}
            </span>
          ) : (
            <BackIcon color="#15372C" />
          )}
        </div>
      </Link>
    </div>
  );
};

export default BackButton;
