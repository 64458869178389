import ArrowRightIcon from "@sprout/icons/arrow_right";
import "./profile_card.scss";
import Button from "@/sprout/button";
import { Body, SectionTitle, Link } from "@/sprout/typography";
import { Link as InertiaLink } from "@inertiajs/react";
import useTranslation from "@hooks/use_translation";
import CompletionTag from "@components/completion_tag.tsx";
import { CompletionStatus } from "@utils/completion_status.ts";
import useProfile from "@/queries/use_profile";
import LoadingSpinner from "@/sprout/loading_spinner";

const ProfileCardHeader = () => {
  const { t } = useTranslation("user");
  return (
    <InertiaLink href="/profile" className="profile_card__header">
      <SectionTitle>{t("profile")}</SectionTitle>
      <div className="profile_card__header__icon">
        <ArrowRightIcon />
      </div>
    </InertiaLink>
  );
};

const ProfileCard = () => {
  const { t } = useTranslation("user");
  const { data, isLoading, error } = useProfile();

  if (isLoading) {
    return (
      <div className="profile_card">
        <ProfileCardHeader />
        <div className="profile_card__content">
          <div className="flex justify-center items-center h-32">
            <LoadingSpinner size="medium" />
          </div>
        </div>
      </div>
    );
  }

  if (error || !data) {
    return (
      <div className="profile_card">
        <ProfileCardHeader />
        <div className="profile_card__content">
          <Body>{t("defaultError")}</Body>
        </div>
      </div>
    );
  }

  const { email, firstName, lastName, quickApplyCompletion, workPreferencesCompletion } = data;

  let profileContent = null;

  if (firstName || lastName) {
    profileContent = (
      <div>
        <Body>
          {firstName} {lastName}
        </Body>
        <p className="profile_card__email">{email}</p>
      </div>
    );
  } else {
    profileContent = <Body>{email}</Body>;
  }

  const GetStartedButton = ({ href }: { href: string }) => (
    <Button href={href} link={true} fitContent={true} size="medium">
      {t("getStarted")}
    </Button>
  );

  return (
    <div className="profile_card">
      <ProfileCardHeader />
      <div className="profile_card__content">
        {profileContent}
        <hr />
        <div className="profile_card__footer">
          <div>
            <Link href="/quick_apply">{t("quickApply")}</Link>
            {quickApplyCompletion === CompletionStatus.NotStarted ? (
              <GetStartedButton href="/quick_apply" />
            ) : (
              <CompletionTag status={quickApplyCompletion} />
            )}
          </div>
          <div>
            <Link href="/work_preferences">{t("workPreferences.title")}</Link>
            {workPreferencesCompletion === CompletionStatus.NotStarted ? (
              <GetStartedButton href="/work_preferences" />
            ) : (
              <CompletionTag status={workPreferencesCompletion} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
