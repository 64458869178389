import { useState, useEffect } from "react";
import { type Option, Select } from "@sprout/select";
import type { ItemProps } from "@sprout/expandable_menu";
import type { EmailSchedule } from "@/pages/subscriptions";
import useCsrfToken from "@hooks/use_csrf";
import ExpandableMenu from "@sprout/expandable_menu";
import ReminderIcon from "@sprout/icons/reminder";
import useTranslation from "@hooks/use_translation";

import "./preferences_legacy.scss";

type Props = {
  paths: {
    notificationSchedulePath: string;
  };
  emailSchedule: EmailSchedule;
  emailSchedules: { [key: string]: number };
  shortCadence?: boolean;
};

const Preferences = (props: Props) => {
  const { paths, emailSchedule, emailSchedules } = props;
  const csrfToken = useCsrfToken();
  const { t } = useTranslation("jobAlerts");

  const getCadenceText = (cadence) => {
    return props.shortCadence
      ? cadence.charAt(0).toUpperCase() + cadence.slice(1)
      : t("preferences.sendEmailCadence", { cadence });
  };

  const scheduleOptions = Object.keys(emailSchedules).map((cadence) => ({
    value: emailSchedules[cadence],
    label: getCadenceText(cadence),
  }));

  const defaultEmailSchedule =
    scheduleOptions.find((option) => option.value === emailSchedule.id) || scheduleOptions[0];

  const [schedule, setSchedule] = useState<Option | ItemProps>(defaultEmailSchedule);

  const cadence =
    schedule.value === emailSchedules["daily"] ? t("preferences.daily") : t("preferences.weekly");

  const menuItems = Object.keys(emailSchedules).map((cadence) => ({
    value: cadence === t("preferences.daily") ? 0 : 1,
    label: getCadenceText(cadence),
  }));

  useEffect(() => {
    updateEmailSchedule();
  }, [schedule.value]);

  const updateEmailSchedule = () => {
    try {
      fetch(paths.notificationSchedulePath, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cadence,
          authenticity_token: csrfToken,
        }),
      });
    } catch (e) {
      console.error("Error updating email schedule", e);
    }
  };

  return (
    <div className="preferences_legacy">
      {/* Mobile view */}
      <ExpandableMenu
        icon={<ReminderIcon />}
        items={menuItems}
        selected={schedule as ItemProps}
        onSelect={(item) => setSchedule(item)}
      />
      {/* Tablet and above view */}
      <Select
        id="email-schedule"
        label=""
        options={scheduleOptions}
        onSelect={(item) => {
          setSchedule(item as Option);
        }}
        selected={schedule}
      />
    </div>
  );
};

export default Preferences;
