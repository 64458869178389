import React, { RefObject } from "react";
import classNames from "classnames";
import LoadingSpinner from "./loading_spinner";
import { Link } from "@inertiajs/react";

export type ButtonShape = "rectangle" | "rounded" | "pill";
type ButtonType = "submit" | "button" | "reset";
type ButtonSize = "small" | "medium" | "large";

interface ButtonProps {
  children: string | React.ReactElement;
  display?: "info" | "danger";
  shape?: ButtonShape | null;
  size?: ButtonSize;
  type?: ButtonType;
  ariaLabel?: string;
  link?: boolean;
  href?: string;
  loading?: boolean;
  disabled?: boolean;
  primary?: boolean;
  fitContent?: boolean;
  linkStyle?: boolean;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  ref?: RefObject<HTMLButtonElement | null>;
  className?: string;
  external?: boolean;
  underline?: boolean;
}

const Button = (props: ButtonProps) => {
  const {
    ref,
    children,
    display,
    shape = "rounded",
    size = "large",
    type = "button",
    ariaLabel,
    link = false,
    href,
    loading,
    disabled,
    primary,
    fitContent,
    linkStyle,
    onClick,
    className: customClass,
    external,
    underline,
  } = props;

  const isDisabled = disabled || loading;

  const className = classNames({
    btn: true,
    [`btn--${shape}`]: true,
    btn__primary: primary,
    [`btn--${size}`]: true,
    btn__disabled: isDisabled,
    btn__info: display,
    "btn__info--danger": display === "danger",
    "btn--fit-content": fitContent,
    "btn--link-style": linkStyle,
    "btn--underline": underline,
    [customClass || ""]: true,
  });

  if (link) {
    if (external) {
      return (
        <a
          href={href || ""}
          className={className}
          aria-label={ariaLabel}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    } else {
      return (
        <Link href={href || ""} className={className} aria-label={ariaLabel}>
          {children}
        </Link>
      );
    }
  }

  return (
    <button
      ref={ref}
      type={type}
      className={className}
      aria-label={ariaLabel}
      onClick={onClick}
      disabled={isDisabled}
      aria-disabled={isDisabled}
    >
      {loading && <LoadingSpinner size="small" />}
      {!loading && children}
    </button>
  );
};

export const PrimaryButton = (props: ButtonProps) => {
  return (
    <Button {...props} primary>
      {props.children}
    </Button>
  );
};

export default Button;
