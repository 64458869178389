import React from "react";
import classNames from "classnames";
import { Body as PrimaryBody, SecondaryBody, TableHeader } from "./typography";
import { Tag, type TagProps } from "./tag";

type Header = string;

type BaseCell = {
  emphasis?: boolean;
  href?: string;
  tags?: TagProps[];
};

type TextCell = {
  primaryBody: string;
  secondaryBody?: string;
  element?: never;
} & BaseCell;

type ElementCell = {
  element: React.ReactNode;
  primaryBody?: never;
  secondaryBody?: never;
} & BaseCell;

export type Cell = TextCell | ElementCell;

export type Row = Cell[];

interface TableProps {
  headers?: Array<Header> | null;
  rows: Array<Row>;
  rowClassName?: string;
  defaultHeader?: string | null;
  headerClassName?: string;
  wideFirstColumn?: boolean;
  openLinksInNewTab?: boolean;
}

const Table = (props: TableProps) => {
  const {
    headers,
    rows,
    rowClassName,
    defaultHeader,
    headerClassName,
    wideFirstColumn,
    openLinksInNewTab,
  } = props;

  const headersExist = headers && headers.length > 0;
  const renderHeaders = headersExist ? (
    headers?.map((header, index) => (
      <th key={"header-" + index} scope="col">
        <TableHeader>{header}</TableHeader>
      </th>
    ))
  ) : (
    <th scope="col" className="visually-hidden">
      <TableHeader>{defaultHeader}</TableHeader>
    </th>
  );

  const renderRows = rows.map((row, index) => (
    <tr className={rowClassName} key={"row-" + index}>
      {row.map((cell, columnIndex) => {
        const className = classNames({
          cell: true,
          "cell--wide": wideFirstColumn && columnIndex === 0,
        });
        return (
          <td key={"cell-" + columnIndex} className={className}>
            {cell.element ? (
              cell.element
            ) : (
              <a href={cell.href} target={openLinksInNewTab ? "_blank" : "_self"} rel="noreferrer">
                <PrimaryBody medium={cell.emphasis}>
                  {cell.primaryBody}
                  {cell.tags?.map((tag, index) => (
                    <Tag key={index} label={tag.label} color={tag.color} spacing={true} />
                  ))}
                </PrimaryBody>
                <SecondaryBody metadata>{cell.secondaryBody}</SecondaryBody>
              </a>
            )}
          </td>
        );
      })}
    </tr>
  ));

  return (
    <table>
      {(headersExist || defaultHeader) && (
        <thead>
          <tr className={headerClassName}>{renderHeaders}</tr>
        </thead>
      )}
      <tbody>{renderRows}</tbody>
    </table>
  );
};

export default Table;
