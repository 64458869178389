import React, { useState } from "react";
import "./self_identification_form.scss";
import { Body, Link, SectionTitle } from "@sprout/typography";
import { Collapsible } from "@sprout/collapsible";
import { SelfIdentificationDetails } from "@components/self_identification_details";
import { type OnSelectParam, type Option, Select } from "@sprout/select";
import useTranslation from "@/hooks/use_translation";
import { SelfIdentification, SelfIdentificationProps } from "@/utils/self_identification";

const findSelectedHispanicOrLatinoOption = (
  options: Option[],
  value?: SelfIdentification | null,
): Option | undefined => {
  if (value?.race == null) {
    return undefined;
  }

  const selectedOption = options.find((option) => option.value === value.race);

  if (selectedOption) {
    return selectedOption;
  }

  return options.find((option) => option.value === "");
};

const findSelectedOption = (options: Option[], value?: string | number) => {
  return options.find((option) => option.value === value);
};

export const SelfIdentificationForm = (props: SelfIdentificationProps) => {
  const { details, onChange, options, value } = props;
  const { t } = useTranslation("user");

  const [showMainDetails, setShowMainDetails] = useState(true);
  const [showVeteranDetails, setShowVeteranDetails] = useState(false);
  const [showDisabilityDetails, setShowDisabilityDetails] = useState(false);
  const [showRace, setShowRace] = useState(
    value?.race === "" || options.raceOptions.some((option) => option.value === value?.race),
  );

  const handleChange = (key: keyof SelfIdentification, selected: OnSelectParam) => {
    onChange({
      ...((value || {}) as SelfIdentification),
      [key]: selected ? (selected as Option).value : null,
    });
  };

  const onHispanicChange = (selected: OnSelectParam) => {
    handleChange("race", selected);
    if ((selected as Option)?.value === "") {
      setShowRace(true);
    } else {
      setShowRace(false);
    }
  };

  return (
    <div className="self-identification">
      <div className="self-identification--section">
        <SectionTitle large>{details.title}</SectionTitle>
        <Body metadata>{details.helperText}</Body>
        <div className="self-identification--collapsible">
          <Collapsible show={showMainDetails}>
            <SelfIdentificationDetails segments={details.example} />
          </Collapsible>
          <Link onClick={() => setShowMainDetails(!showMainDetails)}>
            {showMainDetails
              ? t("selfIdentification.hideExample")
              : t("selfIdentification.showExample")}
          </Link>
        </div>
        <Select
          id="gender"
          label={t("selfIdentification.gender")}
          onSelect={(selected) => handleChange("gender", selected)}
          options={options.genderOptions}
          selected={findSelectedOption(options.genderOptions, value?.gender)}
          isClearable
        />
        <Select
          id="hispanic_or_latino"
          label={t("selfIdentification.hispanicOrLatino")}
          onSelect={(selected) => onHispanicChange(selected)}
          options={options.hispanicOrLatinoOptions}
          selected={findSelectedHispanicOrLatinoOption(options.hispanicOrLatinoOptions, value)}
          isClearable
        />
        {showRace && (
          <Select
            id="race"
            label={t("selfIdentification.raceSelect")}
            onSelect={(selected) => handleChange("race", selected)}
            options={options.raceOptions}
            selected={findSelectedOption(options.raceOptions, value?.race)}
            isClearable
          />
        )}
        <Link
          href="https://boards.cdn.greenhouse.io/docs/RaceEthnicityDefinitions.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          {"Race & ethnicity definitions"}
        </Link>
      </div>
      <div className="self-identification--section">
        <Body medium>{details.veteran.title}</Body>
        <div className="self-identification--collapsible">
          <Collapsible show={showVeteranDetails}>
            <SelfIdentificationDetails segments={details.veteran.example} />
          </Collapsible>
          <Link onClick={() => setShowVeteranDetails(!showVeteranDetails)}>
            {showVeteranDetails
              ? t("selfIdentification.hideExample")
              : t("selfIdentification.showExample")}
          </Link>
        </div>
        <Select
          id="veteran_status"
          label={t("selfIdentification.veteranStatus")}
          onSelect={(selected) => handleChange("veteranStatus", selected)}
          options={options.veteranOptions}
          selected={findSelectedOption(options.veteranOptions, value?.veteranStatus)}
          isClearable
        />
      </div>
      <div className="self-identification--section">
        <Body medium>{details.disability.title}</Body>
        <div className="self-identification--collapsible">
          <Collapsible show={showDisabilityDetails}>
            <SelfIdentificationDetails segments={details.disability.example} />
          </Collapsible>
          <Link onClick={() => setShowDisabilityDetails(!showDisabilityDetails)}>
            {showDisabilityDetails
              ? t("selfIdentification.hideExample")
              : t("selfIdentification.showExample")}
          </Link>
        </div>
        <Select
          id="disability_status"
          label={t("selfIdentification.disabilityStatus")}
          onSelect={(selected) => handleChange("disabilityStatus", selected)}
          options={options.disabilityOptions}
          selected={findSelectedOption(options.disabilityOptions, value?.disabilityStatus)}
          isClearable
        />
      </div>
    </div>
  );
};
