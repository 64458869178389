import { useCookies } from "react-cookie";
import { Trans } from "react-i18next";

import "./inform_cookies_popup.scss";
import useTranslation from "@hooks/use_translation";

const INFORM_COOKIES_COOKIE = "inform_cookies";

// Either 'greenhouse.io' on production or 'greenhouse.dev' on dev environments
const GREENHOUSE_DOMAIN = window.location.hostname.split(".").slice(-2).join(".");

const InformCookiesPopup = () => {
  const { t } = useTranslation("misc");
  const [cookies, setCookie] = useCookies([INFORM_COOKIES_COOKIE]);
  const showPopup = cookies[INFORM_COOKIES_COOKIE] !== false;

  const closePopup = () => {
    const expiry = new Date(new Date().setFullYear(new Date().getFullYear() + 5));

    setCookie(INFORM_COOKIES_COOKIE, "false", {
      path: "/",
      domain: GREENHOUSE_DOMAIN,
      expires: expiry,
    });
  };

  return (
    showPopup && (
      <div className="inform-cookies-container" data-provides="inform-cookies-popup">
        <div className="inner-container">
          <Trans i18nKey="cookies.notice">
            <div className="inform-header">This site uses cookies</div>
            <div>
              By continuing to use this site, you are agreeing to our use of cookies.{" "}
              <a
                className="privacy-policy-link"
                href="https://www.greenhouse.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                See Privacy Policy
              </a>
            </div>
          </Trans>

          <button className="accept-button" onClick={closePopup}>
            {t("cookies.confirm")}
          </button>
        </div>
      </div>
    )
  );
};

export default InformCookiesPopup;
