import { Body, SecondaryBody } from "@/sprout/typography";
import { RecentJobView } from ".";
import { IconButtonLink } from "@/sprout/icon_button";
import Table, { Row } from "@/sprout/table";
import PreviewIcon from "@/sprout/icons/preview";
import { WhenVisible } from "@inertiajs/react";
import "./index.scss";
import StatusIndicator from "./status_indicator";

type Props = {
  jobs: RecentJobView[];
  currentPage?: number;
  total?: number;
  totalPages?: number;
};

const MobileJobRowElement = ({ job }: { job: RecentJobView }) => {
  const handleClick = () => {
    if (job.live) {
      window.open(job.url, "_blank");
    }
  };

  return (
    <div className="recently-viewed-jobs-table__mobile-row" onClick={handleClick}>
      <div className="recently-viewed-jobs-table__mobile-row__job__info">
        <div>
          <Body>{job.title}</Body>
          <SecondaryBody>{job.companyName}</SecondaryBody>
        </div>
        {job.live && (
          <IconButtonLink
            icon={PreviewIcon}
            href={job.url}
            size="sm"
            external={true}
            color="#15372C"
          />
        )}
      </div>
      <div className="recently-viewed-jobs-table__mobile-row__job__metadata">
        <StatusIndicator live={!!job.live} deviceType="mobile" />
        <SecondaryBody>{`viewed ${job.lastViewed}`}</SecondaryBody>
      </div>
    </div>
  );
};

const RecentlyViewedJobsTableMobile = (props: Props) => {
  const RenderWhenVisible = () => {
    if (props.currentPage! < props.totalPages!) {
      return (
        <WhenVisible
          fallback={"Loading..."}
          params={{
            data: { page: props.currentPage! + 1 },
            preserveUrl: true,
            only: ["jobs", "currentPage"],
          }}
        >
          <div></div>
        </WhenVisible>
      );
    }
  };

  const renderRows = (): Row[] => {
    return props.jobs.map((job) => {
      return [
        {
          element: <MobileJobRowElement job={job} />,
        },
      ];
    });
  };

  return (
    <>
      <Table rows={renderRows()} />
      <RenderWhenVisible />
    </>
  );
};

export default RecentlyViewedJobsTableMobile;
