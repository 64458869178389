import React, { useCallback, useState } from "react";
import { TextInput } from "@sprout/text_input";
import { PrimaryButton } from "@sprout/button";
import useCsrfToken from "@hooks/use_csrf";
import useTranslation from "@hooks/use_translation";

type EmailFormProps = {
  jobBoardToken: string;
  createPath: string;
  email: string;
  setShowCode: (showCode: boolean) => void;
  setEmail: (email: string) => void;
};

const LoginEmail = (props: EmailFormProps) => {
  const { jobBoardToken, createPath, email, setShowCode, setEmail } = props;
  const { t } = useTranslation("misc");

  const csrfToken = useCsrfToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const defaultErrorMessage = t("defaultError");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const submit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setError(null);

      if (!emailRegex.test(email)) {
        setError(t("login.invalidEmail"));
        return;
      }

      setIsSubmitting(true);

      try {
        const response = await fetch(createPath, {
          method: "POST",
          body: JSON.stringify({
            email: email,
            job_board: jobBoardToken,
            authenticity_token: csrfToken,
          }),
          headers: { "Content-Type": "application/json" },
        });

        if (response.ok) {
          setShowCode(true);
        } else if (response.status === 429) {
          setError(t("login.tooManyAttempts"));
        } else {
          const json = await response.json();
          const message = json?.message || defaultErrorMessage;
          setError(message);
        }
      } catch (e) {
        console.error(e);
        setError(defaultErrorMessage);
      } finally {
        setIsSubmitting(false);
      }
    },
    [isSubmitting, email],
  );

  return (
    <form onSubmit={submit}>
      <TextInput
        id="email-address"
        placeholder={t("login.email")}
        required
        onChange={(event) => setEmail(event.currentTarget.value || "")}
        maxLength={255}
        value={email}
        error={error}
        autoFocus
      />

      <PrimaryButton fitContent loading={isSubmitting} type="submit">
        {t("login.sendCode")}
      </PrimaryButton>
    </form>
  );
};

export default LoginEmail;
