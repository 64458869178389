import React from "react";
import classNames from "classnames";
import { Link } from "@inertiajs/react";

import type { Attributes, ComponentType } from "react";

type Icon = ComponentType;

type SharedProps = {
  icon: Icon;
  size?: "xs" | "sm" | "md";
  color?: string;
  hoverColor?: "default" | "gray";
  shadow?: boolean;
  disabled?: boolean;
};

interface IconButtonProps extends SharedProps {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  tabIndex?: number;
  className?: string;
}

export const IconButton = (props: IconButtonProps) => {
  const {
    icon,
    onClick,
    size = "sm",
    label,
    color = "gray",
    hoverColor = "default",
    tabIndex,
    shadow = false,
    className: propClassName,
    disabled,
  } = props;

  const attributes = { size, color } as Attributes;

  const className = classNames({
    "icon-button": true,
    [`icon-button--${size}`]: true,
    [`icon-button--hover-${hoverColor}`]: hoverColor !== "default",
    [`icon-button--shadow`]: shadow,
    [`icon-button--disabled`]: disabled,
  });

  return (
    <button
      type="button"
      className={`${propClassName} ${className}`.trim()}
      aria-label={label}
      tabIndex={tabIndex}
      onClick={onClick}
      disabled={disabled}
    >
      {React.createElement(icon, attributes)}
    </button>
  );
};

interface IconButtonLinkProps extends SharedProps {
  href: string;
  external?: boolean;
  style?: { [key: string]: string };
  className?: string;
}

export const IconButtonLink = ({
  href,
  icon,
  size,
  color = "gray",
  hoverColor = "default",
  external = false,
  style = {},
  shadow = false,
  className: propClassName,
  ...remainingProps
}: IconButtonLinkProps) => {
  const className = classNames({
    "icon-button": true,
    [`icon-button--${size}`]: true,
    [`icon-button--hover-${hoverColor}`]: hoverColor !== "default",
    [`icon-button--shadow`]: shadow,
  });

  const attributes = { size, color } as Attributes;
  if (external) {
    return (
      <a
        className={`${propClassName} ${className}`.trim()}
        href={href}
        target="_blank"
        rel="noreferrer"
        style={style}
        {...remainingProps}
      >
        {React.createElement(icon, attributes)}
      </a>
    );
  }

  return (
    <Link className={className} href={href} style={style} {...remainingProps}>
      {React.createElement(icon, attributes)}
    </Link>
  );
};
