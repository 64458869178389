import { createInertiaApp, router } from "@inertiajs/react";
import { createElement, ReactNode } from "react";
import { createRoot } from "react-dom/client";
import { FlashProvider } from "@sprout/flash.tsx";
import Layout from "@components/layout";

router.on("success", () => {
  // Remove all emotion style tags in the head between page visits.
  // Otherwise, they will accumulate across page navigations.
  const styleElements = document?.head?.querySelectorAll('style[data-emotion="overwritable"]');

  if (!styleElements) {
    return;
  }

  // Delaying the removing process because the dropdown styling gets broken for a brief
  // moment in between page navigations.
  setTimeout(() => {
    styleElements.forEach((element) => {
      element.remove();
    });
  }, 300);
});

// Temporary type definition, until @inertiajs/react provides one
type ResolvedComponent = {
  default: ReactNode;
  layout?: (page: ReactNode) => ReactNode;
};

createInertiaApp({
  // Set default page title
  // see https://inertia-rails.netlify.app/guide/title-and-meta
  //
  // title: title => title ? `${title} - App` : 'App',

  // Disable progress bar for now because it doesn't work with CSP unsafe-inline
  // https://github.com/inertiajs/progress/issues/21
  progress: false,

  resolve: (name) => {
    const pages = import.meta.glob<ResolvedComponent>("../pages/**/*.tsx", {
      eager: true,
    });
    const page = pages[`../pages/${name}.tsx`];
    if (!page) {
      console.error(`Missing Inertia page component: '${name}.tsx'`);
    }

    page.default.layout = page.default.layout || ((page) => <Layout>{page}</Layout>);
    return page;
  },

  setup({ el, App, props }) {
    if (el) {
      createRoot(el).render(createElement(FlashProvider, null, createElement(App, props)));
    } else {
      console.error(
        "Missing root element.\n\n" +
          "If you see this error, it probably means you load Inertia.js on non-Inertia pages.\n" +
          'Consider moving <%= vite_typescript_tag "inertia" %> to the Inertia-specific layout instead.',
      );
    }
  },
});
