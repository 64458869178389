import React from "react";
import "./websites.scss";
import { TextInput } from "@sprout/text_input";
import { SectionTitle } from "@sprout/typography";
import useTranslation from "@hooks/use_translation";

export interface Websites {
  linkedin?: string | null;
  github?: string | null;
  portfolio?: string | null;
  website?: string | null;
}

interface WebsiteMetadata {
  key: keyof Websites;
}

interface Props {
  websites: Websites;
  setWebsites: (websites: Websites) => void;
}

const WEBSITE_METADATA: Readonly<WebsiteMetadata[]> = Object.freeze([
  { key: "linkedin" },
  { key: "github" },
  { key: "portfolio" },
  { key: "website" },
]);

export const Websites = ({ websites, setWebsites }: Props) => {
  const { t } = useTranslation("user");
  const handleChange = (type: keyof Websites, value: string | null) => {
    setWebsites({ ...websites, [type]: value });
  };

  return (
    <div className="quick_apply__websites">
      <SectionTitle>{t("website.header")}</SectionTitle>
      {WEBSITE_METADATA.map(({ key }) => (
        <TextInput
          key={key}
          id={key}
          label={t(`website.${key}`)}
          value={websites[key] || ""}
          onChange={(e) => handleChange(key as keyof Websites, e.currentTarget.value)}
        />
      ))}
    </div>
  );
};
