import ApplicationHeader from "./application_header";
import type Application from "@/types/application";
import { Stack, Row } from "@sprout/layout";
import { Tag } from "@sprout/tag";
import Divider from "@sprout/divider";
import { SecondaryBody } from "@sprout/typography";

type Props = {
  application: Application;
  dreamJobsActive: boolean;
  hasTokens: boolean;
};

const SelectedApplication = ({ application, dreamJobsActive, hasTokens }: Props) => {
  return (
    <Stack gap="12px">
      <ApplicationHeader
        application={application}
        dreamJobsActive={dreamJobsActive}
        hasTokens={hasTokens}
      />
      <Stack gap="16px">
        <Row gap="24px" align="center">
          {application.locations && <Tag label={application.locations} />}
          {/* Salary is out of scope */}
          <SecondaryBody>{application.status_message}</SecondaryBody>
        </Row>
        <Stack>
          <Divider />
          <div
            className="job-post-contents"
            dangerouslySetInnerHTML={{ __html: application.description }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SelectedApplication;
