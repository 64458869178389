import { Body } from "@sprout/typography.tsx";
import QuestionIcon from "@sprout/icons/question.tsx";

type CalloutProps = {
  headerText?: string;
  bodyText?: React.ReactNode | string;
  link?: React.ReactNode;
  showIcon?: boolean;
  background?: string;
};

const Callout = ({
  showIcon = false,
  background = "var(--pale-blue)",
  bodyText,
  headerText,
  link,
}: CalloutProps) => {
  const mainContent = (
    <>
      {headerText && (
        <Body medium data-provides="call-out--header-text">
          {headerText}
        </Body>
      )}

      {typeof bodyText === "string" ? (
        <Body data-provides="call-out--body-text">{bodyText}</Body>
      ) : (
        bodyText
      )}

      {link}
    </>
  );

  return (
    <div className={"callout"} style={{ background }}>
      {showIcon && (
        <div className={"callout__icon"}>
          <QuestionIcon color={"blue"} size={"md"} />
        </div>
      )}
      <div className={"callout__content"}>{mainContent}</div>
    </div>
  );
};

export default Callout;
