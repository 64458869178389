import React from "react";
import classNames from "classnames";
import "./index.scss";
import NextIcon from "@sprout/icons/next";
import PreviousIcon from "@sprout/icons/previous";
import useTranslation from "@hooks/use_translation.ts";

const range = (start: number, end: number) =>
  Array.from(new Array(end - start), (_, i) => i + start);

const paginate = (currentPage: number, totalPages: number) => {
  const maxVisiblePages = 5;
  const ellipsis = "...";

  if (totalPages <= maxVisiblePages) {
    return range(1, totalPages + 1);
  }

  if (currentPage < maxVisiblePages) {
    return [...range(1, maxVisiblePages), ellipsis, totalPages];
  }

  if (currentPage > totalPages - maxVisiblePages + 1) {
    return [1, ellipsis, ...range(totalPages - 3, totalPages + 1)];
  }

  return [1, ellipsis, ...range(currentPage - 1, currentPage + 2), ellipsis, totalPages];
};

interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange?: (arg1: number) => void;
  center?: boolean;
}

const Pagination = (props: PaginationProps) => {
  const { t } = useTranslation("sprout");
  const { currentPage = 1, totalPages, onPageChange, center } = props;

  const classes = (name: string, value?: number | string) => {
    switch (name) {
      case "pagination":
        return classNames({
          pagination: true,
          "pagination--center": center,
        });
      case "pagination__link":
        return classNames({
          pagination__link: true,
          "pagination__link--active": currentPage === value,
        });
      default:
        return "";
    }
  };

  const previousPage = () => {
    if (currentPage > 1 && onPageChange) {
      onPageChange(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages && onPageChange) {
      onPageChange(currentPage + 1);
    }
  };

  const renderPaginationButton = (type: string) => {
    const previous = type === "previous";
    const firstPage = currentPage === 1;
    const lastPage = currentPage === totalPages;
    const inactive = previous ? firstPage : lastPage;
    const ariaLabel = previous ? t("pagination.previousPage") : t("pagination.nextPage");
    const onClick = previous ? previousPage : nextPage;

    const className = classNames({
      pagination__btn: true,
      [`pagination__${type}`]: true,
      [`pagination__${type}--inactive`]: inactive,
    });

    return (
      <button
        aria-label={ariaLabel}
        aria-disabled={inactive}
        type="button"
        className={className}
        onClick={onClick}
      >
        {previous ? <PreviousIcon viewBox="0 0 7 12" /> : <NextIcon viewBox="0 0 7 12" />}
      </button>
    );
  };

  const ellipsis = "...";

  const paginationLinks = paginate(currentPage, totalPages);

  const links = (
    <ul>
      {paginationLinks.map((item: number | string, index: number) => (
        <li key={index}>
          {item === ellipsis ? (
            <span>{item}</span>
          ) : (
            <button
              aria-label={`Go to page ${item}`}
              aria-current={currentPage === item}
              className={classes("pagination__link", item)}
              onClick={() => {
                // @ts-expect-error TS2722
                onPageChange(+item);
              }}
            >
              {item}
            </button>
          )}
        </li>
      ))}
    </ul>
  );

  return (
    <nav role="navigation" aria-label="Pagination" className={classes("pagination")}>
      {renderPaginationButton("previous")}
      {links}
      {renderPaginationButton("next")}
    </nav>
  );
};

export default Pagination;
