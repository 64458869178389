import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const SearchIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9616 19.9428L16.23 15.2112C16.0551 15.0364 15.9455 14.8068 15.9195 14.5609C15.8935 14.315 15.9527 14.0675 16.0872 13.86C16.9595 12.4781 17.3365 10.841 17.1567 9.21677C16.9768 7.59256 16.2507 6.07758 15.0972 4.92001V4.92001C14.138 3.95957 12.9268 3.28985 11.6033 2.98812C10.2798 2.68638 8.89805 2.76492 7.61729 3.21467C6.33654 3.66443 5.20902 4.46707 4.36479 5.53003C3.52055 6.59298 2.994 7.87294 2.84586 9.22225C2.69773 10.5716 2.93405 11.9353 3.52756 13.1561C4.12107 14.3769 5.0476 15.405 6.20027 16.1219C7.35294 16.8388 8.68479 17.2153 10.0422 17.2079C11.3996 17.2005 12.7273 16.8095 13.872 16.08C14.0791 15.9494 14.3244 15.8929 14.5677 15.92C14.811 15.947 15.0379 16.0559 15.2112 16.2288L19.9656 20.9832C20.0979 21.1155 20.2764 21.191 20.4635 21.1939C20.6505 21.1969 20.8313 21.1269 20.9676 20.9988C21.0368 20.9292 21.0914 20.8466 21.1286 20.7558C21.1657 20.6649 21.1845 20.5677 21.184 20.4696C21.1834 20.3715 21.1635 20.2744 21.1253 20.184C21.0872 20.0936 21.0315 20.0117 20.9616 19.9428V19.9428ZM5.93283 14.0784C5.12724 13.2729 4.57861 12.2465 4.35633 11.1292C4.13405 10.0119 4.24809 8.8537 4.68404 7.80118C5.11999 6.74865 5.85827 5.84904 6.8055 5.21611C7.75274 4.58317 8.86639 4.24534 10.0056 4.24534C11.1449 4.24534 12.2585 4.58317 13.2058 5.21611C14.153 5.84904 14.8913 6.74865 15.3272 7.80118C15.7632 8.8537 15.8772 10.0119 15.6549 11.1292C15.4326 12.2465 14.884 13.2729 14.0784 14.0784C12.9973 15.1568 11.5326 15.7624 10.0056 15.7624C8.47863 15.7624 7.01396 15.1568 5.93283 14.0784Z"
      />
    </IconSvgWrapper>
  );
};

export default SearchIcon;
