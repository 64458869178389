import theme from "@/stylesheets/theme.module.scss";
import i18n from "@entrypoints/i18n.ts";

const t = i18n.getFixedT(null, "user");

export enum CompletionStatus {
  Complete = "complete",
  AlmostFinished = "almost_finished",
  InProgress = "in_progress",
  NotStarted = "not_started",
}

export type CompletionStatusType = `${CompletionStatus}`;

export const statusInfo = (status: CompletionStatusType) => {
  if (status === CompletionStatus.Complete) {
    return { label: t("progress.complete"), color: theme.greenE100 };
  } else if (status === CompletionStatus.AlmostFinished) {
    return { label: t("progress.almostFinished"), color: theme.irisE50 };
  } else if (status === CompletionStatus.InProgress) {
    return { label: t("progress.inProgress"), color: theme.yellowE25 };
  } else {
    return { label: t("progress.notStarted"), color: theme.smokeGray };
  }
};
