import React from "react";
import { Body, Link } from "@/sprout/typography";
import Button from "@/sprout/button";
import useTranslation from "@hooks/use_translation";
import { useTaskDismiss } from "./taskActions";
import "./task_card.scss";
import { type Task } from "@/types/task";
import GreenhouseLogoIcon from "@/sprout/icons/greenhouse_logo";

type TaskCardProps = {
  statusText: string;
  task: Task;
  title: string;
  description?: string;
  body?: JSX.Element;
  actionText: string;
  icon?: React.ReactNode;
  theme?: {
    colors: {
      background?: string;
      border?: string;
      iconBackground?: string;
    };
  };
  dismissable?: boolean;
} & ({ actionHref: string; Modal?: never } | { actionHref?: never; Modal: React.ComponentType });

const defaultTheme = {
  colors: { background: "bg-pale-blue", border: "border-iris-e50", iconBackground: "bg-iris-e50" },
};

const TaskCard: React.FC<TaskCardProps> = ({
  statusText,
  task,
  title,
  description,
  body,
  actionHref,
  Modal,
  actionText,
  icon,
  theme = defaultTheme,
  dismissable = true,
}) => {
  const { t } = useTranslation("tasks");
  const handleDismiss = useTaskDismiss(task.id);

  return (
    <div
      className={`flex flex-col justify-between task-card w-[295px] p-4 border rounded-lg ${theme.colors.background} ${theme.colors.border}`}
    >
      <div>
        <div className="flex gap-[4px] items-center mb-[4px]">
          <div
            className={`task-card__icon flex items-center justify-center ${theme.colors.iconBackground}`}
          >
            {(icon && icon) || <GreenhouseLogoIcon size="sm" color="#15372C" />}
          </div>
          <Body className="!text-xs !ml-1" metadata>
            {statusText}
          </Body>
        </div>
        <Body medium>{title}</Body>
        {(body && body) || <Body className="!text-sm">{description}</Body>}
      </div>
      <div className="flex justify-start items-center space-x-4">
        {actionHref && (
          <Button href={actionHref} size="medium" link external>
            {actionText}
          </Button>
        )}
        {Modal && <Modal />}
        {dismissable && (
          <Link secondary onClick={handleDismiss}>
            {t("common.dismiss")}
          </Link>
        )}
      </div>
    </div>
  );
};

export default TaskCard;
