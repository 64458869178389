import { Body } from "@sprout/typography";
import { Link } from "@inertiajs/react";
import EditIcon from "@sprout/icons/edit";
import Button from "@/sprout/button";
import "./entry_point.scss";
import useTranslation from "@hooks/use_translation";
import CompletionTag from "@components/completion_tag.tsx";
import { CompletionStatus, CompletionStatusType } from "@utils/completion_status.ts";
import useDevice from "@hooks/use_device.ts";

type Props = {
  href: string;
  sectionHeader: string;
  fullName?: string;
  email?: string | null;
  phoneNumber?: string | null;
  location?: string;
  description?: string;
  workPreferences?: string[];
  showDescription?: boolean;
  completionStatus: CompletionStatusType;
};

const EntryPoint = (props: Props) => {
  const {
    href,
    sectionHeader,
    fullName,
    email,
    phoneNumber,
    location,
    completionStatus,
    description,
    workPreferences = [],
    showDescription,
  } = props;
  const { t } = useTranslation("user");
  const { type } = useDevice();

  const savedWorkPreferences = workPreferences?.map((preference, index) => (
    <Body key={index} light>
      {preference}
    </Body>
  ));

  return (
    <div className="entry-point">
      <Link href={href} className="entry-point__header">
        <Body medium>{sectionHeader}</Body>
        <CompletionTag status={completionStatus} />
        {completionStatus === CompletionStatus.NotStarted ? (
          <>
            {type !== "mobile" && (
              <Button href={href} link={true} fitContent={true} size="medium">
                {t("getStarted")}
              </Button>
            )}
          </>
        ) : (
          <div className="entry-point__header__icon">
            <EditIcon />
          </div>
        )}
      </Link>
      {fullName && <Body medium>{fullName}</Body>}
      {email && <Body light>{email}</Body>}
      {phoneNumber && <Body light>{phoneNumber}</Body>}
      {location && <Body light>{location}</Body>}
      {workPreferences.length > 0 && savedWorkPreferences}
      <div className={`entry-point__description ${type === "mobile" && "items-center"}`}>
        {showDescription && description && <Body light>{description}</Body>}
        {type === "mobile" && completionStatus === CompletionStatus.NotStarted && (
          <Button href={href} link={true} size="medium">
            {t("getStarted")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default EntryPoint;
