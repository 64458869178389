import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@components/ui/dialog.tsx";
import { Trans } from "react-i18next";
import Button from "@sprout/button.tsx";

type Props = {
  triggerText?: string;
};

const MissingApplicationsModal = ({ triggerText }: Props) => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button linkStyle underline size={"medium"}>
          {triggerText ? triggerText : <Trans i18nKey="applications:learn_why" />}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>
            <Trans i18nKey="applications:not_found_modal:title" />
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Trans i18nKey="applications:not_found_modal:explanation" />
        </DialogDescription>
        <DialogFooter className="sm:justify-start">
          <DialogClose>
            <Button primary>
              <Trans i18nKey="applications:not_found_modal:close" />
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MissingApplicationsModal;
