import React from "react";
import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const CheckIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...{ ...defaultIconProps, ...props }}>
      <path
        className={`icon--${props.color}`}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.224 12C1.224 17.94 6.06 22.776 12 22.776C17.94 22.776 22.776 17.94 22.776 12C22.776 6.06 17.94 1.224 12 1.224C6.06 1.224 1.224 6.06 1.224 12ZM2.628 12C2.628 6.828 6.828 2.628 12 2.628C17.172 2.628 21.372 6.828 21.372 12C21.372 17.172 17.172 21.372 12 21.372C6.828 21.372 2.628 17.172 2.628 12ZM7.404 12.9L10.008 15.504C10.272 15.768 10.716 15.768 11.016 15.504L16.848 9.672C17.124 9.396 17.124 8.952 16.848 8.676C16.572 8.4 16.128 8.4 15.852 8.676L10.668 13.86C10.584 13.944 10.44 13.944 10.356 13.86L8.4 11.904C8.124 11.628 7.68 11.628 7.404 11.904C7.128 12.18 7.128 12.624 7.404 12.9Z"
      />
    </IconSvgWrapper>
  );
};

export default CheckIcon;
