import "react-tooltip/dist/react-tooltip.css";
import ExpandableMenu from "@/sprout/expandable_menu";
import "./nav_menu.scss";

type Props = {
  icon: React.ReactNode;
  items: { value: number; label: string; onSelect: () => void }[];
};

const NavMenu = (props: Props) => {
  return (
    <div className="nav-icon">
      <ExpandableMenu
        icon={props.icon}
        items={props.items}
        onSelect={(item) => item?.onSelect?.()}
      />
    </div>
  );
};

export default NavMenu;
