import { useState, useEffect } from "react";
import useCsrfToken from "@hooks/use_csrf";
import { useEffectAfterMount } from "@hooks/use_effect_after_mount";
import { useAsyncOptions } from "@hooks/use_async_options";
import UnloadHandler from "@components/unload_handler";
import { Body, SectionHeader } from "@sprout/typography";
import { Option, Select } from "@sprout/select";
import Button, { PrimaryButton } from "@sprout/button";
import { Checkbox } from "@sprout/checkbox";
import "./work_preferences.scss";
import LocationSelect from "@components/location_select";
import { LocationControl, Location } from "@utils/location";
import { showFlash } from "@sprout/flash.tsx";
import { useTranslation } from "react-i18next";
import Container from "@components/ui/container.tsx";
import BackButton from "@components/back_button.tsx";

type Props = {
  employmentTypes: Option[];
  worksites: Option[];
  currentLanguages: Option[];
  currentEmploymentTypes: Option[];
  currentWorksites: Option[];
  currentRelocation: boolean | null;
  currentLocation: Location | null;
  locationControlParams: LocationControl;
  paths: {
    workPreferencesPath: string;
  };
};

const WorkPreferences = (props: Props) => {
  const {
    paths,
    currentLanguages,
    currentEmploymentTypes,
    currentWorksites,
    currentRelocation,
    currentLocation,
    locationControlParams,
  } = props;

  const [languages, setLanguages] = useState<Option[]>(currentLanguages);
  const [languageOptions, setLanguageOptions] = useState<Option[]>([]);
  const [languagePage, setLanguagePage] = useState(1);
  const [languageSearch, setLanguageSearch] = useState("");
  const [languageError, setLanguageError] = useState<string | null>(null);
  const [location, setLocation] = useState<Location | null>(currentLocation);
  const [employmentTypes, setEmploymentTypes] = useState<Option[]>(currentEmploymentTypes);
  const [worksites, setWorksites] = useState<Option[]>(currentWorksites);
  const [relocation, setRelocation] = useState<boolean | null>(currentRelocation);
  const [relocationError, setRelocationError] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const csrfToken = useCsrfToken();
  const { t } = useTranslation("user");

  useEffect(() => {
    const loadAsyncOptions = async () => {
      const path = `/languages?page=${languagePage}`;
      const { newOptions } = await useAsyncOptions(path, languageSearch, languageOptions);

      setLanguageOptions(newOptions);
    };

    loadAsyncOptions();
  }, [languageSearch]);

  useEffectAfterMount(() => {
    setUnsavedChanges(true);
  }, [languages, employmentTypes, worksites, relocation, location, relocationError, error]);

  const submit = async () => {
    setLoading(true);
    setError(null);
    setLanguageError(null);
    setRelocationError(null);

    try {
      const formData = new FormData();

      languages.forEach((language) => {
        formData.append("languages[][id]", language.value.toString());
      });

      employmentTypes.forEach((employmentType) => {
        formData.append("employment_types[][category]", employmentType.value.toString());
      });

      worksites.forEach((worksite) => {
        formData.append("worksites[][category]", worksite.value.toString());
      });

      formData.append("preference[relocation]", relocation?.toString() || "");
      formData.append("preference[location]", JSON.stringify(location));
      formData.append("authenticity_token", csrfToken);

      const response = await fetch(paths.workPreferencesPath, {
        method: "PATCH",
        body: formData,
      });

      if (response.status === 422) {
        const { errors } = await response.json();

        if (errors.user_language) {
          setLanguageError(errors.user_language[0]);
        }

        if (errors.relocation) {
          setRelocationError(errors.relocation[0]);
        }

        return;
      }

      if (!response.ok) {
        throw new Error("Not ok");
      }

      showFlash(t("workPreferences.flashSuccess"), "success");
      setUnsavedChanges(false);
      window.history.back();
    } catch (e) {
      console.error(e);
      setError(t("workPreferences.flashError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {unsavedChanges && <UnloadHandler />}
      <BackButton href="/profile" />
      <SectionHeader large as="h1">
        {t("workPreferences.header")}
      </SectionHeader>
      <Body>{t("workPreferences.description")}</Body>
      <Select
        id="languages"
        label="Languages spoken"
        options={languageOptions}
        // @ts-expect-error - TS2322
        onSelect={setLanguages}
        onMenuScrollToBottom={() => setLanguagePage(languagePage + 1)}
        onInputChange={(value) => setLanguageSearch(value)}
        selected={languages}
        short
        isClearable
        isMulti
        helperText={t("workPreferences.languagesHelperText")}
        error={languageError}
      />
      <Select
        id="employment-types"
        label="Employment types"
        options={props.employmentTypes}
        // @ts-expect-error - TS2322
        onSelect={setEmploymentTypes}
        selected={employmentTypes}
        short
        isClearable
        isMulti
      />
      <Select
        id="worksites"
        label="Worksites"
        options={props.worksites}
        // @ts-expect-error - TS2322
        onSelect={setWorksites}
        selected={worksites}
        short
        isClearable
        isMulti
      />
      <LocationSelect
        currentLocation={location}
        setLocation={setLocation}
        locationControlParams={locationControlParams}
        helperText={t("workPreferences.locationHelperText")}
        short
      />
      <Checkbox
        id="relocation"
        options={[{ value: "1", label: t("workPreferences.willingToRelocate") }]}
        onSelect={() => setRelocation(!relocation)}
        values={relocation ? ["1"] : []}
        error={relocationError}
        isMulti
      />

      <div className="work_preferences__submit-buttons">
        <PrimaryButton onClick={submit} loading={loading}>
          {t("workPreferences.save")}
        </PrimaryButton>
        <Button onClick={() => window.history.back()}>{t("workPreferences.cancel")}</Button>
        {error && (
          <p
            id="work-preferences-error"
            className="helper-text helper-text--error"
            aria-live="polite"
          >
            {error}
          </p>
        )}
      </div>
    </Container>
  );
};

export default WorkPreferences;
