export interface TagProps {
  label: string;
  color?: string;
  spacing?: boolean;
  className?: string;
}

export const Tag = ({ label, color, spacing, className }: TagProps) => {
  return (
    <span
      className={`tag-container ${className || ""}`}
      style={{ backgroundColor: color, marginLeft: spacing ? 8 : 0 }}
    >
      <span className="ellipsis">
        <span className="tag-text">{label}</span>
      </span>
    </span>
  );
};

export { default as useTagOverflow } from "./hooks/use_tag_overflow";
