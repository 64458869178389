import { Body } from "@/sprout/typography";
import CompanyLogo from "../company_logo";
import DepartmentTags from "../department_tags";
import { Subscription } from "@/pages/dashboard";
import "@/components/job_alerts/row.scss";

const JobAlertsRow = ({ subscription }: { subscription: Subscription }) => {
  const departments = subscription.departments;
  const hasDepartments = departments.length > 0;
  const rowClassName = hasDepartments
    ? "job-alerts-list__row"
    : "job-alerts-list__row job-alerts-list__row--single";

  return (
    <li className={rowClassName}>
      <div className={`job-alerts-list__row__left`}>
        <CompanyLogo
          logoUrl={subscription.logoUrl}
          size="xxsmall"
          companyName={subscription.boardName}
        />
        <Body>{subscription.boardName}</Body>
      </div>
      {hasDepartments && (
        <div className="job-alerts-list__row__right">
          <DepartmentTags departments={departments} />
        </div>
      )}
    </li>
  );
};

export default JobAlertsRow;
