import { type ReactNode } from "react";
import Fingerprint6 from "../images/fingerprint_6.svg";
import { Body, Link, PageHeader } from "@sprout/typography";
import "./error.scss";

interface Props {
  header: string | ReactNode;
  message?: string | string[] | ReactNode | ReactNode[];
  link?: {
    label: string;
    href: string;
  };
  fullPage?: boolean;
}

export const Error = ({ header, message, link, fullPage = true }: Props) => {
  const messageParagraphs = Array.isArray(message) ? message : [message];

  return (
    <div className={`error ${fullPage ? "full-page" : ""}`}>
      <div className="error__wrapper">
        <img src={Fingerprint6} alt="Greenhouse fingerprint leaf" height="300" />
        <div className="error__details">
          <PageHeader>{header}</PageHeader>
          {messageParagraphs.map((paragraph, index) => (
            <Body key={index}>{paragraph}</Body>
          ))}
        </div>
        {link && <Link href={link.href}>{link.label}</Link>}
      </div>
    </div>
  );
};
