import { useQuery } from "@tanstack/react-query";
import { CompletionStatusType } from "@utils/completion_status.ts";

export type Task = {
  id: number;
  taskable_type: string;
  status: CompletionStatusType;
};

type TasksData = {
  tasks: Task[];
};

const fetchTasks = async (): Promise<Task[]> => {
  const response = await fetch("/tasks.json");
  if (!response.ok) {
    throw new Error("Failed to fetch tasks");
  }
  return response.json();
};

export default function useTasks() {
  const {
    data: tasksData,
    error,
    isLoading,
  } = useQuery<Task[], Error>({
    queryKey: ["tasks"],
    queryFn: fetchTasks,
    staleTime: 0,
    refetchOnMount: true,
  });

  const data: TasksData | null = tasksData ? { tasks: tasksData } : null;

  return { data, error, loading: isLoading };
}
