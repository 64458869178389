import useTranslation from "@/hooks/use_translation";
import { Body, SecondaryBody } from "@/sprout/typography";

type Props = {
  live: boolean;
  deviceType: "mobile" | "web";
};

const StatusIndicator = (props: Props) => {
  const { live, deviceType } = props;
  const { t } = useTranslation("recentlyViewedJobs");

  const statusText = live ? t("status.live") : t("status.closed");
  const StatusTextComponent = deviceType === "mobile" ? SecondaryBody : Body;

  return (
    <div className="recently-viewed-jobs-table__status">
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <circle cx="4" cy="4" r="4" fill={live ? "#24A47F" : "#D8372A"} />
      </svg>
      <StatusTextComponent>{statusText}</StatusTextComponent>
    </div>
  );
};

export default StatusIndicator;
