import "./container.scss";
import { ReactNode } from "react";

const Container = ({ children }: { children: ReactNode }) => {
  return (
    <div className="react-container">
      <main>
        <div className="react-container__placemat">{children}</div>
      </main>
    </div>
  );
};

export default Container;
