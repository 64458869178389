import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const ForwardIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        fill={props.color}
        transform="rotate(180, 12, 12)"
        d="M3.57738 12.5171L9.88098 18.8219C10.0163 18.9552 10.1988 19.0296 10.3887 19.0289C10.5787 19.0283 10.7607 18.9526 10.8951 18.8183C11.0295 18.6841 11.1054 18.5022 11.1062 18.3123C11.1071 18.1223 11.0329 17.9397 10.8998 17.8043L6.84858 13.7531C6.76452 13.6692 6.70723 13.5623 6.68395 13.4459C6.66067 13.3295 6.67246 13.2088 6.71781 13.0991C6.76316 12.9894 6.84004 12.8956 6.93873 12.8295C7.03741 12.7635 7.15346 12.7283 7.27218 12.7283L19.8818 12.7283C20.0726 12.7314 20.2573 12.6605 20.3971 12.5306C20.5369 12.4006 20.621 12.2216 20.6318 12.0311C20.6348 11.9346 20.6185 11.8385 20.5837 11.7485C20.5488 11.6585 20.4963 11.5764 20.4291 11.5072C20.362 11.4379 20.2816 11.3828 20.1927 11.3452C20.1038 11.3076 20.0083 11.2882 19.9118 11.2883L7.27218 11.2883C7.15346 11.2882 7.03741 11.253 6.93873 11.187C6.84004 11.121 6.76316 11.0272 6.71781 10.9175C6.67246 10.8078 6.66067 10.687 6.68395 10.5706C6.70723 10.4542 6.76452 10.3473 6.84858 10.2635L10.8998 6.21227C10.9675 6.14558 11.0213 6.06613 11.0582 5.97853C11.095 5.89092 11.1142 5.79688 11.1145 5.70184C11.1148 5.60679 11.0963 5.51263 11.0601 5.42476C11.0239 5.3369 10.9706 5.25709 10.9033 5.18992C10.8361 5.12275 10.7562 5.06957 10.6683 5.03343C10.5804 4.99728 10.4862 4.9789 10.3912 4.97935C10.2961 4.97979 10.2021 4.99904 10.1145 5.036C10.027 5.07296 9.9476 5.12689 9.88098 5.19468L3.57738 11.4995C3.44255 11.6345 3.36681 11.8175 3.36681 12.0083C3.36681 12.1991 3.44255 12.3821 3.57738 12.5171Z"
      />
    </IconSvgWrapper>
  );
};

export default ForwardIcon;
