import { Body } from "@/sprout/typography";
import "./active_applications.scss";
import ApplicationCardList from "../applications/application_card_list";
import useApplications from "@/hooks/use_applications";
import { router } from "@inertiajs/react";
import { useState } from "react";
import Pagination from "../pagination";
import EmptyState from "@components/empty_state.tsx";
import Fingerprint1 from "@images/fingerprint_1.svg";
import { useTranslation, Trans } from "react-i18next";
import MissingApplicationsModal from "@components/applications/missing_applications_modal.tsx";

const MAX_APPLICATIONS_PER_PAGE = 8;

type Props = {
  renderHeader: (count: number) => JSX.Element;
  dreamJobsActive: boolean;
};

const countTranslator = (key: string, options?: { count: number }) => (
  <Trans i18nKey={`applications:${key}`} {...(options || {})} />
);

const ActiveApplications = ({ renderHeader, dreamJobsActive }: Props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const { t } = useTranslation("applications");
  const { data } = useApplications({
    page: currentPage,
    perPage: MAX_APPLICATIONS_PER_PAGE,
    active_only: true,
  });

  const applications = data?.active?.applications || [];
  const totalPages = data?.active?.total_pages || 1;
  const totalApplications = data?.active?.count || 0;

  return (
    <>
      {renderHeader(totalApplications)}
      <div className="active-applications__list">
        {!applications.length && (
          <EmptyState
            svg={Fingerprint1}
            svgSize={"150"}
            title={t("find_great_job")}
            fontWeight={"400"}
            message={t("missing_an_application")}
            link={<MissingApplicationsModal triggerText={t("find_out_why")} />}
          />
        )}
        <ApplicationCardList
          applications={applications}
          onApplicationSelect={(id) => {
            router.visit(`/applications/${id}`);
          }}
          showCallout={false}
          dreamJobsActive={dreamJobsActive}
        />
        {totalPages > 1 && (
          <div className="active-applications__footer">
            <Body>
              {countTranslator("count_of_applications_per_page", {
                count: MAX_APPLICATIONS_PER_PAGE,
              })}
            </Body>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => {
                setCurrentPage(page);
                window.scrollTo(0, 0);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ActiveApplications;
