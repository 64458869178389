import React from "react";
import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const CheckMarkIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        d="M6.25119 12.5418C6.12184 12.5419 5.99765 12.491 5.90559 12.4002L2.41919 8.97617C2.3702 8.93153 2.33071 8.87746 2.3031 8.8172C2.27549 8.75694 2.26031 8.69173 2.25849 8.62547C2.25666 8.55921 2.26822 8.49327 2.29248 8.43158C2.31673 8.36989 2.35318 8.31373 2.39965 8.26646C2.44611 8.21919 2.50164 8.18178 2.5629 8.15646C2.62416 8.13115 2.6899 8.11845 2.75618 8.11913C2.82246 8.11982 2.88792 8.13386 2.94864 8.16043C3.00937 8.18701 3.06411 8.22555 3.10959 8.27377L5.96479 11.0738C6.03956 11.1471 6.14009 11.1881 6.24479 11.1881C6.34949 11.1881 6.45002 11.1471 6.52479 11.0738L12.9504 4.64017C13.0433 4.55052 13.1676 4.5009 13.2967 4.50197C13.4258 4.50304 13.5493 4.55472 13.6407 4.64589C13.7321 4.73707 13.784 4.86046 13.7854 4.98954C13.7868 5.11862 13.7374 5.24308 13.648 5.33617L6.59919 12.4002C6.55339 12.4456 6.49907 12.4814 6.43934 12.5057C6.37962 12.53 6.31567 12.5423 6.25119 12.5418V12.5418Z"
        strokeWidth="0.5"
        className={`icon--${props.color}`}
        fill={props.color}
      />
    </IconSvgWrapper>
  );
};

export default CheckMarkIcon;
