import React, { useState } from "react";
import LoginSecurityCode from "@components/login_security_code";
import LoginEmail from "@components/login_email";
import GreenhouseLogo from "@images/greenhouse_logo.svg";
import useTranslation from "@hooks/use_translation";
import Layout from "@components/layout";

import "./login.scss";

type Props = {
  jobBoardToken: string;
  createPath: string;
  submitCodePath: string;
  jobBoardRendererDomains: string[];
};

const Login = (props: Props) => {
  const { jobBoardToken, createPath, jobBoardRendererDomains, submitCodePath } = props;
  const { t } = useTranslation("misc");

  const [showCode, setShowCode] = useState(false);
  const [email, setEmail] = useState<string>("");

  return (
    <div className="login">
      <div className="login__left">
        <div className="login__leaf"></div>
      </div>
      <div className="login__right">
        <div className="login__content">
          <img src={GreenhouseLogo} alt="Greenhouse logo" height="40" />
          <div className="login__heading" dangerouslySetInnerHTML={{ __html: t("login.splash") }} />
          <div className="login__subheader">
            <div>{t("login.subheader")}</div>
            {!showCode && <div>{t("login.cta")}</div>}
          </div>

          {showCode ? (
            <LoginSecurityCode
              submitCodePath={submitCodePath}
              email={email}
              setShowCode={setShowCode}
              jobBoardRendererDomains={jobBoardRendererDomains}
            />
          ) : (
            <LoginEmail
              jobBoardToken={jobBoardToken}
              createPath={createPath}
              setShowCode={setShowCode}
              email={email}
              setEmail={setEmail}
            />
          )}
        </div>
      </div>
    </div>
  );
};

Login.layout = (page: React.ReactNode) => <Layout publicFacing>{page}</Layout>;

export default Login;
