import React from "react";
import useTranslation from "@hooks/use_translation";
import TaskCard from "./TaskCard";
import type { Task } from "@/types/task";

interface TaskCardProps {
  task: Task;
  statusText: string;
}

const SetupQuickApplyProfile: React.FC<TaskCardProps> = ({ task, statusText }) => {
  const { t } = useTranslation("tasks");

  return (
    <TaskCard
      statusText={statusText}
      task={task}
      title={t("quickApply.title")}
      description={t("quickApply.description")}
      actionHref="/quick_apply"
      actionText={t("quickApply.completeProfile")}
    />
  );
};

export default SetupQuickApplyProfile;
