import { useState, useEffect } from "react";

const DEFAULT_TTL = 60 * 1000 * 5; // 5 minutes

const useTemporaryStorage = (key: string, initialValue: string, ttl: number = DEFAULT_TTL) => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    const expirationTime = localStorage.getItem(`${key}-expiration`);

    if (storedValue && expirationTime && new Date().getTime() < parseInt(expirationTime)) {
      return storedValue;
    } else {
      localStorage.removeItem(key);
      localStorage.removeItem(`${key}-expiration`);
      return initialValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, value);
    const expirationTime = new Date().getTime() + ttl;
    localStorage.setItem(`${key}-expiration`, expirationTime.toString());
  }, [key, value, ttl]);

  return [value, setValue] as const;
};

export default useTemporaryStorage;
