import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const QuestionIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };
  return (
    <IconSvgWrapper {...props}>
      <path
        fill={props.color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.224C6.06 1.224 1.224 6.06 1.224 12C1.224 17.94 6.06 22.776 12 22.776C17.94 22.776 22.776 17.94 22.776 12C22.776 6.06 17.94 1.224 12 1.224ZM12 21.372C6.828 21.372 2.628 17.172 2.628 12C2.628 6.828 6.828 2.628 12 2.628C17.172 2.628 21.372 6.828 21.372 12C21.372 17.172 17.172 21.372 12 21.372ZM12 18.2041C12.5103 18.2041 12.924 17.7904 12.924 17.2801C12.924 16.7698 12.5103 16.3561 12 16.3561C11.4897 16.3561 11.076 16.7698 11.076 17.2801C11.076 17.7904 11.4897 18.2041 12 18.2041ZM8.292 8.04008C8.712 6.26408 10.368 4.96808 12.3 5.11208C14.172 5.25608 15.684 6.79208 15.792 8.66408C15.9 10.5601 14.616 12.1801 12.864 12.5881C12.768 12.6121 12.696 12.7081 12.696 12.8041V14.4001C12.696 14.7841 12.384 15.0961 12 15.0961H11.988C11.604 15.0961 11.292 14.7841 11.292 14.4001V11.9401C11.292 11.5801 11.58 11.2921 11.94 11.2921C13.236 11.2921 14.364 10.2481 14.388 8.95208C14.412 7.59608 13.332 6.49208 11.988 6.49208C10.848 6.49208 9.888 7.28408 9.648 8.35208C9.576 8.67608 9.288 8.89208 8.964 8.89208C8.52 8.89208 8.196 8.47208 8.292 8.04008Z"
      />
    </IconSvgWrapper>
  );
};

export default QuestionIcon;
