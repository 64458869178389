import React from "react";
import Header from "@components/header";
import CsrfContext from "@contexts/csrf_context";
import InformCookiesPopup from "@components/inform_cookies_popup";
import { usePage } from "@inertiajs/react";
import "../entrypoints/i18n";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import rollbar from "@utils/rollbar.ts";
import ErrorBoundaryContext from "@contexts/error_boundary_context.ts";
import { queryClient, localStoragePersister } from "../queries/query_client";
import { useFlash, type RailsFlash } from "@/sprout/flash";

interface PageProps {
  nonce: string;
  csrfToken: string;
  flash: RailsFlash;
  [key: string]: unknown;
}

interface LayoutProps {
  publicFacing?: boolean;
  children: React.ReactNode;
  hideProfile?: boolean;
}

const ONE_DAY = 1000 * 60 * 60 * 24;

const Layout: React.FC<LayoutProps> = (props) => {
  const { publicFacing = false, children, hideProfile = false } = props;
  const { csrfToken, nonce, flash } = usePage<PageProps>().props;

  useFlash(flash);

  // csrfToken sharing should eventually be deprecated in favor of using
  // the inertia router which has csrf support
  return (
    <ErrorBoundaryContext.Provider value={rollbar}>
      <CsrfContext.Provider value={csrfToken}>
        <PersistQueryClientProvider
          client={queryClient}
          persistOptions={{
            persister: localStoragePersister,
            maxAge: ONE_DAY,
            buster: "v1",
          }}
        >
          {!publicFacing && <Header hideProfile={hideProfile} />}
          {children}
          <InformCookiesPopup />
          <ReactQueryDevtools initialIsOpen={false} styleNonce={nonce} />
        </PersistQueryClientProvider>
      </CsrfContext.Provider>
    </ErrorBoundaryContext.Provider>
  );
};

export default Layout;
