import EntryPoint from "@components/entry_point";
import { Body, SectionHeader } from "@sprout/typography";
import useTranslation from "@hooks/use_translation";
import LegalLinks from "@components/legal_links.tsx";
import Container from "@components/ui/container.tsx";
import useProfile from "@/queries/use_profile";
import LoadingSpinner from "@/sprout/loading_spinner";

const Profile = () => {
  const { t } = useTranslation("user");
  const { data, isLoading, error } = useProfile();

  const ProfileHeader = () => (
    <SectionHeader medium as="h1">
      {t("profile")}
    </SectionHeader>
  );

  if (isLoading) {
    return (
      <Container>
        <ProfileHeader />
        <div className="flex justify-center items-center h-64">
          <LoadingSpinner size="medium" />
        </div>
      </Container>
    );
  }

  if (error || !data) {
    return (
      <Container>
        <ProfileHeader />
        <Body>{t("defaultError")}</Body>
      </Container>
    );
  }

  const {
    email,
    phoneNumber,
    preferredName,
    location,
    accountCompletion,
    quickApplyCompletion,
    workPreferencesCompletion,
    workPreferences,
  } = data;

  const firstName = data.firstName || "";
  const lastName = data.lastName || "";
  const fullName = `${firstName} ${lastName}`;
  const displayName = preferredName ? `${firstName} (${preferredName}) ${lastName}` : fullName;

  return (
    <Container>
      <ProfileHeader />
      <EntryPoint
        href="/account"
        sectionHeader={t("account")}
        fullName={fullName}
        email={email}
        completionStatus={accountCompletion}
      />
      <EntryPoint
        href="/quick_apply"
        sectionHeader={t("quickApply")}
        fullName={displayName}
        phoneNumber={phoneNumber}
        location={location?.display_name}
        description={t("quick_apply_description")}
        showDescription={!phoneNumber && !location?.display_name && !displayName.trim()}
        completionStatus={quickApplyCompletion}
      />
      <EntryPoint
        href="/work_preferences"
        sectionHeader={t("workPreferences.title")}
        completionStatus={workPreferencesCompletion}
        description={t("work_preferences_description")}
        workPreferences={workPreferences}
        showDescription={workPreferences.length <= 0}
      />
      <LegalLinks />
    </Container>
  );
};

export default Profile;
