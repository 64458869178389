import React, { useState } from "react";
import { TermsOfServiceForm } from "@components/terms_of_service/form";
import { Body, SectionHeader } from "@sprout/typography";
import { PrimaryButton } from "@sprout/button";
import useCsrfToken from "@hooks/use_csrf";
import useTranslation from "@hooks/use_translation";

const UpdatedUserAgreement = () => {
  const { t } = useTranslation("misc");
  const csrfToken = useCsrfToken();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError(null);

    const defaultErrorMessage = t("defaultError");

    try {
      const response = await fetch(window.location.href, {
        method: "POST",
        body: JSON.stringify({
          accept_terms: acceptedTerms ? "1" : "0",
          authenticity_token: csrfToken,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        setError(defaultErrorMessage);
        return;
      }

      window.location.href = response.url;
    } catch (e) {
      console.error(e);
      setError(defaultErrorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="container">
      <SectionHeader>{t("updatedUserAgreement.header")}</SectionHeader>
      <main>
        <Body>{t("updatedUserAgreement.notice")}</Body>
        <TermsOfServiceForm error={error} onChange={setAcceptedTerms} value={acceptedTerms} />
        <PrimaryButton
          fitContent
          onClick={handleSubmit}
          loading={isSubmitting}
          disabled={!acceptedTerms}
        >
          {t("submit")}
        </PrimaryButton>
      </main>
    </div>
  );
};

export default UpdatedUserAgreement;
