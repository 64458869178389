import { useQuery } from "@tanstack/react-query";
import type { RecentJobView } from "@/components/recently_viewed_jobs/table";

const useRecentlyViewedJobs = () => {
  const fetchRecentlyViewedJobs = async (): Promise<RecentJobView[]> => {
    const response = await fetch("/recently_viewed_jobs/dashboard");
    if (!response.ok) {
      throw new Error("Failed to fetch recently viewed jobs");
    }
    return response.json();
  };

  return useQuery({
    queryKey: ["recentlyViewedJobs", "dashboard"],
    queryFn: fetchRecentlyViewedJobs,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
};

export default useRecentlyViewedJobs;
