import React, { useCallback, useEffect, useRef, useState } from "react";
import { PrimaryButton } from "@sprout/button";
import useCsrfToken from "@hooks/use_csrf";
import { Body, Link } from "@sprout/typography";
import ArrowLeftIcon from "@sprout/icons/arrow_left";

import "./login_security_code.scss";
import { CODE_LENGTH, CodeInput } from "@components/code_input";
import useTranslation from "@hooks/use_translation";

type CodeFormProps = {
  submitCodePath: string;
  email: string;
  setShowCode: (showCode: boolean) => void;
  jobBoardRendererDomains: string[];
};

const LoginSecurityCode = ({
  email,
  jobBoardRendererDomains,
  setShowCode,
  submitCodePath,
}: CodeFormProps) => {
  const { t } = useTranslation("misc");
  const csrfToken = useCsrfToken();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [securityCode, setSecurityCode] = useState("");
  const [error, setError] = useState<string | null>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const canSubmit = securityCode.length === CODE_LENGTH;

  const submit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();

      if (isSubmitting || !canSubmit) {
        return;
      }

      setIsSubmitting(true);
      setError(null);

      try {
        const response = await fetch(submitCodePath, {
          method: "POST",
          body: JSON.stringify({
            code: securityCode,
            email: email,
            authenticity_token: csrfToken,
          }),
          headers: { "Content-Type": "application/json" },
        });

        if (response.ok) {
          const data = await response.json();

          if (data.postToRenderer) {
            jobBoardRendererDomains.forEach((jobBoardRendererDomain) => {
              window.opener?.postMessage("loginSuccessful", `https://${jobBoardRendererDomain}`);
            });
          }

          window.location.href = data.redirectTo;
        } else if (response.status === 400) {
          setError(t("login.codeNotRecognized"));
        } else if (response.status === 429) {
          setError(t("login.tooManyAttempts"));
        } else {
          setError(t("apologeticDefaultError"));
        }
      } catch (e) {
        console.error(e);
        setError(t("apologeticDefaultError"));
      } finally {
        setIsSubmitting(false);
      }
    },
    [isSubmitting, securityCode, submitCodePath],
  );

  // auto-submit when the code is filled
  useEffect(() => {
    if (canSubmit) {
      buttonRef.current?.click();
    }
  }, [securityCode, canSubmit]);

  return (
    <form onSubmit={submit}>
      <div className="login__code-header">
        <Link onClick={() => setShowCode(false)}>
          <ArrowLeftIcon />
          {t("back")}
        </Link>
        <div>
          <Body
            dangerouslySetInnerHTML={{
              __html: t("login.codeSent", { email }),
            }}
          />
        </div>
      </div>
      <div className={`login-security-code ${error ? "login-security-code--error" : ""}`}>
        <CodeInput hasError={!!error} onChange={setSecurityCode} />
        {error && (
          <p
            id="login-security-code-error"
            className="helper-text helper-text--error"
            aria-live="polite"
          >
            {error}
          </p>
        )}
      </div>

      <PrimaryButton
        fitContent
        loading={isSubmitting}
        type="submit"
        disabled={!canSubmit}
        ref={buttonRef}
      >
        {t("submit")}
      </PrimaryButton>
    </form>
  );
};

export default LoginSecurityCode;
