import React from "react";
import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const DropdownArrow = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        className={`icon--${props.color}`}
        d="M11.4534 16.0667L5.90983 9.13729C5.54316 8.67895 5.86948 8 6.45644 8H17.5436C18.1305 8 18.4568 8.67895 18.0902 9.13729L12.5466 16.0667C12.2664 16.417 11.7336 16.417 11.4534 16.0667Z"
      ></path>
    </IconSvgWrapper>
  );
};

export default DropdownArrow;
