import JobAlertsWidget from "@components/dashboard/job_alerts_widget";
import "./dashboard.scss";
import ProfileCard from "@components/profile_card";
import RecentlyViewedJobsCard from "@/components/recently_viewed_jobs/card";
import useDevice from "@/hooks/use_device";
import ActiveApplications from "@/components/dashboard/active_applications";
import TasksSection from "@/components/dashboard/tasks_section";
import useRecentlyViewedJobs from "@/queries/use_recently_viewed_jobs";
import LoadingSpinner from "@/sprout/loading_spinner";
import { Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Error } from "@components/error";
import useTranslation from "@/hooks/use_translation";
import { SectionHeader, SectionTitle } from "@/sprout/typography";
import ArrowRightIcon from "@/sprout/icons/arrow_right";
import { Link } from "@inertiajs/react";
import Fingerprint15 from "../images/fingerprint_15.svg";

export type Subscription = {
  boardName: string;
  departments: string[];
  path: string;
  editPath: string;
  logoUrl: string;
};

type Props = {
  firstName: string;
  paths: {
    notificationSchedulePath: string;
    recentlyViewedJobsPath: string;
  };
  isRecentlyViewedJobsEnabled: boolean;
  recentlyViewedJobsPerPageDashboard: number;
  newUser: boolean;
  dreamJobsActive: boolean;
};

const Dashboard = (props: Props) => {
  const {
    firstName,
    paths,
    isRecentlyViewedJobsEnabled,
    recentlyViewedJobsPerPageDashboard,
    newUser,
    dreamJobsActive,
  } = props;

  const { t } = useTranslation("applications");

  const { type: deviceType } = useDevice();
  const { data: recentlyViewedJobs = [] } = useRecentlyViewedJobs();

  const dashboardTitle = () => {
    if (newUser) {
      return t("dashboard_title_new_user");
    }

    return firstName ? t("dashboard_title_with_name", { name: firstName }) : t("dashboard_title");
  };

  const dashboardSubtitle = () => {
    if (newUser) {
      return t("dashboard_subtitle_new_user");
    }

    const subtitles = t("dashboard_subtitle", { returnObjects: true }) as string[];
    return subtitles[Math.floor(Math.random() * subtitles.length)];
  };

  const renderProfileCard = () => <ProfileCard />;

  const renderTasks = () => <TasksSection />;

  const ErrorState = () => (
    <>
      {renderActiveApplicationsHeader()}
      <div className="active-applications__error">
        <Error
          header={t("errors:fetch_error_header")}
          message={[t("errors:fetch_error_message_line_1"), t("errors:fetch_error_message_line_2")]}
          fullPage={false}
        />
      </div>
    </>
  );

  const LoadingState = () => (
    <>
      {renderActiveApplicationsHeader()}
      <div className="active-applications__error">
        <LoadingSpinner size="medium" />
      </div>
    </>
  );

  const renderActiveApplicationsHeader = (count: number = 0) => (
    <Link href="/applications" className="active-applications__header">
      <SectionTitle> {t("active_applications_count", { count })}</SectionTitle>
      <div className="active-applications__header__icon">
        <ArrowRightIcon />
      </div>
    </Link>
  );

  const renderActiveApplications = () => {
    return (
      <div className="active-applications">
        <ErrorBoundary fallback={<ErrorState />}>
          <Suspense fallback={<LoadingState />}>
            <ActiveApplications
              renderHeader={renderActiveApplicationsHeader}
              dreamJobsActive={dreamJobsActive}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    );
  };

  const renderRecentlyViewedJobsCard = () =>
    isRecentlyViewedJobsEnabled && (
      <RecentlyViewedJobsCard
        jobs={recentlyViewedJobs}
        path={paths.recentlyViewedJobsPath}
        perPage={recentlyViewedJobsPerPageDashboard}
      />
    );

  const renderDashboard = () => {
    if (deviceType === "mobile") {
      return (
        <>
          {renderTasks()}
          {renderActiveApplications()}
          {renderRecentlyViewedJobsCard()}
          {<JobAlertsWidget />}
        </>
      );
    } else {
      return (
        <>
          <div className="dashboard__col1">
            {renderTasks()}
            {renderActiveApplications()}
          </div>
          <div className="dashboard__col2">
            {renderProfileCard()}
            {renderRecentlyViewedJobsCard()}
            {<JobAlertsWidget />}
          </div>
        </>
      );
    }
  };

  return (
    <>
      <div className="welcome-banner">
        <div className="welcome-container">
          <div className="welcome-text-wrapper">
            <div className="welcome-text">
              <SectionHeader serif large as="h1" className="welcome-title">
                {dashboardTitle()}
              </SectionHeader>
              <SectionHeader className="welcome-subtitle">{dashboardSubtitle()}</SectionHeader>
            </div>
          </div>
          <img src={Fingerprint15} alt="Welcome Banner Image" className="welcome-banner__image" />
        </div>
      </div>
      <div className="dashboard">
        <main>{renderDashboard()}</main>
      </div>
    </>
  );
};

export default Dashboard;
