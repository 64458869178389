import { SectionTitle } from "@sprout/typography";
import ArrowRightIcon from "@/sprout/icons/arrow_right";
import "@/components/dashboard/job_alerts_widget.scss";
import { JobAlertsRowList } from "../job_alerts/job_alerts_list";
import { useTranslation } from "react-i18next";
import { Link } from "@inertiajs/react";

const Header = ({ subscriptionCount = 0 }: { subscriptionCount?: number }) => {
  const { t } = useTranslation("jobAlerts");

  return (
    <Link href="/subscriptions" className="job-alerts-widget__header">
      <SectionTitle>
        {subscriptionCount > 0
          ? `${t("yourJobAlerts")} (${subscriptionCount})`
          : t("yourJobAlerts")}
      </SectionTitle>
      <div className="job-alerts-widget__header_icon">
        <ArrowRightIcon />
      </div>
    </Link>
  );
};

const JobAlertsWidget = () => {
  return (
    <div className="job-alerts-widget">
      <JobAlertsRowList renderHeader={(count?: number) => <Header subscriptionCount={count} />} />
    </div>
  );
};

export default JobAlertsWidget;
