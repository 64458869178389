import { ReactElement } from "react";
import { Error } from "@components/error";
import useTranslation from "@hooks/use_translation";
import Layout from "@components/layout";

const NotFoundError = () => {
  const { t } = useTranslation("misc");
  return (
    <Error
      header={t("404.header")}
      message={[t("404.body1"), t("404.body2")]}
      link={{
        href: "/dashboard",
        label: t("404.cta"),
      }}
    />
  );
};

NotFoundError.layout = (page: ReactElement<{ publicFacing: boolean }>) => (
  <Layout publicFacing={page.props.publicFacing}>{page}</Layout>
);

export default NotFoundError;
