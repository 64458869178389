import { useState } from "react";
import Button from "@sprout/button";
import { Body } from "@sprout/typography";
import { TextInput } from "@sprout/text_input";
import useCsrfToken from "@hooks/use_csrf";
import "./support_dialog.scss";
import { showFlash } from "@sprout/flash";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@components/ui/dialog.tsx";
import { Trans, useTranslation } from "react-i18next";
import { Option, Select } from "@sprout/select.tsx";
import { Stack } from "@sprout/layout.tsx";

type Props = {
  bugReportPath: string;
  isOpen: boolean;
  setIsDialogOpen: (isOpen: boolean) => void;
};

const SupportDialog = ({ bugReportPath, isOpen, setIsDialogOpen }: Props) => {
  const { t } = useTranslation("support");
  const csrfToken = useCsrfToken();

  const [bugReport, setBugReport] = useState("");
  const [bugUrl, setBugUrl] = useState("");

  const topicOptions = [
    { label: t("topics.general_feedback"), value: "general_feedback" },
    { label: t("topics.report_a_bug"), value: "report_a_bug" },
    { label: t("topics.feature_request"), value: "feature_request" },
  ];
  const [topic, setTopic] = useState<Option>(topicOptions[0]);

  const resetForm = () => {
    setBugReport("");
    setBugUrl("");
    setTopic(topicOptions[0]);
  };

  const submitBugReport = () => {
    let reportContent = `Topic: ${topic.value} `;

    if (topic.value === "report_a_bug" && bugUrl) {
      reportContent += `Bug URL: ${bugUrl} `;
    }

    reportContent += `Report: ${bugReport.trim()}`;

    fetch(bugReportPath, {
      method: "POST",
      body: JSON.stringify({
        bug_report: reportContent,
        authenticity_token: csrfToken,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then(() => {
        resetForm();
        showFlash(t("feedback_submitted"));
      })
      .catch(() => {
        showFlash(t("something_went_wrong"), "error");
      });
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsDialogOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <Trans i18nKey="support:have_feedback" />
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Stack gap="8px" alignItems="stretch">
            <Body>
              <Trans i18nKey="support:help_make" />
            </Body>
            <Select
              id="topic"
              label={t("topic")}
              options={topicOptions}
              onSelect={(item) => {
                setTopic(item as Option);
              }}
              selected={topic}
            />
            {topic.value === "report_a_bug" && (
              <TextInput
                id="bugUrl"
                type="text"
                label={t("bug_url")}
                onChange={(event) => setBugUrl(event.currentTarget.value)}
                value={bugUrl}
              />
            )}
            <TextInput
              id="bugReport"
              type="text"
              label={t(`instructions.${topic.value}`)}
              onChange={(event) => setBugReport(event.currentTarget.value)}
              value={bugReport}
              rows={4}
              multiline
              required
              help={topic.value === "report_a_bug" ? t("tell_us") : ""}
            />
          </Stack>
        </DialogDescription>
        <DialogFooter>
          <DialogClose>
            <Button onClick={() => resetForm()}>
              <Trans i18nKey="support:cancel" />
            </Button>
          </DialogClose>
          <DialogClose>
            <Button
              onClick={submitBugReport}
              disabled={!bugReport || bugReport.length === 0}
              primary
            >
              <Trans i18nKey="support:share_feedback" />
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SupportDialog;
