import React, { useCallback, useState } from "react";
import Layout from "@components/layout";
import { Trans } from "react-i18next";
import { Body, SectionHeader } from "@sprout/typography";
import { PrimaryButton } from "@sprout/button";
import useCsrfToken from "@hooks/use_csrf";
import useTranslation from "@hooks/use_translation";
import { TermsOfServiceForm } from "@components/terms_of_service/form";

type Props = {
  email: string;
  createPath: string;
};

const NewUser = ({ email, createPath }: Props) => {
  const { t } = useTranslation("misc");
  const csrfToken = useCsrfToken();

  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const submit = useCallback(async () => {
    setIsSubmitting(true);

    try {
      const response = await fetch(createPath, {
        method: "POST",
        body: JSON.stringify({
          email: email,
          accept_terms: acceptedTerms ? "1" : "0",
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          authenticity_token: csrfToken,
        }),
        headers: { "Content-Type": "application/json" },
      });

      if (!response.ok) {
        throw new Error("Not ok");
      }

      window.location.href = response.url;
    } catch (e) {
      console.error(e);
      setError(t("defaultError"));
    } finally {
      setIsSubmitting(false);
    }
  }, [isSubmitting, acceptedTerms]);

  return (
    <div className="container">
      <SectionHeader>{t("newUser.header")}</SectionHeader>
      <main>
        <Trans i18nKey={"newUser.body"}>
          <Body as={"div"}>
            {`You're ready for your next great opportunity. We'll help you find it. Once you’ve set up
            your alert, we'll send your job updates to:`}
            <Body medium>{email}</Body>
          </Body>

          <Body>{`To create your job alert, agree to the terms of service, then select "Submit."`}</Body>
        </Trans>

        <TermsOfServiceForm error={error} onChange={setAcceptedTerms} value={acceptedTerms} />
        <PrimaryButton fitContent onClick={submit} loading={isSubmitting} disabled={!acceptedTerms}>
          {t("submit")}
        </PrimaryButton>
      </main>
    </div>
  );
};

NewUser.layout = (page: React.ReactNode) => <Layout hideProfile>{page}</Layout>;

export default NewUser;
