import ApplicationCard from "./application_card";
import useTranslation from "@hooks/use_translation";
import MissingApplicationsModal from "@components/applications/missing_applications_modal";
import { Row, Stack } from "@sprout/layout";
import Callout from "@sprout/callout.tsx";
import type Application from "../../types/application";
import DreamJobIcon from "@/sprout/icons/dream_job";
import { daysUntilNextMonth } from "@/utils/date_utils";
import "@/stylesheets/dream_job_icon.scss";

type Props = {
  applications: Application[];
  onApplicationSelect: (id: number) => void;
  dreamJobsActive: boolean;
  isLoading?: boolean;
  selectedApplicationId?: number | null | undefined;
  search?: string;
  setSearch?: (search: string) => void;
  showCallout?: boolean;
  narrow?: boolean;
  addLinks?: boolean;
  hasTokens?: boolean;
};

const ApplicationCardList = function (props: Props) {
  const {
    applications,
    dreamJobsActive,
    selectedApplicationId,
    onApplicationSelect,
    showCallout = true,
    narrow = false,
    addLinks = false,
    hasTokens,
  } = props;
  const { t } = useTranslation("applications");

  return (
    <Stack gap="8px">
      {hasTokens === false && (
        <div className="w-full bg-marigold-25 px-6 py-4 border-marigold-e50 rounded-sm">
          <Row>
            <div className="bg-marigold-e50 self-center dream-job-icon">
              <DreamJobIcon size="md" />
            </div>
            <p className="mx-2 self-center text-xs">
              {t("refreshes_in", { count: daysUntilNextMonth() })}
            </p>
          </Row>
        </div>
      )}
      {applications &&
        applications.map((application) => (
          <ApplicationCard
            key={application.id}
            application={application}
            selected={application.id == selectedApplicationId}
            onApplicationSelect={onApplicationSelect}
            dreamJobsActive={dreamJobsActive}
            narrow={narrow}
            addLink={addLinks}
          />
        ))}
      {showCallout && (
        <div className="application-card application-card__no-formatting">
          <Callout
            headerText={t("not_found_modal.title")}
            link={<MissingApplicationsModal />}
            background={"var(--green-gray)"}
          />
        </div>
      )}
    </Stack>
  );
};

export default ApplicationCardList;
