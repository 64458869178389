import { useEffect, useRef, useState } from "react";
import useTranslation from "@hooks/use_translation";
import { SectionHeader } from "@sprout/typography";
import RecentlyViewedJobsTable, {
  type RecentJobView,
} from "@/components/recently_viewed_jobs/table";
import { useDebounce } from "@/hooks/use_debounce";
import { router } from "@inertiajs/react";
import { queryClient } from "@/queries/query_client.ts";
import BackButton from "@components/back_button.tsx";
import Container from "@components/ui/container.tsx";

type Props = {
  jobs: RecentJobView[];
  currentPage: number;
  total: number;
  totalPages: number;
  perPage: number;
};

const RecentlyViewedJobs = (props: Props) => {
  const { jobs, currentPage, total, totalPages, perPage } = props;
  const { t } = useTranslation("recentlyViewedJobs");

  const urlParams = new URLSearchParams(window.location.search);
  const [keyword, setKeyword] = useState(urlParams.get("keyword") || "");
  const debouncedKeyword = useDebounce(keyword, 250);
  const previousKeyword = useRef(keyword);

  const filteredJobs = (keyword: string) => {
    router.reload({ data: { keyword } });
  };

  useEffect(() => {
    if (debouncedKeyword === previousKeyword.current) {
      return;
    }

    previousKeyword.current = debouncedKeyword;

    filteredJobs(debouncedKeyword);
  }, [keyword, debouncedKeyword]);

  useEffect(() => {
    // This ensures that when the user goes back to the dashboard, the recently viewed widget will be up-to-date
    void queryClient.invalidateQueries({ queryKey: ["recentlyViewedJobs", "dashboard"] });
  });

  return (
    <Container>
      <BackButton />
      <SectionHeader>{t("header")}</SectionHeader>
      <RecentlyViewedJobsTable
        jobs={jobs}
        currentPage={currentPage}
        total={total}
        totalPages={totalPages}
        hideHeader={false}
        perPage={perPage}
        keyword={keyword}
        setKeyword={setKeyword}
      />
    </Container>
  );
};

export default RecentlyViewedJobs;
