import { useMemo, useState } from "react";
import {
  Dialog,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@components/ui/dialog.tsx";
import Button from "@sprout/button.tsx";
import { Body } from "@sprout/typography.tsx";
import { Stack } from "@sprout/layout.tsx";
import { useTranslation } from "react-i18next";
import DreamJobIcon from "@/sprout/icons/dream_job";
import SearchIcon from "@/sprout/icons/search";
import { TextInput } from "@/sprout/text_input";
import Application from "@/types/application";
import useApplications from "@/hooks/use_applications";
import ApplicationCardList from "../applications/application_card_list";
import { useDebounce } from "react-use";
import { IconButton } from "@/sprout/icon_button";
import CloseIcon from "@/sprout/icons/close";
import { markAsDreamJob } from "@/api/dream_job";
import "@/stylesheets/dream_job_icon.scss";
import DreamJobInfoModal from "../applications/dream_job_info_modal";

const DashboardDreamJobModal = () => {
  const { t } = useTranslation("dashboard", { keyPrefix: "dream_job_modal" });

  const [search, setSearch] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [selectedApplication, setSelectedApplication] = useState<Application | undefined>(
    undefined,
  );

  const updateDebouncedSearch = (val: string) => {
    setDebouncedSearch(val);
    setSelectedApplication(undefined);
  };

  const { data, invalidateQuery } = useApplications({ active_only: true });

  const filterApplications = (applicationsToFilter: Application[], searchTerm: string) => {
    return applicationsToFilter.filter((application) => {
      return (
        application.job_title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        application.company_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const applications: Application[] = useMemo(
    () => filterApplications(data.active.applications, search),
    [search, data.active.applications],
  );

  useDebounce(
    () => {
      setSearch(debouncedSearch);
    },
    200,
    [debouncedSearch],
  );

  const handleDialogClose = (isOpen: boolean) => {
    if (!isOpen) {
      setSearch("");
      updateDebouncedSearch("");
    }
  };

  const onApplicationSelect = (id: number) => {
    setSelectedApplication(applications.find((application) => application.id === id));
  };

  const makeDreamJob = async () => {
    if (!selectedApplication) {
      return;
    }

    markAsDreamJob({
      dreamJobPath: selectedApplication.dreamJobPath,
      successMessage: t("success"),
      invalidateQuery,
    });
  };

  return (
    <Dialog onOpenChange={handleDialogClose}>
      <DialogTrigger>
        <Button size="medium">{t("trigger")}</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[600px] bg-white max-h-8/10 min-h-8/10 overflow-auto items-start">
        <DialogHeader>
          <DialogTitle>
            <div className="text-xl">{t("title")}</div>
          </DialogTitle>
          <DialogClose asChild>
            <IconButton icon={CloseIcon} size="md" label={t("cancel")} />
          </DialogClose>
        </DialogHeader>
        <DialogDescription>
          <Stack gap="20px" className="overflow-auto">
            <Body>
              {t("description")}
              <DreamJobInfoModal linkTextSize="large" underline={false} />
            </Body>
            <div className="flex p-4 w-full border rounded-lg bg-marigold-25 border-marigold-e50 text-dark-green">
              <div className="bg-marigold-e50 self-center dream-job-icon">
                <DreamJobIcon size="md" />
              </div>
              <p className="mx-2 self-center text-base">{t("remaining_tokens", { count: 1 })}</p>
            </div>
            <TextInput
              className="max-w-98/100 self-center"
              id="dream-job-search"
              placeholder={t("placeholder")}
              onChange={(event) => updateDebouncedSearch(event.currentTarget.value || "")}
              maxLength={255}
              value={debouncedSearch}
              narrow
              icon={<SearchIcon />}
              onClear={() => updateDebouncedSearch("")}
            />
            <ApplicationCardList
              selectedApplicationId={selectedApplication?.id}
              applications={applications}
              dreamJobsActive={true}
              onApplicationSelect={onApplicationSelect}
              showCallout={false}
              narrow
              addLinks
            />
          </Stack>
        </DialogDescription>
        <DialogFooter className="sm:justify-start self-end">
          <DialogClose>
            <Button className="max-h-fit">
              <div>{t("cancel")}</div>
            </Button>
          </DialogClose>
          <DialogClose>
            <Button
              className="max-h-fit"
              onClick={makeDreamJob}
              primary
              disabled={!selectedApplication}
            >
              <div>{t("save")}</div>
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DashboardDreamJobModal;
