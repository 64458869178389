import { Option } from "@sprout/select";

const fetchItems = async (path: string) => {
  const response = await fetch(path, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  if (response.status !== 200) {
    return { items: [] };
  }

  const { items } = await response.json();

  return { items };
};

export const useAsyncOptions = async (path: string, search: string, options: Option[]) => {
  if (search) {
    path += `&term=${search}`;
  }

  const newItems = (await fetchItems(path)).items as Option[];
  const records: Record<number, string> = {};

  options.forEach((option) => {
    records[option.value as number] = option.label;
  });

  newItems.forEach((record) => {
    records[record.value as number] = record.label;
  });

  const newOptions = Object.entries(records).map(([value, label]) => ({
    value: parseInt(value),
    label,
  }));

  newOptions.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }

    return 1;
  });

  return { newOptions };
};
