import React from "react";
import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const PreviousIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.806367 6.54175C0.337737 6.07312 0.337738 5.31332 0.806366 4.84469L5.32352 0.327526C5.63594 0.0151072 6.14247 0.0151062 6.45489 0.327525C6.76731 0.639944 6.76731 1.14648 6.45489 1.4589L2.22058 5.69322L6.45489 9.92753C6.76731 10.2399 6.76731 10.7465 6.45489 11.0589C6.14248 11.3713 5.63594 11.3713 5.32352 11.0589L0.806367 6.54175Z"
        fill="#222222"
      />
    </IconSvgWrapper>
  );
};

export default PreviousIcon;
