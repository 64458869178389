import React from "react";
import PdfFileIcon from "@sprout/icons/pdf_file";
import DefaultIcon from "@sprout/icons/file";
import DocxFileIcon from "@sprout/icons/docx_file";
import DocFileIcon from "@sprout/icons/doc_file";
import RtfFileIcon from "@sprout/icons/rtf_file";
import TxtFileIcon from "@sprout/icons/txt_file";

interface FileIconProps {
  contentType: string;
}

const FileIcon = ({ contentType }: FileIconProps) => {
  switch (contentType) {
    case "application/pdf":
      return <PdfFileIcon />;
    case "application/msword":
      return <DocFileIcon />;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <DocxFileIcon />;
    case "application/rtf":
    case "text/rtf":
      return <RtfFileIcon />;
    case "text/plain":
      return <TxtFileIcon />;
    default:
      return <DefaultIcon />;
  }
};

export default FileIcon;
