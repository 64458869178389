import React from "react";
import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const ArrowRightIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        className={`icon--${props.color}`}
        fill={props.color}
        d="M9.72721 20.8656C9.5849 20.8655 9.44582 20.8232 9.32753 20.744C9.20924 20.6649 9.11706 20.5525 9.06261 20.421C9.00817 20.2895 8.99391 20.1449 9.02164 20.0053C9.04937 19.8657 9.11784 19.7375 9.21841 19.6368L16.4304 12.4248C16.4863 12.369 16.5306 12.3028 16.5609 12.2299C16.5911 12.157 16.6067 12.0789 16.6067 12C16.6067 11.9211 16.5911 11.8429 16.5609 11.77C16.5306 11.6971 16.4863 11.6309 16.4304 11.5752L9.21841 4.36318C9.09123 4.2267 9.02199 4.04617 9.02528 3.85964C9.02857 3.67311 9.10414 3.49514 9.23605 3.36323C9.36797 3.23131 9.54594 3.15575 9.73247 3.15246C9.919 3.14916 10.0995 3.2184 10.236 3.34558L18.3792 11.4876C18.433 11.5401 18.4779 11.6008 18.5124 11.6676C18.5826 11.8034 18.6077 11.9579 18.5842 12.1089C18.5607 12.26 18.4897 12.3996 18.3816 12.5076L10.236 20.6544C10.1011 20.7894 9.9181 20.8654 9.72721 20.8656Z"
      />
    </IconSvgWrapper>
  );
};

export default ArrowRightIcon;
