import Preferences from "@/components/preferences";
import { Body, SectionHeader } from "@/sprout/typography";
import useTranslation from "@hooks/use_translation";

import "@/pages/job_alerts.scss";
import { JobAlertsCardList } from "@/components/job_alerts/job_alerts_list";
import BackButton from "@components/back_button.tsx";
import Container from "@components/ui/container.tsx";

export type EmailSchedule = {
  id: number;
  cadence: "Weekly" | "Daily";
  isDaily: boolean;
};

type Props = {
  paths: {
    notificationSchedulePath: string;
  };
  emailSchedule: EmailSchedule;
  emailSchedules: { [key: string]: number };
};

const JobAlertsPage = ({ paths, emailSchedule, emailSchedules }: Props) => {
  const { t } = useTranslation("jobAlerts");

  const renderHeader = (count: number = 0) => (
    <div className="job-alerts-page__header">
      <SectionHeader as="h2">
        {count > 0 ? `${t("yourJobAlerts")} (${count})` : t("yourJobAlerts")}
      </SectionHeader>
      <div className="job-alerts-page__header__preferences">
        <Body>{t("preferences.emailFrequency")}</Body>
        <Preferences
          paths={paths}
          shortCadence={true}
          emailSchedule={emailSchedule}
          emailSchedules={emailSchedules}
        />
      </div>
    </div>
  );

  return (
    <Container>
      <BackButton />
      <JobAlertsCardList renderHeader={renderHeader} />
    </Container>
  );
};

export default JobAlertsPage;
