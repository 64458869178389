import { SectionTitle } from "@/sprout/typography";
import useTasks from "@/hooks/use_tasks";
import SetupQuickApplyProfile from "./tasks/SetupQuickApplyProfile";
import SetupWorkPreference from "./tasks/SetupWorkPreference";
import AvailabilityTask from "./tasks/availability_task";
import SelfScheduleRequest from "./tasks/SelfScheduleRequest";
import ChooseDreamJob from "./tasks/ChooseDreamJob";
import LoadingSpinner from "@/sprout/loading_spinner";
import type { AnyTask, Task } from "@/types/task";
import { CompletionStatus } from "@utils/completion_status.ts";
import "./tasks_section.scss";
import { IconButton } from "@/sprout/icon_button";
import BackIcon from "@/sprout/icons/back";
import ForwardIcon from "@/sprout/icons/forward";
import { useRef, useState, useEffect, ComponentType } from "react";
import useTranslation from "@hooks/use_translation";

const TASK_COMPONENTS: Record<string, ComponentType<{ task: AnyTask; statusText: string }>> = {
  SetupQuickApplyProfile,
  SetupWorkPreference,
  AvailabilityTask,
  SelfScheduleRequest,
  ChooseDreamJob,
};

const ITEM_WIDTH = 300;

const TasksSection = () => {
  const { data, loading } = useTasks();
  const scrollerRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [spacerWidth, setSpacerWidth] = useState(0);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const tasks = data?.tasks || [];

  const { t } = useTranslation("user");

  useEffect(() => {
    const updateSpacerWidth = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setSpacerWidth(containerWidth - ITEM_WIDTH);
      }
    };

    const updateScrollState = () => {
      if (scrollerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollerRef.current;
        const maxScrollLeft = scrollWidth - clientWidth;

        setIsAtStart(scrollLeft === 0);
        setIsAtEnd(scrollLeft >= maxScrollLeft - ITEM_WIDTH);
      }
    };

    const handleResize = () => {
      requestAnimationFrame(() => {
        updateSpacerWidth();
        updateScrollState();
      });
    };

    handleResize();

    const resizeObserver = new ResizeObserver(handleResize);

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    if (scrollerRef.current) {
      scrollerRef.current.addEventListener("scroll", updateScrollState);
    }

    return () => {
      resizeObserver.disconnect();
      if (scrollerRef.current) {
        scrollerRef.current.removeEventListener("scroll", updateScrollState);
      }
    };
  }, [tasks]);

  if (loading) {
    return <LoadingSpinner size="medium" />;
  }

  const next = () => {
    if (!scrollerRef.current) {
      return;
    }

    const maxScrollLeft = scrollerRef.current.scrollWidth - scrollerRef.current.clientWidth;

    scrollerRef.current.scrollBy({
      left: Math.min(ITEM_WIDTH, maxScrollLeft - scrollerRef.current.scrollLeft),
      top: 0,
      behavior: "smooth",
    });
  };

  const taskStatusText = (task: AnyTask) => {
    if (task.status === CompletionStatus.Complete) {
      return t("progress.complete");
    } else if (task.status === CompletionStatus.AlmostFinished) {
      return t("progress.almostFinished");
    } else if (task.status === CompletionStatus.InProgress) {
      return t("progress.inProgress");
    } else {
      if (task.created_at) {
        const createdAt = new Date(task.created_at).getTime();
        const now = new Date().getTime();
        const timeAgo = now - createdAt;
        const MINUTE = 60000;
        const HOUR = 3600000;
        const DAY = 24 * HOUR;

        if (timeAgo < HOUR) {
          return t("progress.minutes_ago", {
            count: Math.max(1, Math.floor(timeAgo / MINUTE)),
          });
        } else if (timeAgo < DAY) {
          return t("progress.hours_ago", {
            count: Math.floor(timeAgo / HOUR),
          });
        } else {
          return t("progress.days_ago", {
            count: Math.floor(timeAgo / DAY),
          });
        }
      }
      return t("progress.notStarted");
    }
  };

  const prev = () => {
    if (!scrollerRef.current) {
      return;
    }

    scrollerRef.current.scrollBy({
      left: -Math.min(ITEM_WIDTH, scrollerRef.current.scrollLeft),
      top: 0,
      behavior: "smooth",
    });
  };

  if (tasks.length === 0) {
    return null;
  }

  return (
    <div className="tasks-section p-4" ref={containerRef}>
      <div className="mb-4">
        <SectionTitle>{`Tasks (${tasks.length})`}</SectionTitle>
      </div>

      <div className="tasks-section__list flex gap-4" ref={scrollerRef}>
        {tasks.map((task: Task) => {
          const translatedStatus = taskStatusText(task);

          const TaskComponent = TASK_COMPONENTS[task.taskable_type];

          return (
            <div className="tasks-section__item" key={task.id}>
              <TaskComponent task={task} statusText={translatedStatus} />
            </div>
          );
        })}
        {/* Spacer element for extra scrollable space */}
        <div style={{ minWidth: spacerWidth }} />
      </div>
      {tasks.length > 1 && (
        <div className="tasks-section__scroll-buttons flex justify-end">
          <IconButton
            label="Scroll left"
            icon={() => <BackIcon color="#15372C" />}
            size="md"
            onClick={prev}
            disabled={isAtStart}
          />
          <IconButton
            label="Scroll right"
            icon={() => <ForwardIcon color="#15372C" />}
            size="md"
            onClick={next}
            disabled={isAtEnd}
          />
        </div>
      )}
    </div>
  );
};

export default TasksSection;
