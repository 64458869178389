import { ErrorBoundary } from "react-error-boundary";
import { Suspense, useState } from "react";
import { router } from "@inertiajs/react";
import "./applications.scss";
import useDevice from "@/hooks/use_device";
import ApplicationsList from "@/components/applications/applications_list";
import SelectedApplication from "@/components/applications/selected_application";
import { Row } from "@sprout/layout";
import Application from "@/types/application";
import { Error } from "@components/error";
import useApplications from "@/hooks/use_applications";
import LoadingSpinner from "@/sprout/loading_spinner";
import EmptyState from "@components/empty_state.tsx";
import Fingerprint1 from "@images/fingerprint_1.svg";
import useTranslation from "@hooks/use_translation.ts";
import { IconButton } from "@sprout/icon_button.tsx";
import BackIcon from "@sprout/icons/back.tsx";

type DeferredPageProps = {
  applications?: Application[];
};

type Props = DeferredPageProps & {
  selected_application_id?: number;
  tokens_remaining: number;
  dream_jobs_active: boolean;
  dream_job_tokens: number;
};

const routeToApplications = (applicationId?: number | null) => {
  const route = applicationId ? `/applications/${applicationId}` : "/applications";

  router.visit(route, { only: ["selected_application_id"] });
};

const Applications = (props: Props) => {
  const {
    selected_application_id: selectedApplicationId,
    dream_jobs_active: dreamJobsActive,
    dream_job_tokens,
  } = props;
  const [selectedApplication, setSelectedApplication] = useState<Application | undefined>(
    undefined,
  );

  const { isLoading, data, error } = useApplications();
  const device = useDevice();
  const { t } = useTranslation("applications");

  const active = data?.active || { applications: [] };
  const inactive = data?.inactive || { applications: [] };

  if (data && !error && selectedApplicationId && !selectedApplication) {
    const application = data.active.applications.find(
      (application) => application.id === selectedApplicationId,
    );

    if (application) {
      setSelectedApplication(application);
    }
  }

  const showApplicationsList = device.type == "web" || !selectedApplication;
  const showSelectedApplication = device.type == "web" || selectedApplication;

  return (
    <>
      {showApplicationsList && (
        <ApplicationsList
          activeApplications={active.applications}
          inactiveApplications={inactive.applications}
          isLoading={isLoading}
          selectedApplication={selectedApplication}
          onApplicationSelect={routeToApplications}
          dreamJobsActive={dreamJobsActive}
          device={device}
          hasTokens={dream_job_tokens > 0}
        />
      )}
      {showSelectedApplication && (
        <div className="selected-application-pane">
          {selectedApplication && (
            <SelectedApplication
              application={selectedApplication}
              dreamJobsActive={dreamJobsActive}
              hasTokens={dream_job_tokens > 0}
            />
          )}
          {!isLoading && !selectedApplication && (
            <EmptyState svg={Fingerprint1} title={t("no_active")} message={t("know_whats_next")} />
          )}
        </div>
      )}
    </>
  );
};

const centeredState = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

const ErrorState = () => {
  return (
    <div style={centeredState}>
      <Error header={"Failed to fetch applications"} />
    </div>
  );
};

const LoadingState = () => {
  return (
    <div style={centeredState}>
      <LoadingSpinner size="medium" />
    </div>
  );
};

const ApplicationsPage = (props: Props) => {
  const device = useDevice();

  const goBack = () => routeToApplications();

  return (
    <main className="applications-page">
      {!!props.selected_application_id && device.type == "mobile" && (
        <IconButton
          className="back-button"
          icon={BackIcon}
          size="md"
          label="Back"
          onClick={goBack}
        />
      )}
      <Row gap="24px">
        <ErrorBoundary fallback={<ErrorState />}>
          <Suspense fallback={<LoadingState />}>
            <Applications {...props} />
          </Suspense>
        </ErrorBoundary>
      </Row>
    </main>
  );
};

export default ApplicationsPage;
