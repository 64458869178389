import { useState, useCallback, ReactNode, useRef } from "react";
import { createPortal } from "react-dom";
import classNames from "classnames";
import CheckIcon from "@sprout/icons/check";
import ErrorIcon from "@sprout/icons/error";
import { useEffectAfterMount } from "@/hooks/use_effect_after_mount";

type FlashMessage = {
  id: number;
  message: string;
  type: "success" | "error";
  fadingOut?: boolean;
};

export type RailsFlash = {
  error?: string;
  success?: string;
};

const FLASH_DURATION = 4000;
const FLASH_FADE_DURATION = 300;

let showFlash: (message: string, type?: "success" | "error") => void = () => {
  console.warn("showFlash called before FlashProvider was mounted.");
};

const useFlash = (flash: RailsFlash) => {
  const flashed = useRef(false);

  useEffectAfterMount(() => {
    flash?.error && !flashed.current && showFlash(flash.error, "error");
    flash?.success && !flashed.current && showFlash(flash.success, "success");

    flashed.current = !!flash?.error || !!flash?.success;
  });
};

export const FlashProvider = ({ children }: { children: ReactNode }) => {
  const [messages, setMessages] = useState<FlashMessage[]>([]);

  // Assign to the global function
  showFlash = useCallback((message: string, type: "success" | "error" = "success") => {
    const id = Date.now();
    setMessages((prev) => [...prev, { id, message, type }]);

    setTimeout(() => {
      setMessages((prev) => {
        return prev.map((msg) => {
          return msg.id === id ? { ...msg, fadingOut: true } : msg;
        });
      });
    }, FLASH_DURATION - FLASH_FADE_DURATION);

    setTimeout(() => {
      setMessages((prev) => prev.filter((msg) => msg.id !== id));
    }, FLASH_DURATION);
  }, []);

  const mountElement = window.document.getElementById("app");
  if (!mountElement) {
    return null;
  }

  return (
    <>
      {children}
      {createPortal(
        <div className="flash-wrapper">
          {messages.map(({ id, message, type, fadingOut }) => (
            <div
              key={id}
              className={classNames("flash-message", {
                "flash-message--success": type === "success",
                "flash-message--error": type === "error",
                "fade-out": fadingOut,
              })}
            >
              <span>
                {type === "error" ? <ErrorIcon color="red" /> : <CheckIcon color="green" />}
              </span>
              <div className="flash-body">{message}</div>
            </div>
          ))}
        </div>,
        mountElement,
      )}
    </>
  );
};

export { showFlash, useFlash };
