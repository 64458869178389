import type { Employment, MonthYear } from "@components/quick_apply/employments";

export interface EmploymentError {
  company?: string;
  title?: string;
  startDateMonth?: string;
  startDateYear?: string;
  endDateMonth?: string;
  endDateYear?: string;
  endDateYearInvalid?: string;
}

export interface EmploymentErrors {
  [index: number]: EmploymentError;
}

function isEmptyMonthYear(monthYear?: MonthYear | null): boolean {
  if (!monthYear) {
    return true;
  }

  return !monthYear.month && !monthYear.year;
}

function isEmptyEmployment(employment: Partial<Employment>): boolean {
  return (
    !employment.company &&
    !employment.title &&
    isEmptyMonthYear(employment.startDate) &&
    isEmptyMonthYear(employment.endDate)
  );
}

export function endDateAfterStartDate(
  startMonth?: number | null,
  startYear?: number | null,
  endMonth?: number | null,
  endYear?: number | null,
): boolean {
  if (startYear != null && endYear != null) {
    if (startYear > endYear) {
      return false;
    } else if (startYear === endYear) {
      if (startMonth == null || endMonth == null) {
        return true;
      } else if (startMonth >= endMonth) {
        return false;
      }
    }
  }

  return true;
}

export function validateEmployments(employments: Partial<Employment>[]): EmploymentErrors {
  const errors: EmploymentErrors = {};

  employments.forEach((employment, index) => {
    const error = {} as EmploymentError;

    if (isEmptyEmployment(employment)) {
      return;
    }

    if (!employment.company?.trim()) {
      error.company = "employment.companyRequired";
    }

    if (!employment.title?.trim()) {
      error.title = "employment.titleRequired";
    }

    if (!employment.startDate?.month) {
      error.startDateMonth = "dates.startDateMonthRequired";
    }

    if (!employment.startDate?.year) {
      error.startDateYear = "dates.startDateYearRequired";
    }

    // We only need to validate end date if either field is provided.
    // Otherwise we can consider this a current employment.
    if (!isEmptyMonthYear(employment.endDate)) {
      if (!employment.endDate?.month) {
        error.endDateMonth = "dates.endDateMonthRequired";
      } else if (!employment.endDate?.year) {
        error.endDateYear = "dates.endDateYearRequired";
      }
    }

    if (
      !endDateAfterStartDate(
        employment.startDate?.month,
        employment.startDate?.year,
        employment.endDate?.month,
        employment.endDate?.year,
      )
    ) {
      error.endDateYearInvalid = "dates.dateRangeInvalid";
    }

    if (Object.keys(error).length > 0) {
      errors[index] = error;
    }
  });

  return errors;
}
