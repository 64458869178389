import React from "react";
import useTranslation from "@hooks/use_translation";
import TaskCard from "./TaskCard";
import type { Task } from "@/types/task";
import DreamJobIcon from "@/sprout/icons/dream_job";
import DashboardDreamJobModal from "../dashboard_dream_job_modal";

interface TaskCardProps {
  task: Task;
  statusText: string;
}

const ChooseDreamJob: React.FC<TaskCardProps> = ({ task, statusText }) => {
  const { t } = useTranslation("tasks");

  return (
    <TaskCard
      theme={{
        colors: {
          background: "bg-marigold-25",
          border: "border-marigold-e50",
          iconBackground: "bg-marigold-e50",
        },
      }}
      icon={<DreamJobIcon size="sm" />}
      statusText={statusText}
      task={task}
      title={t("chooseDreamJob.title")}
      description={t("chooseDreamJob.description")}
      actionText={t("chooseDreamJob.choose")}
      Modal={DashboardDreamJobModal}
    />
  );
};

export default ChooseDreamJob;
