import { Body } from "@sprout/typography.tsx";
import Button from "@sprout/button.tsx";
import { Stack } from "@sprout/layout.tsx";
import type Application from "../../types/application";
import {
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@components/ui/dialog.tsx";
import { Trans } from "react-i18next";
import useTranslation from "@hooks/use_translation.ts";
import { useQueryClient } from "@tanstack/react-query";
import { markAsDreamJob } from "@/api/dream_job";

type Props = {
  application: Application;
  afterSuccess: () => void;
};

const DreamJobModal = ({ application, afterSuccess }: Props) => {
  const { t } = useTranslation("applications");
  const queryClient = useQueryClient();

  const onAddDreamJob = () => {
    markAsDreamJob({
      dreamJobPath: application.dreamJobPath,
      successMessage: t("dream_job.success"),
      invalidateQuery: () => queryClient.invalidateQueries({ queryKey: ["applications"] }),
      afterSuccess,
    });
  };

  return (
    <DialogContent className="sm:max-w-md">
      <DialogHeader>
        <DialogTitle>
          <Trans i18nKey="applications:dream_job:modal:title" />
        </DialogTitle>
      </DialogHeader>
      <DialogDescription>
        <div className="support-modal__body">
          <Stack gap="8px">
            <Stack gap="20px">
              <Body>
                <Trans i18nKey="applications:dream_job:modal:description" />
              </Body>
              <Body>
                <Trans i18nKey="applications:dream_job:modal:refresh_details" />
              </Body>
            </Stack>
          </Stack>
        </div>
      </DialogDescription>
      <DialogFooter className="sm:justify-start">
        <DialogClose>
          <Button>
            <Trans i18nKey="applications:dream_job:modal:cancel" />
          </Button>
        </DialogClose>
        <DialogClose>
          <Button onClick={onAddDreamJob} primary>
            <Trans i18nKey="applications:dream_job:modal:confirm" />
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  );
};

export default DreamJobModal;
