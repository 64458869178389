import "@/components/empty_state.scss";
import { Stack } from "@sprout/layout";

type Props = {
  svg: string;
  svgSize?: string;
  title?: string;
  fontWeight?: string;
  message: string;
  padding?: string;
  link?: JSX.Element;
};

const EmptyState = ({
  svg,
  svgSize = "300",
  title,
  fontWeight = "500",
  message,
  padding = "16px 24px",
  link,
}: Props) => {
  return (
    <div className="empty-state-component" style={{ padding }}>
      <Stack gap="24px" alignItems="center">
        <img src={svg} alt="Greenhouse fingerprint leaf" width={svgSize} />
        <div className="empty-state-component__text">
          {title && (
            <p className="empty-state-component__title" style={{ fontWeight }}>
              {title}
            </p>
          )}
          <p className="empty-state-component__message">
            {message}
            {link && link}
          </p>
        </div>
      </Stack>
    </div>
  );
};

export default EmptyState;
