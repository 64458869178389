import RecentlyViewedJobsTable, { type RecentJobView } from "./table";
import "./card.scss";
import { SectionTitle } from "@/sprout/typography";
import ArrowRightIcon from "@/sprout/icons/arrow_right";
import { Link } from "@inertiajs/react";
import useTranslation from "@hooks/use_translation";

type Props = {
  jobs: RecentJobView[];
  path: string;
  perPage: number;
};

const RecentlyViewedJobsCard = (props: Props) => {
  const { t } = useTranslation("recentlyViewedJobs");

  return (
    <div className="recently-viewed-jobs_card">
      <Link href={props.path} className="recently-viewed-jobs_card__header">
        <SectionTitle>{t("header")}</SectionTitle>
        <div className="recently-viewed-jobs_card__header__icon">
          <ArrowRightIcon />
        </div>
      </Link>
      <RecentlyViewedJobsTable
        jobs={props.jobs}
        hideHeader={true}
        perPage={props.perPage}
        forceMobileView={true}
        isWidgetMode
      />
    </div>
  );
};

export default RecentlyViewedJobsCard;
