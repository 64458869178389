import Application from "@/types/application";
import { useSuspenseQuery, useQueryClient, InvalidateQueryFilters } from "@tanstack/react-query";

type ParamProps = {
  page?: number;
  perPage?: number;
  active_only?: boolean;
  omit_dream_jobs?: boolean;
  search_terms?: string;
};

type ApplicationSection = {
  applications: Application[];
  count: number;
  total_pages?: number;
};

type ApplicationsResponse = {
  active: ApplicationSection;
  inactive?: ApplicationSection;
  total_applications: number;
};

const PAGE_URL = "/applications.json";

const buildURL = ({ page, perPage, active_only }: ParamProps) => {
  const searchParams: Record<string, string> = {};

  if (page) {
    searchParams["page"] = page.toString();
  }

  if (perPage) {
    searchParams["perPage"] = perPage.toString();
  }

  if (active_only) {
    searchParams["active_only"] = "true";
  }

  const queryString = new URLSearchParams(searchParams).toString();
  return queryString ? `${PAGE_URL}?${queryString}` : PAGE_URL;
};

const buildQueryKey = (params: ParamProps) => {
  const key: (string | number | boolean)[] = ["applications"];

  if (params.page) {
    key.push(params.page);
  }

  if (params.perPage) {
    key.push(params.perPage);
  }

  if (params.active_only) {
    key.push("active_only");
  }

  return key;
};

const useApplications = (params: ParamProps = {}) => {
  const queryClient = useQueryClient();

  const queryKey = buildQueryKey(params);
  const fetchApplications = (): Promise<ApplicationsResponse> =>
    fetch(buildURL(params)).then((res) => res.json());

  const query = useSuspenseQuery({
    queryKey,
    queryFn: fetchApplications,
    staleTime: 3 * 60 * 1000,
  });

  const invalidateQuery = () => {
    queryClient.invalidateQueries(queryKey as InvalidateQueryFilters<unknown>);
  };

  return { ...query, invalidateQuery };
};

export default useApplications;
