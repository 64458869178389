import classNames from "classnames";

interface LayoutProps {
  children: React.ReactNode;
  gap?: "0" | "4px" | "8px" | "12px" | "16px" | "20px" | "24px";
  alignItems?: "flex-start" | "center" | "flex-end" | "stretch";
  className?: string;
}

const Stack = ({
  children,
  gap = "4px",
  alignItems = "flex-start",
  className: customClass = "",
}: LayoutProps) => {
  const className = classNames({
    stack: true,
    [`stack--gap-${gap}`]: true,
    [`stack--align-${alignItems}`]: alignItems,
    [customClass]: customClass,
  });

  return <div className={className}>{children}</div>;
};

interface RowProps {
  align?: "flex-start" | "center";
}

const Row = ({
  children,
  gap = "4px",
  align = "flex-start",
  className: customClass = "",
  ...rest
}: LayoutProps & RowProps) => {
  const className = classNames({
    row: true,
    [`row--gap-${gap}`]: true,
    [`row--align-${align}`]: align,
    [customClass]: customClass,
  });

  return (
    <div {...rest} className={className}>
      {children}
    </div>
  );
};

export { Stack, Row };
