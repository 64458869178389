import React, { useEffect, useRef } from "react";

export const useEffectAfterMount = (effect: React.EffectCallback, deps?: React.DependencyList) => {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }

    return effect();
  }, deps);
};
