import { Tag } from "@sprout/tag";
import { SectionHeader, SecondaryBody, Body } from "@sprout/typography";
import PreviewIcon from "@sprout/icons/preview";
import EditIcon from "@sprout/icons/edit";
import { IconButtonLink } from "@sprout/icon_button";

import type { Subscription } from "@/pages/dashboard";

import theme from "@/stylesheets/theme.module.scss";
import "./subscriptions_legacy.scss";
import CompanyLogo from "@components/company_logo";
import useTranslation from "@hooks/use_translation";
import useSubscriptions from "@/queries/use_subscriptions";

const DepartmentTags = ({ departments }: { departments: string[] }) => {
  const { t } = useTranslation("jobAlerts");
  const MAX_DEPARTMENTS = 5;
  const exceedsMax = departments.length > MAX_DEPARTMENTS;
  let departmentsList = departments;

  if (exceedsMax) {
    departmentsList = departments.slice(0, MAX_DEPARTMENTS);
  }

  return (
    <div className="subscriptions-legacy__departments">
      {departmentsList.map((department) => (
        <Tag key={department} label={department} />
      ))}
      {exceedsMax && (
        <SecondaryBody>
          {t("subscription.additionalDepartments", { count: departments.length - MAX_DEPARTMENTS })}
        </SecondaryBody>
      )}
    </div>
  );
};

const SubscriptionCard = ({ subscription }: { subscription: Subscription }) => {
  return (
    <li className="subscriptions-legacy__card">
      <div className="subscriptions-legacy__header">
        <CompanyLogo logoUrl={subscription.logoUrl} companyName={subscription.boardName} />
        <div>
          <IconButtonLink
            external
            href={subscription.path}
            icon={PreviewIcon}
            color={theme.darkGreen}
            data-provides="preview-board"
          />

          <IconButtonLink
            icon={EditIcon}
            color={theme.darkGreen}
            href={subscription.editPath}
            data-provides="edit-subscription"
          />
        </div>
      </div>
      <SectionHeader as="h3">{subscription.boardName}</SectionHeader>
      <DepartmentTags departments={subscription.departments} />
    </li>
  );
};

const Subscriptions = () => {
  const { data: subscriptions } = useSubscriptions();
  const { t } = useTranslation("jobAlerts");

  return (
    <ul className="subscriptions-legacy">
      {subscriptions.length === 0 && <Body>{t("noNotifications")}</Body>}
      {subscriptions.map((subscription) => {
        return <SubscriptionCard key={subscription.boardName} subscription={subscription} />;
      })}
    </ul>
  );
};

export default Subscriptions;
