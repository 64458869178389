import React, { useId, useRef, useState } from "react";
import { Body, Label, SectionHeader } from "@sprout/typography";
import { TextInput } from "@sprout/text_input";
import LocationSelect from "@components/location_select";
import { IconButton } from "@sprout/icon_button";
import Button from "@sprout/button";
import FileIcon from "@sprout/file_icon";
import CloseIcon from "@sprout/icons/close";
import { Websites } from "@components/quick_apply/websites";
import EducationSection from "@components/quick_apply/education_section";
import { Employment, Employments } from "@components/quick_apply/employments";
import { useEffectAfterMount } from "@hooks/use_effect_after_mount";
import type { Option } from "@sprout/select";
import useCsrfToken from "@hooks/use_csrf";
import {
  EmploymentErrors,
  endDateAfterStartDate,
  validateEmployments,
} from "@components/quick_apply/util";
import ProfileButtons from "@components/profile_buttons";
import UnloadHandler from "@components/unload_handler";
import { SelfIdentificationForm } from "@components/self_identification_form";
import "./quick_apply.scss";
import useTranslation from "@hooks/use_translation";
import { LocationControl, Location } from "@utils/location";
import { SelfIdentification, SelfIdentificationProps } from "@/utils/self_identification";
import { showFlash } from "@sprout/flash.tsx";
import Container from "@components/ui/container.tsx";
import BackButton from "@components/back_button.tsx";

const ALLOWED_FILETYPES = [
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "text/plain",
  "text/rtf",
  "application/rtf",
];

const MAX_FILE_SIZE_MB = 100;

export interface FileState {
  id?: string;
  file: File | null;
  filename: string;
  url: string;
  content_type: string;
}

export type Education = {
  school?: Option;
  degree?: Option;
  discipline?: Option;
  schoolId?: number;
  degreeId?: number;
  disciplineId?: number;
  startMonth?: number;
  startYear?: number;
  endMonth?: number;
  endYear?: number;
  key: string;
};

type Props = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  preferredName: string;
  location: Location | null;
  locationControlParams: LocationControl;
  resume: FileState | null;
  educations: Education[];
  employments: Employment[];
  websites: Websites;
  paths: {
    userPath: string;
    quickApplyPath: string;
    educationPath: string;
  };
  selfIdentification: SelfIdentificationProps;
};

const QuickApply = (props: Props) => {
  const { locationControlParams, paths } = props;
  const { t } = useTranslation("user");

  const [firstName, setFirstName] = useState(props.firstName || "");
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastName, setLastName] = useState(props.lastName || "");
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [preferredName, setPreferredName] = useState(props.preferredName || "");
  const [preferredNameError, setPreferredNameError] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber || "");
  const [phoneNumberError, setPhoneNumberError] = useState<string | null>(null);
  const [location, setLocation] = useState<Location | null>(props.location);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [resume, setResume] = useState<FileState | null>(props.resume);
  const [resumeErrors, setResumeErrors] = useState<string | undefined>("");
  const [websites, setWebsites] = useState<Websites>(props.websites || {});
  const [educations, setEducations] = useState(
    props.educations.map((e) => ({ ...e, key: crypto.randomUUID().toString() })) || [],
  );
  const [educationsError, setEducationsError] = useState<{ [key: number]: string }>({});
  const [employments, setEmployments] = useState<Employment[]>(
    (props.employments.length > 0 ? props.employments : [{}]).map((employment) => ({
      ...employment,
      key: crypto.randomUUID().toString(),
    })),
  );
  const [employmentsErrors, setEmploymentsErrors] = useState<EmploymentErrors>({});
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const csrfToken = useCsrfToken();

  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const preferredNameRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const resumeInputRef = useRef<HTMLInputElement>(null);
  const resumeDivRef = useRef<HTMLDivElement>(null);
  const educationRefs = useRef<Array<HTMLElement>>([]);
  const employmentRefs = useRef<Array<HTMLElement>>([]);
  let errorScrollEl: HTMLInputElement | HTMLElement | null = null;

  const firstNameId = useId();
  const lastNameId = useId();

  const [selfIdentification, setSelfIdentification] = useState<SelfIdentification>(
    props.selfIdentification.value || {},
  );

  useEffectAfterMount(() => {
    setUnsavedChanges(true);
  }, [
    firstName,
    firstNameError,
    lastName,
    lastNameError,
    phoneNumber,
    phoneNumberError,
    preferredName,
    preferredNameError,
    location,
    locationControlParams,
    educations,
    employments,
    selfIdentification,
  ]);

  const handleResumeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setResume({
        file: file,
        filename: file.name,
        url: URL.createObjectURL(file),
        content_type: file.type,
      });
    }
  };

  const deleteFile = async () => {
    if (resume?.id == null) {
      setResume(null);
      return;
    }

    if (window.confirm(t("resume.confirmDelete"))) {
      try {
        const response = await fetch("/resume", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken,
          },
        });

        if (!response.ok) {
          throw new Error("Error deleting file");
        }

        setResume(null);
      } catch (error) {
        console.error("Error deleting file:", error);
      }
    }
  };

  const megabytesToBytes = (mb: number) => mb * 1024 * 1024;

  const setIfFirstErrorEl = (ref: HTMLInputElement | HTMLElement | null) => {
    if (!errorScrollEl) {
      errorScrollEl = ref;
    }
  };

  const scrollToInputError = () => {
    if (!errorScrollEl) {
      return;
    }
    errorScrollEl.scrollIntoView({ behavior: "smooth", block: "center", inline: "start" });
  };

  const validateResume = () => {
    if (!resume?.file) {
      return;
    }

    if (!ALLOWED_FILETYPES.includes(resume.file.type)) {
      return t("resume.invalidFileType");
    }

    if (resume.file.size > megabytesToBytes(MAX_FILE_SIZE_MB)) {
      return t("resume.maxFileSize", { size: MAX_FILE_SIZE_MB });
    }
  };

  const validateEducations = () => {
    const errors: { [key: number]: string } = {};
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;

    educations.forEach((education, index) => {
      // Validate start date is in the past
      if (education.startYear !== undefined) {
        if (education.startYear > currentYear) {
          errors[index] = t("dates.startDateError");
          return;
        } else if (education.startYear === currentYear) {
          if (education.startMonth !== undefined && education.startMonth > currentMonth) {
            errors[index] = t("dates.startDateError");
            return;
          }
        }
      }

      if (
        !endDateAfterStartDate(
          education.startMonth,
          education.startYear,
          education.endMonth,
          education.endYear,
        )
      ) {
        errors[index] = t("dates.dateRangeInvalid");
        return;
      }
    });

    return errors;
  };

  const attachResumeClick = () => {
    if (resumeInputRef.current) {
      resumeInputRef.current.click();
    }
  };

  const clearResume = () => {
    deleteFile();
    if (resumeInputRef.current) {
      resumeInputRef.current.value = "";
    }
  };

  const submit = async () => {
    errorScrollEl = null;

    const resumeError = validateResume();
    resumeError && setIfFirstErrorEl(resumeDivRef.current);

    const educationErrors = validateEducations();
    const hasEducationErrors = Object.keys(educationErrors).length > 0;
    if (hasEducationErrors) {
      const firstError = Object.keys(educationErrors)[0];
      setIfFirstErrorEl(educationRefs.current[firstError as unknown as number]);
    }

    const employmentErrors = validateEmployments(employments);
    const hasEmploymentErrors = Object.keys(employmentErrors).length > 0;
    if (hasEmploymentErrors) {
      const firstError = Object.keys(employmentErrors)[0];
      setIfFirstErrorEl(employmentRefs.current[firstError as unknown as number]);
    }

    if (resumeError || hasEducationErrors || hasEmploymentErrors) {
      setResumeErrors(resumeError);
      setEducationsError(educationErrors);
      setEmploymentsErrors(employmentErrors);
      scrollToInputError();
      return;
    }

    setIsSubmitting(true);
    setError(null);

    setFirstNameError(null);
    setLastNameError(null);
    setPhoneNumberError(null);
    setPreferredNameError(null);
    setResumeErrors("");
    setEducationsError({});
    setEmploymentsErrors({});

    try {
      const formData = new FormData();

      formData.append("user[first_name]", firstName);
      formData.append("user[last_name]", lastName);
      formData.append("personal_info[preferred_name]", preferredName);
      formData.append("personal_info[phone_number]", phoneNumber);
      if (location) {
        formData.append("location[display_name]", location.display_name);
        formData.append("location[latitude]", location.latitude.toString());
        formData.append("location[longitude]", location.longitude.toString());
      }
      if (resume?.file) {
        formData.append("resume", resume.file);
      }

      formData.append("websites[linkedin]", websites.linkedin || "");
      formData.append("websites[github]", websites.github || "");
      formData.append("websites[portfolio]", websites.portfolio || "");
      formData.append("websites[website]", websites.website || "");

      formData.append("educations", JSON.stringify(educations));

      employments.forEach((employment) => {
        formData.append(`employments[][id]`, employment.id?.toString() || "");
        formData.append("employments[][company]", employment.company || "");
        formData.append("employments[][title]", employment.title || "");
        formData.append(
          "employments[][start_date_month]",
          employment.startDate?.month?.toString() || "",
        );
        formData.append(
          "employments[][start_date_year]",
          employment.startDate?.year?.toString() || "",
        );
        formData.append(
          "employments[][end_date_month]",
          employment.endDate?.month?.toString() || "",
        );
        formData.append("employments[][end_date_year]", employment.endDate?.year?.toString() || "");
      });

      formData.append(
        "self_identification[disability_status]",
        selfIdentification.disabilityStatus?.toString() || "",
      );
      formData.append("self_identification[gender]", selfIdentification.gender?.toString() || "");
      formData.append("self_identification[race]", selfIdentification.race?.toString() || "");
      formData.append(
        "self_identification[veteran_status]",
        selfIdentification.veteranStatus?.toString() || "",
      );

      formData.append("authenticity_token", csrfToken);
      const response = await fetch(paths.quickApplyPath, {
        method: "PATCH",
        body: formData,
      });

      if (response.status === 413) {
        setResumeErrors(t("resume.maxFileSize", { size: MAX_FILE_SIZE_MB }));
      }

      if (response.status === 422) {
        const { errors } = await response.json();

        if (errors.first_name) {
          setIfFirstErrorEl(firstNameRef.current);
          setFirstNameError(errors.first_name[0]);
        }

        if (errors.last_name) {
          setIfFirstErrorEl(lastNameRef.current);
          setLastNameError(errors.last_name[0]);
        }

        if (errors.preferred_name) {
          setIfFirstErrorEl(preferredNameRef.current);
          setPreferredNameError(errors.preferred_name[0]);
        }

        if (errors.phone_number) {
          setIfFirstErrorEl(phoneRef.current);
          setPhoneNumberError(errors.phone_number[0]);
        }

        scrollToInputError();

        return;
      }

      if (!response.ok) {
        throw new Error("Not ok");
      }

      showFlash(t("profile_updated.success"), "success");
      setUnsavedChanges(false);
      window.history.back();
    } catch (e) {
      console.error(e);
      setError(t("defaultError"));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      {unsavedChanges && <UnloadHandler />}
      <BackButton href="/profile" />
      <div>
        <div className="quick_apply__header">
          <SectionHeader as="h1" large>
            {t("edit_quick_apply")}
          </SectionHeader>
        </div>
        <Body>{t("quickApplyDescription")}</Body>
      </div>
      <div className="quick_apply__details">
        <div className="quick_apply__short-field">
          <TextInput
            id={firstNameId}
            ref={firstNameRef}
            label={t("fields.firstName")}
            onChange={(event) => setFirstName(event.currentTarget.value || "")}
            maxLength={255}
            value={firstName}
            error={firstNameError && `First name ${firstNameError}`}
          />
        </div>
        <div className="quick_apply__short-field">
          <TextInput
            id={lastNameId}
            ref={lastNameRef}
            label={t("fields.lastName")}
            onChange={(event) => setLastName(event.currentTarget.value || "")}
            maxLength={255}
            value={lastName}
            error={lastNameError && `Last name ${lastNameError}`}
          />
        </div>
        <div className="quick_apply__short-field">
          <TextInput
            id="preferred-name"
            ref={preferredNameRef}
            label={t("fields.preferredName")}
            onChange={(event) => setPreferredName(event.currentTarget.value || "")}
            maxLength={255}
            value={preferredName}
            error={preferredNameError && `Preferred first name ${preferredNameError}`}
          />
        </div>

        <div className="quick_apply__short-field">
          <TextInput
            id="phone"
            ref={phoneRef}
            type="tel"
            label={t("fields.phone")}
            onChange={(event) => setPhoneNumber(event.currentTarget.value || "")}
            maxLength={255}
            value={phoneNumber}
            error={phoneNumberError && `Phone number ${phoneNumberError}`}
          />
        </div>

        <div className="location">
          <LocationSelect
            currentLocation={location}
            setLocation={setLocation}
            locationControlParams={locationControlParams}
          />
        </div>
      </div>
      <div className="quick_apply__documents">
        <div className="quick_apply__resume" ref={resumeDivRef}>
          <Label className={resumeErrors ? "label--error" : ""} id="resume" htmlFor="resume">
            {t("resume.header")}
          </Label>
          {resume ? (
            <div className="quick_apply__selected_documents">
              <FileIcon contentType={resume.content_type} />
              <a href={resume.id && resume.url} target="_blank" rel="noopener noreferrer">
                <Body>{resume.filename}</Body>
              </a>
              <IconButton
                label={t("resume.remove")}
                icon={CloseIcon}
                hoverColor="gray"
                onClick={clearResume}
              />
            </div>
          ) : (
            <>
              <Button shape="pill" onClick={attachResumeClick}>
                {t("resume.add")}
              </Button>
              <input
                type="file"
                id="resume"
                accept=".pdf,.doc,.docx,.txt,.rtf"
                ref={resumeInputRef}
                onChange={handleResumeChange}
              />
              <Body metadata>{t("resume.acceptedFileTypes")}</Body>
            </>
          )}
          {resumeErrors && (
            <p id="resume-error" className="helper-text helper-text--error" aria-live="polite">
              {resumeErrors}
            </p>
          )}
        </div>
      </div>
      <EducationSection
        educations={educations}
        setEducations={setEducations}
        educationsError={educationsError}
        educationPath={paths.educationPath}
        educationRefs={educationRefs}
      />
      <Employments
        employments={employments}
        errors={employmentsErrors}
        setEmployments={setEmployments}
        employmentRefs={employmentRefs}
      />
      <Websites websites={websites} setWebsites={setWebsites} />
      <SelfIdentificationForm
        {...props.selfIdentification}
        value={selfIdentification}
        onChange={setSelfIdentification}
      />

      <ProfileButtons
        loading={isSubmitting}
        error={error}
        submit={submit}
        paths={paths}
        showDeactivate={false}
      />
    </Container>
  );
};

export default QuickApply;
