import React from "react";
import { Body } from "@sprout/typography";

type DetailsSegment = string | string[] | null;

interface SelfIdentificationDetailsProps {
  segments: Readonly<DetailsSegment[]>;
}
export const SelfIdentificationDetails = ({ segments }: SelfIdentificationDetailsProps) => {
  if (!segments.length) {
    return null;
  }

  return (
    <div className="self-identification--details">
      <div className="self-identification--details--panel">
        {segments.map((segment, index) => {
          if (!segment) {
            return <br key={index} />;
          }

          if (Array.isArray(segment)) {
            return (
              <ul key={index} className="body">
                {segment.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            );
          }

          return <Body key={index}>{segment}</Body>;
        })}
      </div>
    </div>
  );
};
