import React from "react";
import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const EditIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        fill={props.color}
        d="M3.73199 21.66C3.37199 21.66 3.01199 21.516 2.74799 21.252C2.37599 20.88 2.24399 20.328 2.41199 19.824L3.47999 16.644C3.55199 16.44 3.65999 16.248 3.81599 16.104L17.124 2.78403C17.4 2.49603 17.784 2.34003 18.18 2.34003C18.552 2.34003 18.912 2.48403 19.188 2.73603L21.228 4.77603C21.504 5.05203 21.66 5.42403 21.66 5.82003C21.66 6.21603 21.504 6.58803 21.228 6.86403L18.936 9.15603L7.91999 20.184C7.76399 20.34 7.57199 20.448 7.36799 20.52L4.17599 21.576C4.03199 21.636 3.88799 21.66 3.73199 21.66ZM15.684 6.20403L4.84799 17.04C4.82399 17.064 4.79999 17.088 4.79999 17.124L3.89999 19.824C3.83999 19.992 4.00799 20.16 4.17599 20.1L6.88799 19.2C6.92399 19.188 6.94799 19.176 6.97199 19.152L17.796 8.31603C17.88 8.23203 17.88 8.08803 17.796 8.00403L15.996 6.19203C15.912 6.12003 15.768 6.12003 15.684 6.20403ZM16.98 5.22003L18.78 7.03203C18.864 7.11603 19.008 7.11603 19.092 7.03203L20.124 6.00003C20.208 5.91603 20.208 5.77203 20.124 5.68803L18.324 3.88803C18.24 3.80403 18.096 3.80403 18.012 3.88803L16.98 4.92003C16.896 4.99203 16.896 5.13603 16.98 5.22003Z"
      />
    </IconSvgWrapper>
  );
};

export default EditIcon;
