import "react-tooltip/dist/react-tooltip.css";
import "./company_logo.scss";

type Props = {
  logoUrl?: string;
  companyName: string;
  size?: "xxsmall" | "xsmall" | "smaller" | "small" | "medium" | "large" | undefined;
};

const CompanyLogo = (props: Props) => {
  return (
    <div className={`company-logo ${props.size || "medium"}`}>
      {props.logoUrl ? (
        <img className="company-logo__logo" src={props.logoUrl} alt={props.companyName} />
      ) : (
        <div className="company-logo__placeholder">
          <div>{props.companyName.charAt(0).toUpperCase()}</div>
        </div>
      )}
    </div>
  );
};

export default CompanyLogo;
