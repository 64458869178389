import "@/stylesheets/tag_overflow.scss";
import { useEffect, useRef, useState } from "react";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";

const useTagOverflow = (tags: string[], renderTag: (tag: string) => JSX.Element) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [visibleTags, setVisibleTags] = useState<string[]>([]);
  const [hiddenTags, setHiddenTags] = useState<string[]>([]);

  useEffect(() => {
    if (!containerRef.current) return;

    const calculateVisibleTags = () => {
      const container = containerRef.current;
      if (!container) return;

      // Reset for recalculation
      setVisibleTags([]);
      setHiddenTags([]);

      // Clone the container to measure without affecting the DOM
      const clone = container.cloneNode(true) as HTMLElement;
      clone.style.visibility = "hidden";
      clone.style.position = "absolute";
      document.body.appendChild(clone);

      const containerWidth = container.clientWidth;
      let currentWidth = 0;
      const visible: string[] = [];
      const hidden: string[] = [];

      // Create temporary tags to measure their width
      const tempContainer = document.createElement("div");
      tempContainer.style.visibility = "hidden";
      tempContainer.style.position = "absolute";
      document.body.appendChild(tempContainer);

      // Determine which tags fit
      const overflowTextBuffer = 28; // pixels

      for (let i = 0; i < tags.length; i++) {
        const tag = tags[i];

        // Create a temporary tag to measure its width
        const tempTag = document.createElement("span");
        const root = createRoot(tempTag);

        flushSync(() => {
          root.render(renderTag(tag));
        });

        tempContainer.appendChild(tempTag);

        const tagWidth = tempTag.offsetWidth + 4; // 4px for margin

        // Once we have all the numbers we need we can clean up
        tempContainer.removeChild(tempTag);
        root.unmount();

        if (currentWidth + tagWidth < containerWidth - overflowTextBuffer) {
          currentWidth += tagWidth;
          visible.push(tag);
        } else {
          // This tag and all remaining tags won't fit
          hidden.push(tag);
          // Add all remaining tags to hidden without measuring them and exit
          hidden.push(...tags.slice(i + 1));
          break;
        }
      }

      document.body.removeChild(clone);
      document.body.removeChild(tempContainer);

      setVisibleTags(visible);
      setHiddenTags(hidden);
    };

    setTimeout(calculateVisibleTags, 0);

    // Recalculate on window resize
    window.addEventListener("resize", calculateVisibleTags);
    return () => window.removeEventListener("resize", calculateVisibleTags);
  }, [tags]);

  return [containerRef, visibleTags, hiddenTags] as const;
};

export default useTagOverflow;
