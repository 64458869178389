import {
  Dialog,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import Callout from "@/sprout/callout";
import { Stack } from "@/sprout/layout";
import { Body } from "@/sprout/typography";
import Button from "@sprout/button.tsx";
import { useTranslation } from "react-i18next";

type Props = {
  linkTextSize?: "medium" | "large";
  underline?: boolean;
};

const DreamJobInfoModal = ({ linkTextSize = "medium", underline = true }: Props) => {
  const { t } = useTranslation("applications", { keyPrefix: "dream_job_info_modal" });

  return (
    <Dialog>
      <DialogTrigger>
        <Button size={linkTextSize} linkStyle underline={underline} className="ml-2">
          {t("button")}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <div>{t("title")}</div>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <Stack gap="16px">
            <Body>{t("description")}</Body>
            <Callout showIcon={true} bodyText={t("callout")} />
          </Stack>
        </DialogDescription>
        <DialogFooter>
          <DialogClose>
            <Button primary>{t("confirm")}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DreamJobInfoModal;
