import useTranslation from "@/hooks/use_translation";
import { RecentJobView } from ".";
import useCsrfToken from "@/hooks/use_csrf";
import { IconButton, IconButtonLink } from "@/sprout/icon_button";
import TrashIcon from "@/sprout/icons/trash";
import Table, { Row } from "@/sprout/table";
import { router } from "@inertiajs/react";
import Pagination from "@/components/pagination";
import "./index.scss";
import StatusIndicator from "./status_indicator";
import { Body } from "@/sprout/typography";
import PreviewIcon from "@/sprout/icons/preview";
import { showFlash } from "@/sprout/flash";
import { queryClient } from "@/queries/query_client";

type Props = {
  jobs: RecentJobView[];
  currentPage?: number;
  total?: number;
  totalPages?: number;
  perPage: number;
};

const RecentlyViewedJobsTableWeb = (props: Props) => {
  const { t } = useTranslation("recentlyViewedJobs");
  const csrfToken = useCsrfToken();

  const headers = [
    t("tableHeaders.title"),
    t("tableHeaders.company"),
    t("tableHeaders.status"),
    t("tableHeaders.viewed"),
    "",
  ];

  const JobTitle = ({ job }: { job: RecentJobView }) => {
    return (
      <div className="recently-viewed-jobs-table__web-row__job-title">
        <Body>{job.title}</Body>
        {!!job.live && (
          <IconButtonLink
            icon={PreviewIcon}
            href={job.url}
            size="sm"
            external={true}
            color="#15372C"
          />
        )}
      </div>
    );
  };

  const deleteRecentJobView = async (deletePath: string) => {
    try {
      const response = await fetch(deletePath, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": csrfToken,
        },
      });

      if (!response.ok) {
        showFlash(t("error"), "error");
        return;
      }

      await queryClient.invalidateQueries({ queryKey: ["recentlyViewedJobs", "dashboard"] });
      router.reload();
    } catch (error) {
      showFlash(t("error"), "error");
    }
  };

  const goToPage = (page: number) => {
    router.get(window.location.pathname, { page });
  };

  const renderRows = (): Row[] => {
    const jobsToRender = props.jobs.slice(-props.perPage);
    return jobsToRender.map((job) => {
      return [
        {
          element: <JobTitle job={job} />,
        },
        {
          primaryBody: job.companyName,
        },
        {
          element: <StatusIndicator live={!!job.live} deviceType="web" />,
        },
        {
          primaryBody: job.lastViewed?.toString(),
        },
        {
          element: (
            <IconButton
              label={t("delete")}
              size="md"
              icon={TrashIcon}
              color="dark-green"
              onClick={() => deleteRecentJobView(job.deletePath)}
            />
          ),
        },
      ];
    });
  };

  return (
    <>
      <Table
        headers={headers}
        rows={renderRows()}
        rowClassName="recently-viewed-jobs-table__web-row"
      />
      {(props.totalPages ?? 1) > 1 && (
        <div className="recently-viewed-jobs-table__pagination-wrapper">
          <Pagination
            currentPage={props.currentPage ?? 1}
            totalPages={props.totalPages ?? 1}
            onPageChange={goToPage}
          />
        </div>
      )}
    </>
  );
};

export default RecentlyViewedJobsTableWeb;
