import React, { useState, useMemo, useCallback, Dispatch, SetStateAction } from "react";
import { Select, type Option, OnSelectParam } from "@sprout/select";
import LocationControl, {
  PeliasResult,
  MapboxResult,
  type LocationControlResult,
} from "@grnhse/location-control";
import useTranslation from "@hooks/use_translation";
import { LocationControl as LocationControlType, Location } from "@utils/location";

type Props = {
  currentLocation: Location | null;
  setLocation: Dispatch<SetStateAction<Location | null>>;
  locationControlParams: LocationControlType;
  short?: boolean;
  helperText?: string;
};

const LocationSelect = (props: Props) => {
  const { currentLocation, setLocation, locationControlParams, short, helperText } = props;
  const { t } = useTranslation("user");

  const [selectedOption, setSelectedOption] = useState<Readonly<Option> | undefined>(
    currentLocation?.display_name
      ? {
          value: 0,
          label: currentLocation?.display_name || "",
        }
      : undefined,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  const locationControl = useMemo(() => {
    return new LocationControl({
      provider: locationControlParams.provider,
      apiKey: locationControlParams.apiKey,
    });
  }, []);

  const loadOptions = useCallback(
    async (inputValue: string): Promise<Option[]> => {
      setIsLoading(true);

      if (!locationControl) {
        return Promise.resolve([] as Option[]);
      }

      const options = await locationControl.results(inputValue);
      setOptions(options);
      setIsLoading(false);
      return options;
    },
    [locationControl],
  );

  const onSelect = async (selected: OnSelectParam) => {
    if (!locationControl) return;

    const storableResult = await locationControl.resultSelected(selected as LocationControlResult);
    handleChange(storableResult);
    setSelectedOption(storableResult);
  };

  const handleChange = (value: LocationControlResult) => {
    if (value instanceof MapboxResult || value instanceof PeliasResult) {
      return setLocation({
        display_name: value.displayName,
        latitude: value.latitude,
        longitude: value.longitude,
      });
    } else if (!value) {
      return setLocation(null);
    }
  };

  return (
    <Select
      id="candidate-location"
      label={t("fields.location")}
      onSelect={onSelect}
      loadOptions={loadOptions}
      value={selectedOption}
      isLoading={isLoading}
      defaultOptions={options}
      isAsync
      isClearable
      hideNoOptionsMessage
      hideDropdownIndicator
      short={short}
      helperText={helperText}
    />
  );
};

export default LocationSelect;
