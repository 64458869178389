import type Application from "../../types/application";
import CompanyLogo from "@components/company_logo";
import { Body, SecondaryBody, SectionHeader, SectionTitle } from "@sprout/typography";
import PreviewIcon from "@sprout/icons/preview";
import EllipsisIcon from "@sprout/icons/ellipsis";
import { Row, Stack } from "@sprout/layout";
import { IconButtonLink } from "@sprout/icon_button";
import useTranslation from "@hooks/use_translation";
import { Tag } from "@sprout/tag.tsx";
import theme from "@/stylesheets/theme.module.scss";
import useDevice from "@/hooks/use_device";
import Tooltip from "@sprout/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui/dropdown-menu.tsx";
import { Dialog, DialogTrigger } from "@components/ui/dialog.tsx";
import DreamJobModal from "./dream_job_modal";
import { startOfMonth, startOfDay, addMonths, differenceInDays } from "date-fns";

type Props = {
  application: Application;
  dreamJobsActive: boolean;
  hasTokens: boolean;
};

const ApplicationHeader = ({ application, dreamJobsActive, hasTokens }: Props) => {
  const { t } = useTranslation("applications");
  const device = useDevice();
  const today = startOfDay(new Date());
  const nextMonthStart = startOfMonth(addMonths(today, 1));
  const daysUntilNewDreamJob = differenceInDays(nextMonthStart, today);

  const buttonSize: "sm" | "md" = device.type == "mobile" ? "sm" : "md";

  const dreamJobDescription = () => {
    if (application.dream_job) {
      return t("dream_job.already_set");
    } else if (!hasTokens) {
      return t("dream_job.no_tokens", { count: daysUntilNewDreamJob });
    } else {
      return t("dream_job.description");
    }
  };

  return (
    <Row gap="24px" align="flex-start">
      <CompanyLogo
        logoUrl={application.company_logo_url}
        companyName={application.company_name}
        size="large"
      />

      <Stack gap="4px">
        {application.dream_job && <Tag label={t("dream_job_tag")} color={theme.marigoldE50} />}
        <SectionHeader>{application.job_title}</SectionHeader>
        <SectionTitle>{application.company_name}</SectionTitle>
      </Stack>

      {!dreamJobsActive && (
        <div className="visit-post-button">
          <Tooltip text={t("dream_job.open_job_post")}>
            <IconButtonLink
              href={application.job_post_url}
              icon={PreviewIcon}
              size={buttonSize}
              shadow={true}
              external={true}
            />
          </Tooltip>
        </div>
      )}

      {dreamJobsActive && (
        <Dialog>
          <DropdownMenu>
            <DropdownMenuTrigger>
              <EllipsisIcon />
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-74" align="end">
              <DropdownMenuItem
                onSelect={() => {
                  window.open(application.job_post_url, "_blank");
                }}
              >
                <Row align="center" gap={"12px"}>
                  <Body>{t("dream_job.open_job_post")}</Body>
                  <PreviewIcon color={theme.darkGreen} />
                </Row>
              </DropdownMenuItem>
              <DialogTrigger disabled={!hasTokens || application.dream_job}>
                <DropdownMenuItem>
                  <Row gap={"8px"}>
                    <Stack>
                      <Body>{t("dream_job.set")}</Body>
                      <div className="dream-job-subtext">
                        <SecondaryBody>{dreamJobDescription()}</SecondaryBody>
                      </div>
                    </Stack>
                  </Row>
                </DropdownMenuItem>
              </DialogTrigger>
            </DropdownMenuContent>
          </DropdownMenu>

          <DreamJobModal application={application} />
        </Dialog>
      )}
    </Row>
  );
};

export default ApplicationHeader;
