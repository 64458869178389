import { router } from "@inertiajs/react";

type MarkAsDreamJobParams = {
  dreamJobPath: string;
  successMessage: string;
  invalidateQuery?: () => void;
  afterSuccess?: () => void;
};

export const markAsDreamJob = ({
  dreamJobPath,
  successMessage,
  invalidateQuery,
  afterSuccess,
}: MarkAsDreamJobParams) => {
  router.post(
    dreamJobPath,
    { successMessage },
    {
      preserveScroll: true,
      onSuccess: () => {
        invalidateQuery && invalidateQuery();

        router.reload({ only: ["applications", "dream_job_tokens"] });

        afterSuccess && afterSuccess();
      },
    },
  );
};
