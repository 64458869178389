import React, { useLayoutEffect, useRef, useState } from "react";

interface Props {
  children: React.ReactNode;
  show: boolean;
}

export const Collapsible = ({ children, show }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    setContentHeight(ref.current.clientHeight);
  }, [ref, children]);

  return (
    <div className="collapsible" style={{ height: show ? contentHeight : 0 }}>
      <div ref={ref}>{children}</div>
    </div>
  );
};
