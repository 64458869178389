import { useEffect, useState } from "react";
import { Link, router } from "@inertiajs/react";
import { usePage } from "@inertiajs/react";
import useCsrfToken from "@hooks/use_csrf";
import NavMenu from "@components/nav_menu.tsx";
import GreenhouseLogo from "@images/greenhouse_logo.svg";
import GreenhouseIconDarkGreen from "@images/greenhouse_icon_dark_green.svg";
import GreenhouseIconWhite from "@images/greenhouse_icon_white.svg";
import HamburgerIcon from "@sprout/icons/hamburger.tsx";
import CloseIcon from "@sprout/icons/close.tsx";
import "./header.scss";
import useTranslation from "@hooks/use_translation.ts";
import useDevice from "@hooks/use_device.ts";
import { Row } from "@sprout/layout.tsx";
import theme from "@/stylesheets/theme.module.scss";
import CandidateIcon from "@sprout/icons/candidate.tsx";
import QuestionIcon from "@sprout/icons/question.tsx";
import SupportDialog from "@components/support_dialog.tsx";

type PageProps = {
  applicationTrackingEnabled: boolean;
  url: string;
};

type Props = {
  hideProfile?: boolean;
  paths?: {
    dashboard: string;
    applications: string;
    profile: string;
    reportBugPath: string;
    signOut: string;
  };
};

const Header = ({
  hideProfile = false,
  paths = {
    dashboard: "/dashboard",
    applications: "/applications",
    profile: "/profile",
    reportBugPath: "/users/report_bug",
    signOut: "/users/sign_out",
  },
}: Props) => {
  const { applicationTrackingEnabled, url } = usePage<PageProps>().props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isSupportDialogOpen, setIsSupportDialogOpen] = useState(false);
  const csrfToken = useCsrfToken();
  const device = useDevice();
  const isMobile = device.type === "mobile";

  const { t } = useTranslation("misc");

  useEffect(() => {
    if (mobileMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [mobileMenuOpen]);

  const handleLinkClick = (url: string) => {
    setMobileMenuOpen(false);
    router.visit(url);
  };

  const openFeedbackModal = () => {
    setMobileMenuOpen(false);
    setIsSupportDialogOpen(true);
  };

  const openAboutLink = () => {
    setMobileMenuOpen(false);
    window.open("https://my.greenhouse.com/", "_blank");
  };

  const logOut = async () => {
    localStorage.removeItem("JOB_SEEKERS_REACT_QUERY_CACHE");

    const response = await fetch(paths.signOut, {
      method: "DELETE",
      body: JSON.stringify({
        authenticity_token: csrfToken,
      }),
      headers: { "Content-Type": "application/json" },
    });

    window.location.href = response.url;
  };

  const renderLogo = () => {
    if (isMobile) {
      const MobileIcon = mobileMenuOpen ? GreenhouseIconWhite : GreenhouseIconDarkGreen;
      return (
        <Link href="/" className="header__logo-wrapper">
          <img src={MobileIcon} alt="Greenhouse icon" />
        </Link>
      );
    }

    return (
      <Link href="/" className="header__logo-wrapper">
        <img src={GreenhouseLogo} alt="Greenhouse logo" />
      </Link>
    );
  };

  const renderLinks = () => (
    <>
      <button
        onClick={() => handleLinkClick(paths.dashboard)}
        className={`header__item ${url === paths.dashboard ? "selected" : ""}`}
      >
        {t("home")}
      </button>
      {applicationTrackingEnabled && (
        <Row gap={"8px"}>
          <button
            onClick={() => handleLinkClick(paths.applications)}
            className={`header__item ${url === paths.applications ? "selected" : ""}`}
          >
            {t("applications")}
          </button>
        </Row>
      )}
      {isMobile && mobileMenuOpen && (
        <>
          {!hideProfile && (
            <button
              onClick={() => handleLinkClick(paths.profile)}
              className={`header__item ${url === paths.profile ? "selected" : ""}`}
            >
              {t("profile")}
            </button>
          )}
          <button className={"header__item"} onClick={() => openFeedbackModal()}>
            {t("submit_feedback")}
          </button>
          <button className="header__item" onClick={() => openAboutLink()}>
            {t("about")}
          </button>
          <button className="header__item" onClick={logOut}>
            {t("logOut")}
          </button>
        </>
      )}
    </>
  );

  const renderDesktopMenuButtons = () => (
    <div className="header__desktop-menu-buttons">
      {!hideProfile && (
        <NavMenu
          icon={<CandidateIcon color={theme.darkGreen} />}
          items={[
            { value: 0, label: t("profile"), onSelect: () => router.visit(paths.profile) },
            { value: 1, label: t("logOut"), onSelect: logOut },
          ]}
        />
      )}
      <NavMenu
        icon={<QuestionIcon color={theme.darkGreen} />}
        items={[
          { value: 0, label: t("submit_feedback"), onSelect: openFeedbackModal },
          { value: 1, label: t("about"), onSelect: openAboutLink },
        ]}
      />
    </div>
  );

  const renderMobileMenuButton = () => (
    <button
      className="header__mobile-menu-button"
      onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
    >
      {mobileMenuOpen ? (
        <CloseIcon size={"md"} color={"white"} />
      ) : (
        <HamburgerIcon size={"md"} color={"dark-green"} />
      )}
    </button>
  );

  return (
    <div>
      <header className={`header ${mobileMenuOpen ? "header__mobile-menu-open" : ""}`}>
        <div className="header__content">
          <div className="header__left">
            {renderLogo()}
            {!isMobile && renderLinks()}
          </div>
          {!isMobile && renderDesktopMenuButtons()}
          {isMobile && renderMobileMenuButton()}
        </div>
        {isMobile && mobileMenuOpen && <div className="header__mobile-menu">{renderLinks()}</div>}
        <SupportDialog
          isOpen={isSupportDialogOpen}
          setIsDialogOpen={setIsSupportDialogOpen}
          bugReportPath={paths.reportBugPath}
        />
      </header>
    </div>
  );
};

export default Header;
