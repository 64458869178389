import { useMediaQuery } from "usehooks-ts";

const useDevice = () => {
  const isMobile = useMediaQuery("(max-width: 899px)");
  const isTouch = useMediaQuery("(pointer: coarse)");

  return {
    type: isMobile ? "mobile" : "web",
    isTouch: isMobile && isTouch,
  };
};

export default useDevice;
