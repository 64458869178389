import {
  Tooltip as ShadCNTooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

type TooltipProps = {
  children: React.ReactNode;
  text: string;
};

const Tooltip = ({ children, text }: TooltipProps) => {
  return (
    <TooltipProvider>
      <ShadCNTooltip>
        <TooltipTrigger>{children}</TooltipTrigger>
        <TooltipContent side="bottom">
          <p>{text}</p>
        </TooltipContent>
      </ShadCNTooltip>
    </TooltipProvider>
  );
};

export default Tooltip;
