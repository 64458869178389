import React from "react";
import { Menu, MenuList, MenuButton, MenuItem } from "@reach/menu-button";

import "@reach/menu-button/styles.css";

export type ItemProps = {
  value: number;
  label: string | React.ReactNode;
  onSelect?: () => void;
  disabled?: boolean;
};

const MenuItems = ({
  items,
  selected,
  onSelect,
}: {
  items: ItemProps[];
  selected?: ItemProps;
  onSelect: (selected: ItemProps) => void;
}) => (
  <MenuList>
    {items.map((item) => (
      <MenuItem
        key={item.value}
        className={item.value === selected?.value ? "expandable-menu--selected" : ""}
        onSelect={() => {
          onSelect({ value: item.value, label: item.label, onSelect: item.onSelect });
        }}
        disabled={item.disabled}
      >
        {item.label}
      </MenuItem>
    ))}
  </MenuList>
);

type ExpandableMenuProps = {
  icon: React.ReactNode;
  items: ItemProps[];
  selected?: ItemProps;
  onSelect: (selected: ItemProps) => void;
  className?: string;
};

const ExpandableMenu = (props: ExpandableMenuProps) => {
  const { icon, items, selected, onSelect, className } = props;

  return (
    <Menu>
      <MenuButton className={className}>{icon}</MenuButton>
      <MenuItems items={items} selected={selected} onSelect={onSelect} />
    </Menu>
  );
};

export default ExpandableMenu;
