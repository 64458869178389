import React from "react";
import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const NextIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.90353 4.84448C6.37216 5.31311 6.37216 6.07291 5.90353 6.54154L1.38638 11.0587C1.07396 11.3711 0.567425 11.3711 0.255006 11.0587C-0.0574141 10.7463 -0.0574145 10.2398 0.255005 9.92733L4.48932 5.69301L0.255005 1.4587C-0.0574141 1.14628 -0.0574145 0.639752 0.255005 0.327332C0.567424 0.0149128 1.07396 0.0149126 1.38638 0.327332L5.90353 4.84448Z"
        fill="#222222"
      />
    </IconSvgWrapper>
  );
};

export default NextIcon;
