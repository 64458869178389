import React from "react";
import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const FileIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <g stroke={props.color} strokeWidth="1.5" fill="none" fillRule="evenodd">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M1.037 26.316h19.491V9.29L13 1.684H1z"
        />
        <path strokeLinejoin="round" d="M12.688 2.129v7.334h8.16" />
      </g>
    </IconSvgWrapper>
  );
};

export default FileIcon;
