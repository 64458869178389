import * as React from "react";
import classNames from "classnames";
import { Label } from "./typography";
import { RefObject } from "react";
import { Row } from "./layout";
import { IconButton } from "./icon_button";
import CloseIcon from "./icons/close";

type InputType = "email" | "number" | "password" | "tel" | "text" | "url";

export interface SharedTextInputProps {
  id?: string;
  value?: string;
  label?: string | null;
  placeholder?: string | null;
  outsideLabel?: boolean;
  disabled?: boolean;
  maxLength?: number;
  required?: boolean;
  help?: React.ReactNode | null;
  error?: React.ReactNode | null;
  rows?: number;
  short?: boolean;
  narrow?: boolean;
  autoFocus?: boolean;
  defaultValue?: string;
  onChange?: (event: React.SyntheticEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onBlur?: (() => void) | undefined;
  ref?: RefObject<HTMLInputElement | HTMLTextAreaElement | null>;
  icon?: React.ReactNode;
  className?: string;
}

interface TextInputProps extends SharedTextInputProps {
  type?: InputType;
  multiline?: boolean;
  onClear?: () => void;
}

export const TextInput = (props: TextInputProps) => {
  const {
    id,
    value,
    label,
    type = "text",
    placeholder,
    outsideLabel = false,
    disabled = false,
    maxLength,
    required = false,
    help,
    error = null,
    multiline = false,
    rows,
    short,
    narrow,
    autoFocus,
    defaultValue,
    onChange,
    onBlur,
    ref,
    icon,
    onClear = undefined,
    className,
  } = props;
  const [focused, setFocused] = React.useState(false);

  const multilineStyles = multiline && !outsideLabel;
  const noLabel = !label || label === "";

  let endAdornmentComponent = null;
  if (value && onClear) {
    endAdornmentComponent = (
      <div className="end-adornment">
        <IconButton icon={CloseIcon} label={"Clear"} onClick={onClear} />
      </div>
    );
  }

  const textInputWrapperClasses = classNames({
    "text-input-wrapper": true,
    [className as string]: className,
  });

  const labelClasses = classNames({
    label: true,
    "label--outside-label": outsideLabel,
    "label--error": !!error,
  });

  const inputWrapperClasses = classNames({
    "input-wrapper": true,
    "input-wrapper__multi-line": multilineStyles,
    "input-wrapper__multi-line--disabled": multilineStyles && disabled,
    "input-wrapper--active": focused,
    "input-wrapper--error": !!error,
    "input-wrapper--disabled": disabled,
    "input-wrapper--short": short,
    "input-wrapper--outside-label": outsideLabel,
    "input-wrapper--no-label": noLabel && !narrow,
    "input-wrapper--narrow": noLabel && narrow,
    "input-wrapper--with-label": !noLabel,
    "input-wrapper--with-clear-button": !!onClear,
  });

  const inputClasses = classNames({
    input: true,
    "input--outside-label": outsideLabel,
    "input--disabled": disabled,
    "input__multi-line": multilineStyles,
    "input__single-line": !multiline,
  });

  const helperTextClasses = classNames({
    "helper-text": true,
    "helper-text--error": !!error,
  });

  const labelId = `${id}-label`;

  const onBlurCallback = () => {
    setFocused(false);

    if (onBlur) {
      onBlur();
    }
  };

  return (
    <div className={textInputWrapperClasses}>
      <div className={inputWrapperClasses}>
        {label && (
          <Label className={labelClasses} id={labelId} htmlFor={id as string} required={required}>
            {label}
          </Label>
        )}
        <Row gap="8px">
          {icon && icon}
          {multiline ? (
            <textarea
              id={id}
              value={value}
              className={inputClasses}
              aria-label={label || undefined}
              aria-describedby={`${id}-description ${id}-error ${id}-help`}
              aria-invalid={!!error}
              aria-errormessage={`${id}-error`}
              aria-required={required}
              placeholder={placeholder || undefined}
              disabled={disabled}
              maxLength={maxLength}
              rows={rows}
              onChange={onChange}
              onFocus={() => setFocused(true)}
              onBlur={onBlurCallback}
              ref={ref as RefObject<HTMLTextAreaElement>}
            />
          ) : (
            <input
              id={id}
              value={value}
              className={inputClasses}
              aria-label={label || undefined}
              aria-describedby={`${id}-description ${id}-error ${id}-help`}
              aria-invalid={!!error}
              aria-errormessage={`${id}-error`}
              aria-required={required}
              type={type}
              placeholder={placeholder || undefined}
              disabled={disabled}
              maxLength={maxLength}
              autoFocus={autoFocus}
              onChange={onChange}
              onFocus={() => setFocused(true)}
              onBlur={onBlurCallback}
              defaultValue={defaultValue}
              ref={ref as RefObject<HTMLInputElement>}
            />
          )}
          {endAdornmentComponent && endAdornmentComponent}
        </Row>
      </div>

      {help && (
        <p className="helper-text" aria-live="polite" data-testid={`${id}-help`}>
          {help}
        </p>
      )}

      {error && (
        <p
          id={`${id}-error`}
          className={helperTextClasses}
          aria-live="polite"
          data-testid={`${id}-error`}
        >
          {error}
        </p>
      )}
    </div>
  );
};
