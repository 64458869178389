import { Link } from "@sprout/typography";
import useTranslation from "@hooks/use_translation";

const LegalLinks = () => {
  const { t } = useTranslation("misc");
  return (
    <div className="text-center">
      <p className="pb-4">
        <Link href="/users/agreement" target="_blank">
          {t("userAgreement")}
        </Link>
      </p>
      <p>
        <Link href="https://www.greenhouse.com/privacy-policy" target="_blank" rel="noreferrer">
          {t("privacyPolicy")}
        </Link>
      </p>
    </div>
  );
};

export default LegalLinks;
