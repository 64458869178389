import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";

const HamburgerIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props };

  return (
    <IconSvgWrapper {...props}>
      <path
        className={`icon--${props.color}`}
        d="M24.7215 8.86802H5.27851C4.78201 8.86802 4.37851 8.46452 4.37851 7.96802C4.37851 7.47152 4.78201 7.06802 5.27851 7.06802H24.7215C25.218 7.06802 25.6215 7.47152 25.6215 7.96802C25.6215 8.46452 25.218 8.86802 24.7215 8.86802ZM24.7215 15.9H5.27851C4.78201 15.9 4.37851 15.4965 4.37851 15C4.37851 14.5035 4.78201 14.1 5.27851 14.1H24.7215C25.218 14.1 25.6215 14.5035 25.6215 15C25.6215 15.4965 25.218 15.9 24.7215 15.9ZM5.27851 22.932H24.7215C25.218 22.932 25.6215 22.5285 25.6215 22.032C25.6215 21.5355 25.218 21.132 24.7215 21.132H5.27851C4.78201 21.132 4.37851 21.5355 4.37851 22.032C4.37851 22.5285 4.78201 22.932 5.27851 22.932Z"
        fill="#15372C"
      />
    </IconSvgWrapper>
  );
};

export default HamburgerIcon;
