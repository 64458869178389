import type Application from "../../types/application";
import { Body, SecondaryBody } from "@sprout/typography";
import CompanyLogo from "@components/company_logo";
import { Row, Stack } from "@sprout/layout";
import { Tag } from "@sprout/tag.tsx";
import theme from "@/stylesheets/theme.module.scss";
import useTranslation from "@hooks/use_translation";
import { IconButton } from "@/sprout/icon_button";
import PreviewIcon from "@/sprout/icons/preview";
import { router } from "@inertiajs/react";
import Tooltip from "@/sprout/tooltip";

type Props = {
  application: Application;
  selected: boolean;
  onApplicationSelect: (id: number) => void;
  dreamJobsActive: boolean;
  narrow?: boolean;
  addLink?: boolean;
};

const ApplicationCard = ({
  application,
  selected,
  onApplicationSelect,
  dreamJobsActive,
  narrow = false,
  addLink = false,
}: Props) => {
  const { t } = useTranslation("user");

  return (
    <div
      className={`application-card ${selected ? "selected" : ""} ${narrow ? "narrow" : ""}`}
      onClick={() => onApplicationSelect(application.id)}
    >
      <Stack gap={narrow ? "16px" : "4px"} className={narrow ? "!flex-row" : ""}>
        <Row gap="12px" className={narrow ? "basis-[content]" : ""}>
          <div className="application-card-left">
            <CompanyLogo
              logoUrl={application.company_logo_url}
              companyName={application.company_name}
              size={narrow ? "small" : "smaller"}
            />
          </div>
          {dreamJobsActive && application.dream_job && (
            <div className="application-card-tag">
              <Tag label={t("dream_job")} color={theme.marigoldE50} />
            </div>
          )}
        </Row>
        <Stack gap={narrow ? "0" : "4px"}>
          <Body medium className={narrow ? "!text-sm" : ""}>
            {application.job_title}
          </Body>
          <SecondaryBody>{application.company_name}</SecondaryBody>
          <SecondaryBody metadata className={narrow ? "!text-xs leading-[1.3rem]" : ""}>
            {application.status_message}
          </SecondaryBody>
        </Stack>
        {addLink && (
          <div className="h-full content-center">
            <Tooltip text={t("application_icon_tooltip")}>
              <IconButton
                icon={PreviewIcon}
                onClick={(e) => {
                  e.stopPropagation();
                  router.visit(`/applications/${application.id}`);
                }}
                className="application-card-link"
                label={t("application_icon_tooltip")}
                size="md"
              />
            </Tooltip>
          </div>
        )}
      </Stack>
    </div>
  );
};

export default ApplicationCard;
