import { useState } from "react";
import { type Option, Select } from "@sprout/select";
import type { EmailSchedule } from "@/pages/job_alerts";
import useCsrfToken from "@hooks/use_csrf";
import useTranslation from "@hooks/use_translation";
import { showFlash } from "@/sprout/flash";
import { useEffectAfterMount } from "@/hooks/use_effect_after_mount";

type Props = {
  paths: {
    notificationSchedulePath: string;
  };
  emailSchedule: EmailSchedule;
  emailSchedules: { [key: string]: number };
  shortCadence?: boolean;
};

const Preferences = (props: Props) => {
  const { paths, emailSchedule, emailSchedules } = props;
  const csrfToken = useCsrfToken();
  const { t } = useTranslation("jobAlerts");

  const getCadenceText = (cadence: string) => {
    return props.shortCadence
      ? cadence.charAt(0).toUpperCase() + cadence.slice(1)
      : t("preferences.sendEmailCadence", { cadence });
  };

  const scheduleOptions = Object.keys(emailSchedules).map((cadence) => ({
    value: emailSchedules[cadence],
    label: getCadenceText(cadence),
  }));

  const defaultEmailSchedule =
    scheduleOptions.find((option) => option.value === emailSchedule.id) || scheduleOptions[0];

  const [schedule, setSchedule] = useState<Option>(defaultEmailSchedule);

  const cadence =
    schedule.value === emailSchedules["daily"] ? t("preferences.daily") : t("preferences.weekly");

  useEffectAfterMount(() => {
    updateEmailSchedule();
  }, [schedule.value]);

  const updateEmailSchedule = () => {
    try {
      fetch(paths.notificationSchedulePath, {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          cadence,
          authenticity_token: csrfToken,
        }),
      }).then(() => {
        showFlash("Frequency updated!");
      });
    } catch (e) {
      console.error("Error updating email schedule", e);
    }
  };

  return (
    <div className="preferences">
      <Select
        id="email-schedule"
        label=""
        options={scheduleOptions}
        onSelect={(item) => {
          setSchedule(item as Option);
        }}
        selected={schedule}
      />
    </div>
  );
};

export default Preferences;
