import React from "react";
import { Error } from "@components/error";
import useTranslation from "@hooks/use_translation";
import Layout from "@components/layout";

const GenericError = () => {
  const { t } = useTranslation("misc");
  return (
    <Error
      header={t("500.header")}
      message={[t("500.body1"), t("500.body2")]}
      link={{
        href: document.referrer,
        label: t("500.cta"),
      }}
    />
  );
};

GenericError.layout = (page: React.ReactElement<{ publicFacing: boolean }>) => (
  <Layout publicFacing={page.props.publicFacing}>{page}</Layout>
);

export default GenericError;
