import { useState } from "react";
import useCsrfToken from "@hooks/use_csrf";
import { useEffectAfterMount } from "@hooks/use_effect_after_mount";
import { SectionHeader } from "@sprout/typography";
import { TextInput } from "@sprout/text_input";
import ProfileButtons from "@components/profile_buttons";
import UnloadHandler from "@components/unload_handler";
import useTranslation from "@hooks/use_translation";
import { showFlash } from "@sprout/flash.tsx";
import "./account.scss";
import Container from "@components/ui/container.tsx";
import BackButton from "@components/back_button.tsx";

type Props = {
  email: string;
  firstName: string | null;
  lastName: string | null;
  paths: {
    userPath: string;
  };
};

const Account = (props: Props) => {
  const { email, paths } = props;

  const { t } = useTranslation("user");
  const csrfToken = useCsrfToken();
  const [firstName, setFirstName] = useState(props.firstName || "");
  const [firstNameError, setFirstNameError] = useState<string | null>(null);
  const [lastName, setLastName] = useState(props.lastName || "");
  const [lastNameError, setLastNameError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffectAfterMount(() => {
    setUnsavedChanges(true);
  }, [firstName, firstNameError, lastName, lastNameError]);

  const submit = async () => {
    setIsSubmitting(true);
    setError(null);
    setFirstNameError(null);
    setLastNameError(null);

    try {
      const formData = new FormData();

      formData.append("user[first_name]", firstName);
      formData.append("user[last_name]", lastName);
      formData.append("authenticity_token", csrfToken);

      const response = await fetch(paths.userPath, {
        method: "PATCH",
        body: formData,
      });

      if (response.status === 422) {
        const { errors } = await response.json();

        if (errors.first_name) {
          setFirstNameError(errors.first_name[0]);
        }

        if (errors.last_name) {
          setLastNameError(errors.last_name[0]);
        }

        return;
      }

      if (!response.ok) {
        throw new Error("Not ok");
      }

      showFlash(t("profile_updated.success"), "success");
      setUnsavedChanges(false);
      window.history.back();
    } catch (e) {
      console.error(e);
      setError(t("defaultError"));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      {unsavedChanges && <UnloadHandler />}
      <BackButton href="/profile" />
      <SectionHeader large as="h1">
        {t("edit_account")}
      </SectionHeader>
      <div className="account-inputs">
        <TextInput
          id="first-name"
          label={t("fields.firstName")}
          onChange={(event) => setFirstName(event.currentTarget.value || "")}
          maxLength={255}
          value={firstName}
          error={firstNameError && t("accountForm.firstNameError", { error: firstNameError })}
        />
        <TextInput
          id="last-name"
          label={t("fields.lastName")}
          onChange={(event) => setLastName(event.currentTarget.value || "")}
          maxLength={255}
          value={lastName}
          error={lastNameError && t("accountForm.lastNameError", { error: lastNameError })}
        />
        <TextInput
          id="email-address"
          label={t("fields.email")}
          onChange={() => {}}
          maxLength={255}
          value={email}
          disabled
        />
      </div>

      <ProfileButtons loading={isSubmitting} error={error} submit={submit} paths={paths} />
    </Container>
  );
};

export default Account;
