import React, { RefObject, useCallback, useId, useState } from "react";
import { SectionTitle } from "@sprout/typography";
import { TextInput } from "@sprout/text_input";
import "./employments.scss";
import { type Option, Select } from "@sprout/select";
import { useMonthOptions } from "@hooks/use_month_options";
import Button from "@sprout/button";
import TrashIcon from "@sprout/icons/trash";
import { IconButton } from "@sprout/icon_button";
import type { EmploymentError, EmploymentErrors } from "@components/quick_apply/util";
import { Checkbox } from "@sprout/checkbox";
import useTranslation from "@hooks/use_translation";

export interface MonthYear {
  month?: number | null;
  year?: number | null;
}

export interface Employment {
  id?: number | null;
  key: string;
  title?: string | null;
  company?: string | null;
  startDate?: MonthYear | null;
  endDate?: MonthYear | null;
}

interface Props {
  employments: Employment[];
  errors: EmploymentErrors;
  setEmployments: (employments: Employment[]) => void;
  employmentRefs: RefObject<HTMLElement[]>;
}

interface EmploymentProps {
  deletable?: boolean;
  employment: Employment;
  errors: EmploymentError;
  onChange: (employment: Employment) => void;
  onDelete: () => void;
  index: number;
  employmentRefs: RefObject<HTMLElement[]>;
}

const CURRENT_ROLE_CHECKBOX_OPTIONS = [{ label: "employment.currentRole", value: "isCurrentRole" }];

const Employment = ({
  deletable,
  employment,
  errors,
  onChange,
  onDelete,
  index,
  employmentRefs,
}: EmploymentProps) => {
  const months = useMonthOptions();
  const id = useId();
  const { t } = useTranslation("user");

  const selectedStartMonth = months.find((m) => m.value === employment.startDate?.month) || null;
  const selectedEndMonth = months.find((m) => m.value === employment.endDate?.month) || null;

  const [isCurrentRole, setIsCurrentRole] = useState(!!employment.company && !employment.endDate);

  const handleMonthChange = (key: "startDate" | "endDate", month: Option | null) => {
    const existingDate = employment[key] || {};

    onChange({
      ...employment,
      [key]: {
        ...existingDate,
        month: month?.value as number,
      },
    });
  };

  const handleYearChange = (key: "startDate" | "endDate", year: string) => {
    const existingDate = employment[key] || {};

    onChange({
      ...employment,
      [key]: {
        ...existingDate,
        year: parseInt(year) || null,
      },
    });
  };

  const handleCurrentRoleChange = () => {
    onChange({
      ...employment,
      endDate: null,
    });
    setIsCurrentRole(!isCurrentRole);
  };

  const setDivRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      employmentRefs.current[index] = node;
    }
  }, []);

  return (
    <div id={id} ref={setDivRef} className={"quick_apply__employment"}>
      <div className="quick_apply__employment__header">
        <SectionTitle>{t("employment.header")}</SectionTitle>
        {deletable && (
          <IconButton
            label={t("employment.remove")}
            size="md"
            icon={TrashIcon}
            onClick={onDelete}
          />
        )}
      </div>
      <TextInput
        label={t("employment.company")}
        value={employment.company || ""}
        onChange={(e) => onChange({ ...employment, company: e.currentTarget.value })}
        error={errors.company && t(errors.company)}
      />
      <TextInput
        label={t("employment.title")}
        value={employment.title || ""}
        onChange={(e) => onChange({ ...employment, title: e.currentTarget.value })}
        error={errors.title && t(errors.title)}
      />
      <div className={"quick_apply__employment__date"}>
        <Select
          id={`${id}-startDateMonth`}
          label={t("dates.startDateMonth")}
          options={months}
          selected={selectedStartMonth}
          onSelect={(month) => handleMonthChange("startDate", month as Option)}
          error={errors.startDateMonth && t(errors.startDateMonth)}
          isClearable
        />
        <TextInput
          type="number"
          label={t("dates.startDateYear")}
          value={employment.startDate?.year?.toString() || ""}
          onChange={(e) => handleYearChange("startDate", e.currentTarget.value)}
          error={errors.startDateYear && t(errors.startDateYear)}
        />
      </div>
      <div className={"quick_apply__employment__end_date"}>
        <div className={"quick_apply__employment__date"}>
          <Select
            id={`${id}-endDateMonth`}
            label={t("dates.endDateMonth")}
            options={months}
            onSelect={(month) => handleMonthChange("endDate", month as Option)}
            selected={selectedEndMonth}
            error={errors.endDateMonth && t(errors.endDateMonth)}
            isClearable
            isDisabled={isCurrentRole}
            value={selectedEndMonth}
          />
          <TextInput
            type="number"
            label={t("dates.endDateYear")}
            value={employment.endDate?.year?.toString() || ""}
            onChange={(e) => handleYearChange("endDate", e.currentTarget.value)}
            error={
              (errors.endDateYear && t(errors.endDateYear)) ||
              (errors.endDateYearInvalid && t(errors.endDateYearInvalid))
            }
            disabled={isCurrentRole}
          />
        </div>
        <Checkbox
          id={`${id}-isCurrentRole`}
          options={CURRENT_ROLE_CHECKBOX_OPTIONS.map((opt) => ({ ...opt, label: t(opt.label) }))}
          onSelect={handleCurrentRoleChange}
          values={isCurrentRole ? [CURRENT_ROLE_CHECKBOX_OPTIONS[0].value] : []}
          isMulti
        />
      </div>
    </div>
  );
};

export const Employments = ({ employments, errors, setEmployments, employmentRefs }: Props) => {
  const { t } = useTranslation("user");

  const addEmployment = () => {
    setEmployments([...employments, { key: crypto.randomUUID().toString() }]);
  };

  const handleChange = (index: number, employment: Employment) => {
    setEmployments([...employments.slice(0, index), employment, ...employments.slice(index + 1)]);
  };

  const handleDelete = (index: number) => {
    setEmployments([...employments.slice(0, index), ...employments.slice(index + 1)]);
  };

  if (!employments) {
    return null;
  }

  return (
    <div className="quick_apply__employments">
      {employments.map((employment, index) => (
        <React.Fragment key={employment.key}>
          {index > 0 && <hr />}
          <Employment
            deletable={!!employment.id}
            employment={employment}
            errors={errors[index] || {}}
            onChange={(employment) => handleChange(index, employment)}
            onDelete={() => handleDelete(index)}
            index={index}
            employmentRefs={employmentRefs}
          />
        </React.Fragment>
      ))}
      <div>
        <Button linkStyle onClick={addEmployment}>
          {t("add_employment")}
        </Button>
      </div>
    </div>
  );
};
