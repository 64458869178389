import React from "react";
import useTranslation from "@hooks/use_translation";
import TaskCard from "./TaskCard";
import type { Task } from "@/types/task";

interface TaskCardProps {
  task: Task;
  statusText: string;
}

const SetupWorkPreference: React.FC<TaskCardProps> = ({ task, statusText }) => {
  const { t } = useTranslation("tasks");

  return (
    <TaskCard
      statusText={statusText}
      task={task}
      title={t("workPreferences.title")}
      description={t("workPreferences.description")}
      actionHref="/work_preferences"
      actionText={t("workPreferences.completeProfile")}
    />
  );
};

export default SetupWorkPreference;
