import { QueryClient } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";

const ONE_DAY = 1000 * 60 * 60 * 24;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutes
      gcTime: ONE_DAY,
    },
  },
});

export const localStoragePersister = createSyncStoragePersister({
  storage: window.localStorage,
  key: "JOB_SEEKERS_REACT_QUERY_CACHE",
});
