import { useQuery } from "@tanstack/react-query";
import type { Location } from "@utils/location";
import { CompletionStatusType } from "@utils/completion_status.ts";

export interface ProfileData {
  email: string;
  firstName: string | null;
  lastName: string | null;
  phoneNumber: string | null;
  preferredName: string | null;
  location?: Location;
  accountCompletion: CompletionStatusType;
  quickApplyCompletion: CompletionStatusType;
  workPreferencesCompletion: CompletionStatusType;
  workPreferences: string[];
}

const useProfile = () => {
  const fetchProfile = async (): Promise<ProfileData> => {
    const response = await fetch("/profiles/data");
    if (!response.ok) {
      throw new Error("Failed to fetch profile data");
    }
    return response.json();
  };

  return useQuery({
    queryKey: ["profile"],
    queryFn: fetchProfile,
    staleTime: 0,
  });
};

export default useProfile;
