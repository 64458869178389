import useTranslation from "@hooks/use_translation";
import { useState } from "react";
import { Body } from "@sprout/typography";
import ApplicationCardList from "./application_card_list";
import { Stack } from "@sprout/layout";
import type Application from "../../types/application";
import { TextInput } from "@sprout/text_input.tsx";
import { Trans } from "react-i18next";
import SearchIcon from "@/sprout/icons/search";
import useTemporaryStorage from "@hooks/use_temporary_storage";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/sprout/tabs";
import { useDebounce } from "react-use";

type Props = {
  activeApplications: Application[];
  inactiveApplications?: Application[];
  onApplicationSelect: (id: number) => void;
  dreamJobsActive: boolean;
  isLoading: boolean;
  selectedApplication?: Application | undefined;
  device: { type: string };
  hasTokens: boolean;
};

const ApplicationsList = (props: Props) => {
  const {
    activeApplications,
    inactiveApplications,
    onApplicationSelect,
    dreamJobsActive,
    isLoading,
    selectedApplication,
    device,
    hasTokens,
  } = props;
  const { t } = useTranslation("applications");
  const [search, setSearch] = useTemporaryStorage("application-search", "");
  const [debouncedSearch, setDebouncedSearch] = useState(search);
  const [tab, setTab] = useTemporaryStorage("application-tab", "active");

  const filterApplications = (applicationsToFilter: Application[]) => {
    return applicationsToFilter.filter((application) => {
      return (
        application.job_title.toLowerCase().includes(search.toLowerCase()) ||
        application.company_name.toLowerCase().includes(search.toLowerCase())
      );
    });
  };

  const filteredActive = filterApplications(activeApplications || []);
  const filteredInactive = filterApplications(inactiveApplications || []);

  useDebounce(
    () => {
      setSearch(debouncedSearch);
    },
    200,
    [debouncedSearch],
  );

  const shownList = (tab === "active" ? filteredActive : filteredInactive) || [];
  const activeCount = filteredActive.length;
  const inactiveCount = filteredInactive.length;
  const totalApplications = activeCount + inactiveCount;

  if (device.type == "web") {
    if (shownList.length > 0 && !selectedApplication?.id) {
      onApplicationSelect(shownList[0].id);
    }
  }

  return (
    <div className="applications-list">
      <Stack gap="8px">
        {!isLoading && (
          <div className="applications-list-header">
            <Body>
              <Trans i18nKey="applications:count_of_applications" count={totalApplications} />
            </Body>
            <TextInput
              id="search"
              placeholder={t("search")}
              onChange={(event) => setDebouncedSearch(event.currentTarget.value || "")}
              maxLength={255}
              value={debouncedSearch}
              narrow={true}
              icon={<SearchIcon />}
              onClear={() => setDebouncedSearch("")}
            />
          </div>
        )}
        {!isLoading && (
          <Tabs defaultValue={tab} onValueChange={setTab} className="w-full">
            <TabsList>
              <TabsTrigger value="active">
                {t("active_with_count", { count: activeCount })}
              </TabsTrigger>
              <TabsTrigger value="inactive">
                {t("inactive_with_count", { count: inactiveCount })}
              </TabsTrigger>
            </TabsList>
            {totalApplications === 0 && (
              <p className="empty-message">
                <Trans i18nKey="applications:empty_message" />
              </p>
            )}
            <TabsContent value="active">
              {filteredActive && (
                <ApplicationCardList
                  selectedApplicationId={selectedApplication?.id}
                  applications={filteredActive}
                  dreamJobsActive={dreamJobsActive}
                  onApplicationSelect={onApplicationSelect}
                  hasTokens={hasTokens}
                />
              )}
            </TabsContent>
            <TabsContent value="inactive">
              {filteredInactive && (
                <ApplicationCardList
                  selectedApplicationId={selectedApplication?.id}
                  applications={filteredInactive}
                  dreamJobsActive={dreamJobsActive}
                  onApplicationSelect={onApplicationSelect}
                />
              )}
            </TabsContent>
          </Tabs>
        )}
      </Stack>
    </div>
  );
};

export default ApplicationsList;
