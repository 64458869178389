import useDevice from "@/hooks/use_device";
import { Body } from "@/sprout/typography";
import useTranslation from "@hooks/use_translation";
import RecentlyViewedJobsTableWeb from "./web";
import RecentlyViewedJobsTableMobile from "./mobile";
import { TextInput } from "@/sprout/text_input";
import SearchIcon from "@/sprout/icons/search";
import EmptyState from "@components/empty_state.tsx";
import Fingerprint2 from "@images/fingerprint_2.svg";

export type RecentJobView = {
  jobPostId: string;
  title?: string;
  companyName?: string;
  lastViewed: string;
  live?: boolean;
  url: string;
  deletePath: string;
};

type Props = {
  jobs: RecentJobView[];
  currentPage?: number;
  total?: number;
  totalPages?: number;
  hideHeader: boolean;
  perPage: number;
  forceMobileView?: boolean;
  keyword?: string;
  setKeyword?: (keyword: string) => void;
  isWidgetMode?: boolean;
};

const RecentlyViewedJobsTable = (props: Props) => {
  const { keyword, setKeyword } = props;
  const { t } = useTranslation("recentlyViewedJobs");
  const { type: deviceType } = useDevice();

  const renderHeader = () => {
    return (
      <>
        <Body>
          <span className="recently-viewed-jobs-table__job-count">{props.total}</span>{" "}
          {t("jobPosts", { count: props.total })}
        </Body>
        <div className="recently-viewed-jobs-table__head__search">
          <TextInput
            id="search"
            placeholder={t("search")}
            onChange={(event) => setKeyword && setKeyword(event.currentTarget.value || "")}
            maxLength={255}
            value={keyword}
            narrow={true}
            icon={<SearchIcon />}
            onClear={() => setKeyword && setKeyword("")}
          />
        </div>
      </>
    );
  };

  const renderTable = () => {
    if (props.forceMobileView || deviceType === "mobile") {
      return <RecentlyViewedJobsTableMobile {...props} />;
    } else {
      return <RecentlyViewedJobsTableWeb {...props} />;
    }
  };

  const emptyState = () => {
    if (props.isWidgetMode) {
      return <EmptyState svg={Fingerprint2} svgSize="150" message={t("see_the_perfect")} />;
    }
    return (
      <EmptyState
        svg={Fingerprint2}
        title={t("no_recently_viewed")}
        message={t("when_you_view")}
        padding={"60px"}
      />
    );
  };

  return (
    <div className="recently-viewed-jobs-table">
      {props.jobs.length === 0 ? (
        emptyState()
      ) : (
        <>
          <div className="recently-viewed-jobs-table__head ">
            {!props.hideHeader && renderHeader()}
          </div>
          {renderTable()}
        </>
      )}
    </div>
  );
};

export default RecentlyViewedJobsTable;
