import useTranslation from "@/hooks/use_translation";
import useSubscriptions from "@/queries/use_subscriptions";
import { Error } from "@components/error";
import { ErrorBoundary } from "react-error-boundary";

import type { Subscription } from "@/pages/dashboard";
import { Suspense } from "react";
import LoadingSpinner from "@/sprout/loading_spinner";
import JobAlertsCard from "./job_alerts_card";
import JobAlertsRow from "./job_alerts_row";

import "./list.scss";
import EmptyState from "@components/empty_state.tsx";
import Fingerprint6 from "@images/fingerprint_6.svg";

type HeaderProps = {
  renderHeader: (count?: number) => JSX.Element;
};
type JobAlertsListProps = HeaderProps & {
  renderJobAlert: (subscription: Subscription) => JSX.Element;
  isWidgetMode?: boolean;
};

const JobAlertsList = ({ renderJobAlert, isWidgetMode, renderHeader }: JobAlertsListProps) => {
  const { data: subscriptions } = useSubscriptions();
  const { t } = useTranslation("jobAlerts");

  const EmptyListState = () => {
    if (isWidgetMode) {
      return <EmptyState svg={Fingerprint6} svgSize={"150"} message={t("noJobAlerts")} />;
    }
    return (
      <EmptyState
        svg={Fingerprint6}
        title={t("noJobAlertsYet")}
        message={t("createAnAlert")}
        padding={"60px"}
      />
    );
  };

  return (
    <>
      {renderHeader(subscriptions.length)}

      {subscriptions.length === 0 && <EmptyListState />}

      {subscriptions.length > 0 && (
        <ul className={`job-alerts-list ${isWidgetMode && "widget"}`}>
          {subscriptions.map((subscription) => renderJobAlert(subscription))}
        </ul>
      )}
    </>
  );
};

const LoadingState = ({ renderHeader }: HeaderProps) => {
  return (
    <>
      {renderHeader()}
      <div className="job-alerts-list__loading">
        <LoadingSpinner size="medium" />
      </div>
    </>
  );
};

const ErrorState = ({ renderHeader }: HeaderProps) => {
  const { t } = useTranslation("jobAlerts");

  return (
    <>
      {renderHeader()}
      <Error header={t("errorLoadingJobAlerts")} fullPage={false} />
    </>
  );
};

const SuspenseContainer = (props: JobAlertsListProps) => {
  return (
    <ErrorBoundary fallback={<ErrorState renderHeader={props.renderHeader} />}>
      <Suspense fallback={<LoadingState renderHeader={props.renderHeader} />}>
        <JobAlertsList {...props} />
      </Suspense>
    </ErrorBoundary>
  );
};

export const JobAlertsCardList = ({ renderHeader }: HeaderProps) => {
  return (
    <SuspenseContainer
      renderHeader={renderHeader}
      renderJobAlert={(subscription) => (
        <JobAlertsCard key={subscription.boardName} subscription={subscription} />
      )}
    />
  );
};

export const JobAlertsRowList = ({ renderHeader }: HeaderProps) => {
  return (
    <SuspenseContainer
      renderHeader={renderHeader}
      isWidgetMode
      renderJobAlert={(subscription) => (
        <JobAlertsRow key={subscription.boardName} subscription={subscription} />
      )}
    />
  );
};
