import Rollbar from "rollbar";

export const ROLLBAR_FILTER_CONFIG = {
  jsErrors: {
    shouldFilter: true,
    ignoreList: [
      /Cannot read properties of undefined \(reading 'parentNode'\)/,
      /Maximum call stack size exceeded/,
      /Script error/,
      /The object can not be found here/,
      /ResizeObserver loop completed with undelivered notifications/,
      /Cannot read property of undefined\/null expression window\.history\.state/,
      /Cannot read properties of null \(reading 'scrollRegions'\)/,
      /Failed to execute 'removeChild' on 'Node'/,
    ],
  },
};

const ROLLBAR_CONFIG = {
  accessToken: window.ENV?.ROLLBAR_CLIENT_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: false,
  transmit: window.ENV?.ROLLBAR_CLIENT_ENABLED === "true",
  environment: window.ENV?.ROLLBAR_ENV,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: window.ENV?.LOTUS_GIT_COMMIT,
        guess_uncaught_frames: true,
      },
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkIgnore: (_isUncaught: boolean, _args: any[], payload: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const browserExtensionOrAutomationFile = (frame: any) => {
      const patterns = [/^chrome-extension/, /^safari-extension/, /^moz-extension/, /^pptr/];
      return patterns.some((pattern) => pattern.test(frame.filename));
    };

    return payload.body?.trace?.frames?.some(browserExtensionOrAutomationFile) || false;
  },
  ignoredMessages: ROLLBAR_FILTER_CONFIG.jsErrors.shouldFilter
    ? ROLLBAR_FILTER_CONFIG.jsErrors.ignoreList
    : [],
};

const rollbar = new Rollbar(ROLLBAR_CONFIG);
export default rollbar;
