import { router } from "@inertiajs/react";

interface UseTaskDismissOptions {
  redirectTo?: string;
  preserveScroll?: boolean;
}

export const useTaskDismiss = (taskId: number, options: UseTaskDismissOptions = {}) => {
  const { redirectTo = "/dashboard", preserveScroll = true } = options;

  const handleDismiss = () => {
    router.post(
      `/tasks/${taskId}/dismiss`,
      {},
      {
        preserveScroll,
        onSuccess: redirectTo ? () => router.visit(redirectTo) : undefined,
      },
    );
  };

  return handleDismiss;
};

// Additional task-related hooks can be added here in the future
