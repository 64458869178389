import { useState } from "react";
import Button, { PrimaryButton } from "@sprout/button";
import { Trans, useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@components/ui/dialog";
import useCsrfToken from "@/hooks/use_csrf";
import { Stack } from "@/sprout/layout";

type Props = {
  loading: boolean;
  error: string | null;
  submit: () => void;
  paths: {
    userPath: string;
  };
  showDeactivate?: boolean;
};

const ProfileButtons = (props: Props) => {
  const { loading, error, submit, paths, showDeactivate = true } = props;
  const [deactivationError, setDeactivationError] = useState<string | null>(null);

  const { t } = useTranslation("user");
  const csrfToken = useCsrfToken();

  const onDeactivate = async () => {
    const response = await fetch(paths.userPath, {
      method: "DELETE",
      body: JSON.stringify({
        authenticity_token: csrfToken,
      }),
      headers: { "Content-Type": "application/json" },
    });

    if (response.redirected) {
      window.location.href = response.url;
    } else {
      setDeactivationError(t("profileButtons.error"));
    }
  };

  return (
    <div>
      <div className="profile__buttons">
        <PrimaryButton onClick={submit} loading={loading}>
          {t("profileButtons.save")}
        </PrimaryButton>
        <Button onClick={() => window.history.back()}>{t("profileButtons.cancel")}</Button>
        {showDeactivate && (
          <div className="profile__deactivate">
            <Dialog>
              <DialogTrigger>
                <Button linkStyle display="danger">
                  {t("profileButtons.deactivate")}
                </Button>
              </DialogTrigger>
              <DialogContent className="sm:max-w-md">
                <DialogHeader>
                  <DialogTitle>
                    <Trans i18nKey="user:profileButtons:modal:title" />
                  </DialogTitle>
                </DialogHeader>
                <DialogDescription className="support-modal__body">
                  <Stack>
                    <Trans i18nKey="user:profileButtons:modal:body" />
                  </Stack>
                </DialogDescription>
                <DialogFooter>
                  <DialogClose>
                    <Button>{t("profileButtons.modal.cancel")}</Button>
                  </DialogClose>
                  <DialogClose>
                    <Button display="danger" onClick={onDeactivate}>
                      {t("profileButtons.modal.confirm")}
                    </Button>
                  </DialogClose>
                </DialogFooter>
              </DialogContent>
            </Dialog>
          </div>
        )}
      </div>
      {(error || deactivationError) && (
        <p id="profile-error" className="helper-text helper-text--error" aria-live="polite">
          {error || deactivationError}
        </p>
      )}
    </div>
  );
};

export default ProfileButtons;
