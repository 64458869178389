import { RefObject, useCallback, useEffect, useState } from "react";
import { Body } from "@sprout/typography";
import type { Education } from "@/pages/quick_apply";
import { Option, Select } from "@sprout/select";
import Button from "@sprout/button";
import { TextInput } from "@sprout/text_input";
import TrashIcon from "@sprout/icons/trash";
import { IconButton } from "@sprout/icon_button";
import { useMonthOptions } from "@hooks/use_month_options";
import { useEffectAfterMount } from "@hooks/use_effect_after_mount";
import useTranslation from "@hooks/use_translation";
import { useAsyncOptions } from "@hooks/use_async_options";

type EducationSectionProps = {
  educations: Education[];
  setEducations: (educations: Education[]) => void;
  educationsError: { [key: number]: string };
  educationPath: string;
  educationRefs: RefObject<HTMLElement[]>;
};

type EducationProps = {
  education: Education;
  setEducation: (education: Education) => void;
  showDelete?: boolean;
  deleteEducation: () => void;
  error?: string;
  index: number;
  educationPath: string;
  educationRefs: RefObject<HTMLElement[]>;
};

const Education = ({
  education,
  setEducation,
  showDelete,
  deleteEducation,
  error,
  index,
  educationPath,
  educationRefs,
}: EducationProps) => {
  const months = useMonthOptions();
  const { t } = useTranslation("user");

  const [school, setSchool] = useState(education.school);
  const [degree, setDegree] = useState(education.degree);
  const [discipline, setDiscipline] = useState(education.discipline);
  const [startMonth, setStartMonth] = useState(
    months.find((m) => m.value === education.startMonth),
  );
  const [startYear, setStartYear] = useState(education.startYear);
  const [endMonth, setEndMonth] = useState(months.find((m) => m.value === education.endMonth));
  const [endYear, setEndYear] = useState(education.endYear);

  const [degreeOptions, setDegreeOptions] = useState<Option[]>([]);
  const [disciplineOptions, setDisciplineOptions] = useState<Option[]>([]);
  const [schoolOptions, setSchoolOptions] = useState<Option[]>([]);

  const [degreePage, setDegreePage] = useState<number>(1);
  const [disciplinePage, setDisciplinePage] = useState<number>(1);
  const [schoolPage, setSchoolPage] = useState<number>(1);

  const [degreeSearch, setDegreeSearch] = useState<string>("");
  const [disciplineSearch, setDisciplineSearch] = useState<string>("");
  const [schoolSearch, setSchoolSearch] = useState<string>("");

  async function loadAsyncOptions(
    search: string,
    page: number,
    options: Option[],
    key: "schools" | "degrees" | "disciplines",
    setOptions: (options: Option[]) => void,
  ) {
    const path = `${educationPath}/${key}?page=${page}`;
    const { newOptions } = await useAsyncOptions(path, search, options);

    setOptions(newOptions);
  }

  useEffect(() => {
    loadAsyncOptions(schoolSearch, schoolPage, schoolOptions, "schools", setSchoolOptions).catch(
      console.error,
    );
  }, [schoolPage, schoolSearch]);

  useEffect(() => {
    loadAsyncOptions(degreeSearch, degreePage, degreeOptions, "degrees", setDegreeOptions).catch(
      console.error,
    );
  }, [degreePage, degreeSearch]);

  useEffect(() => {
    loadAsyncOptions(
      disciplineSearch,
      disciplinePage,
      disciplineOptions,
      "disciplines",
      setDisciplineOptions,
    ).catch(console.error);
  }, [disciplinePage, disciplineSearch]);

  useEffectAfterMount(() => {
    setEducation({
      school: school,
      degree: degree,
      discipline: discipline,
      startMonth: startMonth?.value as number,
      startYear,
      endMonth: endMonth?.value as number,
      endYear,
      key: education.key,
    });
  }, [school, degree, discipline, startMonth, startYear, endMonth, endYear]);

  const setDivRef = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      educationRefs.current[index] = node;
    }
  }, []);

  return (
    <div className="form" ref={setDivRef}>
      {index > 0 && <hr />}
      <div className="section-header">
        <Body medium>{t("education.header")}</Body>
        {showDelete && (
          <IconButton
            label={t("education.remove")}
            size="md"
            icon={TrashIcon}
            onClick={deleteEducation}
          />
        )}
      </div>
      <Select
        id="school"
        label={t("education.school")}
        options={schoolOptions}
        // @ts-expect-error - TS2322
        onSelect={setSchool}
        selected={school}
        isClearable={true}
        onMenuScrollToBottom={() => setSchoolPage(schoolPage + 1)}
        onInputChange={(value) => setSchoolSearch(value)}
        isCreatable
      />
      <Select
        id="degree"
        label={t("education.degree")}
        options={degreeOptions}
        // @ts-expect-error - TS2322
        onSelect={setDegree}
        selected={degree}
        isClearable={true}
        onMenuScrollToBottom={() => setDegreePage(schoolPage + 1)}
        onInputChange={(value) => setDegreeSearch(value)}
        isCreatable
      />
      <Select
        id="discipline"
        label={t("education.discipline")}
        options={disciplineOptions}
        // @ts-expect-error - TS2322
        onSelect={setDiscipline}
        selected={discipline}
        isClearable={true}
        onMenuScrollToBottom={() => setDisciplinePage(schoolPage + 1)}
        onInputChange={(value) => setDisciplineSearch(value)}
        isCreatable
      />

      {/* Start dates */}
      <div className="dates">
        <Select
          id="startMonth"
          label={t("dates.startDateMonth")}
          options={months}
          // @ts-expect-error - TS2322
          onSelect={setStartMonth}
          selected={startMonth}
          isClearable
        />
        <TextInput
          id="startYear"
          type="number"
          label={t("dates.startDateYear")}
          onChange={(event) => setStartYear(+event.currentTarget.value)}
          maxLength={4}
          value={startYear?.toString()}
          error={error}
        />
      </div>

      {/* End dates */}
      <div className="dates">
        <Select
          id="endMonth"
          label={t("dates.endDateMonth")}
          options={months}
          // @ts-expect-error - TS2322
          onSelect={setEndMonth}
          selected={endMonth}
          isClearable
        />
        <TextInput
          id="endYear"
          type="number"
          label={t("dates.endDateYear")}
          onChange={(event) => setEndYear(+event.currentTarget.value)}
          maxLength={4}
          value={endYear?.toString()}
        />
      </div>
    </div>
  );
};

const EducationSection = ({
  educations,
  setEducations,
  educationsError,
  educationPath,
  educationRefs,
}: EducationSectionProps) => {
  const addEducation = () => {
    setEducations([...educations, { key: crypto.randomUUID() }]);
  };
  const { t } = useTranslation("user");

  return (
    <div className="quick_apply__education" id="education">
      {educations.map((education, index) => {
        return (
          <Education
            key={education.key}
            education={education}
            setEducation={(updatedEducation) => {
              const updatedEducations = [...educations];
              updatedEducations[index] = updatedEducation;
              setEducations(updatedEducations);
            }}
            showDelete={educations.length > 1}
            deleteEducation={() => {
              const updatedEducations = [...educations];
              updatedEducations.splice(index, 1);
              setEducations(updatedEducations);
            }}
            error={educationsError[index]}
            index={index}
            educationPath={educationPath}
            educationRefs={educationRefs}
          />
        );
      })}
      <Button onClick={addEducation} linkStyle>
        {t("add_school")}
      </Button>
    </div>
  );
};

export default EducationSection;
