import React from "react";
import type { GhrTask } from "@/types/task";
import CompanyLogo from "@/components/company_logo";
import { Body } from "@/sprout/typography";

interface JobInfoProps {
  task: GhrTask;
}

const JobInfo: React.FC<JobInfoProps> = ({ task }) => {
  return (
    <div className="flex items-center gap-2 mt-[4px]">
      <CompanyLogo companyName={task.company_name} size="xsmall" logoUrl={task.company_logo_url} />
      <div className="flex flex-col overflow-hidden">
        <Body className="!text-sm truncate">{task.job_title}</Body>
        <Body className="!text-xs truncate">{task.company_name}</Body>
      </div>
    </div>
  );
};

export default JobInfo;
