import "@/components/department_tags.scss";

import { Tag, useTagOverflow } from "@sprout/tag";
import Tooltip from "@/sprout/tooltip";

const DepartmentTags = ({ departments }: { departments: string[] }) => {
  const [containerRef, visibleTags, hiddenTags] = useTagOverflow(departments, (tag: string) => (
    <Tag label={tag} className="tag-item" />
  ));

  return (
    <div className="department-tags tag-overflow" ref={containerRef}>
      {visibleTags.map((department) => (
        <Tag key={department} label={department} className="tag-item" />
      ))}
      {hiddenTags.length > 0 && (
        <Tooltip text={hiddenTags.join(", ")}>
          <div className="more-tags">+{hiddenTags.length}</div>
        </Tooltip>
      )}
    </div>
  );
};

export default DepartmentTags;
