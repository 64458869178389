import React, { useId } from "react";
import { Trans } from "react-i18next";
import { Body } from "@sprout/typography";
import { Checkbox } from "@sprout/checkbox";
import { Option } from "@sprout/select";
import useTranslation from "@hooks/use_translation";
import "./form.scss";

interface Props {
  error: string | null;
  onChange: (value: boolean) => void;
  value: boolean;
}

export const TermsOfServiceForm = ({ error, onChange, value }: Props) => {
  const { t } = useTranslation("misc");
  const checkboxId = useId();

  const handleSelect = (selectedOptions: Option | Option[]) => {
    if (Array.isArray(selectedOptions)) {
      onChange(selectedOptions.length > 0);
    }
  };

  return (
    <>
      <Trans>
        <Body>
          By checking the box below, you consent to sharing basic personal information, such as your
          name and email address, with Greenhouse for the purpose of creating and servicing your
          Greenhouse My Jobs account. The terms of use are subject to the{" "}
          <a href="/users/agreement" target="_blank" rel="noreferrer">
            Greenhouse My Jobs User Agreement
          </a>
          , which can be accessed at any time, and Greenhouse’s use of your personal information is
          subject to the Greenhouse{" "}
          <a href="https://www.greenhouse.com/privacy-policy" target="_blank" rel="noreferrer">
            Privacy Policy
          </a>
          . You may terminate your Greenhouse My Jobs account at any time and your personal
          information will be permanently deleted.
        </Body>
      </Trans>

      <div className="terms-of-service__checkbox">
        <Checkbox
          isMulti
          required
          id={checkboxId}
          options={[
            {
              value: "1",
              label: t("termsOfService.agree"),
            },
          ]}
          onSelect={handleSelect}
          error={error}
          values={value ? ["1"] : []}
        />
      </div>
    </>
  );
};
