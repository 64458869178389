import React, { useId, useRef } from "react";

export const CODE_LENGTH = 8;

interface Props {
  hasError: boolean;
  onChange: (value: string) => void;
}

const selectInput = (input: React.MutableRefObject<HTMLInputElement | null>) =>
  input.current?.select();

// With this small timeout, it will actually highlight any text in the input so you can immediately replace it
const selectAndHighlightInput = (input: React.MutableRefObject<HTMLInputElement | null>) => {
  setTimeout(() => selectInput(input), 0);
};

export const CodeInput = ({ hasError, onChange }: Props) => {
  const id = useId();
  const inputRefs = Array.from(new Array(CODE_LENGTH), () => useRef<HTMLInputElement | null>(null));

  const currentCode = () => inputRefs.map((input) => input.current?.value || "").join("");

  const handleChange = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
    const nextInput = inputRefs[index + 1];

    onChange(currentCode());

    // Jump to next input if current value is not blank
    if (event.target.value && index < CODE_LENGTH - 1) {
      selectInput(nextInput);
    }
  };

  const handleKeyDown = (index: number, event: React.KeyboardEvent<HTMLInputElement>) => {
    const previousInputRef = inputRefs[index - 1];
    const nextInputRef = inputRefs[index + 1];

    if (
      event.key === "Backspace" &&
      index > 0 &&
      !(event.currentTarget as HTMLInputElement).value
    ) {
      // Do this one without a delay because it will actually erase the previous value instead
      selectInput(previousInputRef);
    } else if (event.key === "ArrowLeft" && index > 0) {
      selectAndHighlightInput(previousInputRef);
    } else if (event.key === "ArrowRight" && index < CODE_LENGTH - 1) {
      selectAndHighlightInput(nextInputRef);
    }
  };

  const handlePaste = (startIndex: number, event: React.ClipboardEvent<HTMLInputElement>) => {
    const clipboardData = event.clipboardData?.getData("text") || "";
    const pastedCharacters = clipboardData.split("");

    pastedCharacters.forEach((character, index) => {
      const inputIndex = startIndex + index;

      if (inputIndex >= CODE_LENGTH) {
        return;
      }

      inputRefs[inputIndex].current!.value = character;
    });

    onChange(currentCode());
  };

  return (
    <fieldset id={id} data-provides="login-security-code">
      <div className="login-security-code__wrapper">
        {inputRefs.map((inputRef, index) => (
          <input
            key={index}
            id={`${id}-${index}`}
            data-test-id={`security-input-${index}`}
            type="text"
            ref={inputRefs[index]}
            aria-invalid={hasError}
            aria-errormessage="security-code-error"
            aria-required={true}
            maxLength={1}
            value={inputRef.current?.value || ""}
            onChange={(e) => handleChange(index, e)}
            onPaste={(e) => handlePaste(index, e)}
            autoFocus={index === 0}
            onKeyDown={(e) => handleKeyDown(index, e)}
          />
        ))}
      </div>
    </fieldset>
  );
};
