import { Tag } from "@sprout/tag";
import "./entry_point.scss";
import { CompletionStatusType, statusInfo } from "@utils/completion_status.ts";

type Props = {
  status: CompletionStatusType;
};

const CompletionTag = ({ status }: Props) => {
  const info = statusInfo(status);

  return <Tag label={info.label} color={info.color} spacing={true} />;
};

export default CompletionTag;
