import React from "react";
import useTranslation from "@hooks/use_translation";
import type { GhrTask } from "@/types/task";
import TaskCard from "./TaskCard";
import CalendarIcon from "@/sprout/icons/calendar";
import JobInfo from "./JobInfo";

interface TaskCardProps {
  statusText: string;
  task: GhrTask;
}

const SelfScheduleRequest: React.FC<TaskCardProps> = ({ statusText, task }) => {
  const { t } = useTranslation("tasks");

  return (
    <TaskCard
      icon={<CalendarIcon size="sm" />}
      statusText={statusText}
      task={task}
      title={t("self_schedule.title")}
      body={<JobInfo task={task} />}
      actionHref={task.url}
      actionText={t("self_schedule.schedule_interview")}
      theme={{
        colors: {
          background: "bg-white",
          border: "border-gray-700",
          iconBackground: "bg-gray-500",
        },
      }}
      dismissable={false}
    />
  );
};

export default SelfScheduleRequest;
