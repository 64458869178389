import { Body, SectionHeader } from "@sprout/typography";
import { ErrorBoundary } from "react-error-boundary";
import Subscriptions from "@components/subscriptions_legacy";
import Preferences from "@components/preferences_legacy";
import "./dashboard_legacy.scss";
import ProfileCard from "@components/profile_card";
import useTranslation from "@hooks/use_translation";
import RecentlyViewedJobsCard from "@/components/recently_viewed_jobs/card";
import useDevice from "@/hooks/use_device";
import useRecentlyViewedJobs from "@/queries/use_recently_viewed_jobs";
import { Suspense } from "react";

export type Subscription = {
  boardName: string;
  departments: string[];
  path: string;
  editPath: string;
  logoUrl: string;
};

export type EmailSchedule = {
  id: number;
  cadence: "Weekly" | "Daily";
  isDaily: boolean;
};

type Props = {
  emailSchedule: EmailSchedule;
  emailSchedules: { [key: string]: number };
  paths: {
    notificationSchedulePath: string;
    recentlyViewedJobsPath: string;
  };
  isRecentlyViewedJobsEnabled: boolean;
  recentlyViewedJobsPerPageDashboard: number;
};

const Dashboard = (props: Props) => {
  const {
    emailSchedule,
    emailSchedules,
    paths,
    isRecentlyViewedJobsEnabled,
    recentlyViewedJobsPerPageDashboard,
  } = props;

  const { t } = useTranslation("jobAlerts");
  const { type: deviceType } = useDevice();
  const { data: recentlyViewedJobs = [] } = useRecentlyViewedJobs();

  const renderHeader = () => (
    <div className="dashboard-legacy__header">
      <SectionHeader as="h2" large serif>
        {t("yourJobAlerts")}
      </SectionHeader>
      <Preferences paths={paths} emailSchedule={emailSchedule} emailSchedules={emailSchedules} />
    </div>
  );

  const renderProfileCard = () => <ProfileCard />;

  const renderRecentlyViewedJobsCard = () =>
    isRecentlyViewedJobsEnabled && (
      <RecentlyViewedJobsCard
        jobs={recentlyViewedJobs}
        path={paths.recentlyViewedJobsPath}
        perPage={recentlyViewedJobsPerPageDashboard}
      />
    );

  const renderSubscriptions = () => {
    return (
      <ErrorBoundary fallback={<Body>{t("errorLoadingJobAlerts")}</Body>}>
        <Suspense>
          <Subscriptions />
        </Suspense>
      </ErrorBoundary>
    );
  };

  const renderDashboard = () => {
    if (deviceType === "mobile") {
      return (
        <>
          {renderHeader()}
          {renderProfileCard()}
          {renderSubscriptions()}
          {renderRecentlyViewedJobsCard()}
        </>
      );
    } else {
      return (
        <>
          <div className="dashboard-legacy__col1">
            {renderHeader()}
            {renderSubscriptions()}
          </div>
          <div className="dashboard-legacy__col2">
            {renderProfileCard()}
            {renderRecentlyViewedJobsCard()}
          </div>
        </>
      );
    }
  };

  return (
    <div className="dashboard-legacy">
      <main>{renderDashboard()}</main>
    </div>
  );
};

export default Dashboard;
