import React from "react";
import classNames from "classnames";
import { Link as InertiaLink } from "@inertiajs/react";

interface PageHeaderProps {
  children: React.ReactNode;
  className?: string;
}

const PageHeader = ({ children, className }: PageHeaderProps) => {
  return <h1 className={classNames("page-header", className)}>{children}</h1>;
};

type SectionHeaderProps = {
  as?: "h1" | "h2" | "h3";
  children: React.ReactNode;
  large?: boolean;
  medium?: boolean;
  serif?: boolean;
  className?: string;
};

const SectionHeader = (props: SectionHeaderProps) => {
  const { as, children, large, medium, serif, className } = props;

  const elementClassName = classNames(
    {
      "section-header": true,
      "section-header--large": large,
      "section-header--medium": medium,
      "section-header--serif": serif,
    },
    className,
  );

  return as === "h1" ? (
    <h1 className={elementClassName}>{children}</h1>
  ) : as === "h2" ? (
    <h2 className={elementClassName}>{children}</h2>
  ) : (
    <h3 className={elementClassName}>{children}</h3>
  );
};

interface SectionTitleProps {
  children: React.ReactNode;
  large?: boolean;
  className?: string;
}

const SectionTitle = ({ children, large, className }: SectionTitleProps) => {
  const elementClassName = classNames(
    "section-title",
    { "section-title--large": large },
    className,
  );

  return <h4 className={elementClassName}>{children}</h4>;
};

type BodyProps = {
  medium?: boolean;
  metadata?: boolean;
  strikethrough?: boolean;
  status?: "default" | "active";
  secondary?: boolean;
  light?: boolean;
  children?: React.ReactNode;
  as?: "p" | "span" | "div";
  error?: boolean;
  className?: string;
  dangerouslySetInnerHTML?: { __html: string };
};

const Body = (props: BodyProps) => {
  const {
    as,
    medium,
    metadata,
    strikethrough,
    status,
    secondary,
    children,
    light,
    error,
    className,
    ...rest
  } = props;

  const Element = as || "p";

  const elementClassName = classNames(
    {
      body: true,
      body__secondary: secondary,
      "body--light": light,
      "body--metadata": metadata,
      "body--strikethrough": strikethrough,
      "body--medium": medium,
      "body--active": status === "active",
      "body--error": error,
    },
    className,
  );

  return (
    <Element {...rest} className={elementClassName}>
      {children}
    </Element>
  );
};

type SecondaryBodyProps = {
  medium?: boolean;
  metadata?: boolean;
  strikethrough?: boolean;
  light?: boolean;
  children: React.ReactNode;
  className?: string;
};

const SecondaryBody = ({ children, className, ...props }: SecondaryBodyProps) => {
  return (
    <Body {...props} secondary={true} className={className}>
      {children}
    </Body>
  );
};

type LinkProps = {
  href?: string;
  children: React.ReactNode;
  rel?: string;
  target?: string;
  linkref?: React.RefObject<HTMLAnchorElement>;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  secondary?: boolean;
  medium?: boolean;
  className?: string;
};

const Link = (props: LinkProps) => {
  const { target, secondary, medium, linkref, href, className, ...rest } = props;

  const linkClassName = classNames(
    {
      link: true,
      link__secondary: secondary,
      "link--medium": medium,
    },
    className,
  );

  if (target === "_blank" || !href) {
    return (
      <a {...rest} href={href} target={target} className={linkClassName}>
        {props.children}
      </a>
    );
  }

  return (
    <InertiaLink {...rest} href={href} target={target} className={linkClassName} ref={linkref}>
      {props.children}
    </InertiaLink>
  );
};

type LabelProps = {
  htmlFor: string;
  id: string;
  children: React.ReactNode;
  required?: boolean;
  className?: string;
};

const Label = (props: LabelProps) => {
  const className = classNames("label", props.className);

  return (
    <label id={props.id} htmlFor={props.htmlFor} className={className}>
      {props.children}
      {props.required && <span aria-hidden="true">*</span>}
    </label>
  );
};

const TableHeader = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return <p className={classNames("table-header", className)}>{children}</p>;
};

export { SectionHeader, Body, PageHeader, SecondaryBody, SectionTitle, Link, Label, TableHeader };
