import { defaultIconProps, IconSvgWrapper, type IconProps } from "./base";
import theme from "@/stylesheets/theme.module.scss";

const EllipsisIcon = (props: IconProps) => {
  props = { ...defaultIconProps, ...props, color: theme.darkGreen };

  return (
    <IconSvgWrapper {...props}>
      <path
        fill={props.color}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.84079 12.1416C7.84079 13.202 6.98118 14.0616 5.92079 14.0616C4.86041 14.0616 4.00079 13.202 4.00079 12.1416C4.00079 11.0812 4.86041 10.2216 5.92079 10.2216C6.98118 10.2216 7.84079 11.0812 7.84079 12.1416ZM11.7312 14.0616C12.7916 14.0616 13.6512 13.202 13.6512 12.1416C13.6512 11.0812 12.7916 10.2216 11.7312 10.2216C10.6708 10.2216 9.8112 11.0812 9.8112 12.1416C9.8112 13.202 10.6708 14.0616 11.7312 14.0616ZM17.5404 14.0616C18.6008 14.0616 19.4604 13.202 19.4604 12.1416C19.4604 11.0812 18.6008 10.2216 17.5404 10.2216C16.48 10.2216 15.6204 11.0812 15.6204 12.1416C15.6204 13.202 16.48 14.0616 17.5404 14.0616Z"
      />
    </IconSvgWrapper>
  );
};

export default EllipsisIcon;
